// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorLLMResultOutput_container__jalJ5 {
    background-color: #E8EBF1;
    padding: 30px 40px;
    border-radius: 5px;
}
.MonitorLLMResultOutput_results__zMrvj {
  background-color: #000000cf;
  color: white;
}
.MonitorLLMResultOutput_md__P1lEq {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 12px 25px;
}
.MonitorLLMResultOutput_badge_wrapper__3hpyc {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.MonitorLLMResultOutput_badge__YxA7G {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -30px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/MonitoringComponent/MonitorLLMResultOutput.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;EACE,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".container {\n    background-color: #E8EBF1;\n    padding: 30px 40px;\n    border-radius: 5px;\n}\n.results {\n  background-color: #000000cf;\n  color: white;\n}\n.md {\n  border-radius: 10px;\n  margin-bottom: 20px;\n  padding: 12px 25px;\n}\n.badge_wrapper {\n  padding-top: 30px;\n  margin-bottom: 15px;\n  position: relative;\n}\n.badge {\n  background: #37ad52;\n  color: white;\n  padding: 5px;\n  border-radius: 2px;\n  position: relative;\n  left: -30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MonitorLLMResultOutput_container__jalJ5`,
	"results": `MonitorLLMResultOutput_results__zMrvj`,
	"md": `MonitorLLMResultOutput_md__P1lEq`,
	"badge_wrapper": `MonitorLLMResultOutput_badge_wrapper__3hpyc`,
	"badge": `MonitorLLMResultOutput_badge__YxA7G`
};
export default ___CSS_LOADER_EXPORT___;
