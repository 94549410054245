// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_actions__MCpio button {
  padding: 5px 12px;
  color: white;
  font-size: 14px;
  margin: 0 2px;
  /* width: 40%; */
  /* height: auto; */
}
`, "",{"version":3,"sources":["webpack://./src/BrandOnBoarding/Settings/Component/Table.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".actions button {\n  padding: 5px 12px;\n  color: white;\n  font-size: 14px;\n  margin: 0 2px;\n  /* width: 40%; */\n  /* height: auto; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `Table_actions__MCpio`
};
export default ___CSS_LOADER_EXPORT___;
