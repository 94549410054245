// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandIndexHistoryItem_item__qtvBD {
    display: flex;
    justify-content: space-between;
    background: #545454;
    padding: 0 10px;
    border-radius: 3px;
    color: #fff;
    margin: 0 12px 10px 12px;
}`, "",{"version":3,"sources":["webpack://./src/Component/BrandIndexHistory/BrandIndexHistoryItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,wBAAwB;AAC5B","sourcesContent":[".item {\n    display: flex;\n    justify-content: space-between;\n    background: #545454;\n    padding: 0 10px;\n    border-radius: 3px;\n    color: #fff;\n    margin: 0 12px 10px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `BrandIndexHistoryItem_item__qtvBD`
};
export default ___CSS_LOADER_EXPORT___;
