import React from "react";
import { Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";

const BrandCard = ({ brandName, handleIsDetailPage, brandData }) => {

  const navigate = useNavigate()
  return (
    <Card
      style={{
        width: "350px",
        // height: "80px",
        padding: "6px",
        backgroundColor: "lightgray",
      }}
    >
      <div
        style={{
          display: "flex",
          fontSize: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6 > Brand : {brandName} </h6>
        <FaArrowRight
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleIsDetailPage();
            navigate(`/brandonboarding/dashboard?brandId=${brandData?.customer_brand_id}`)
          }}
        />
      </div>

      <div style={{ display: "flex", fontSize: "16px" }}>
        <p> Index Score {brandData?.brand_index_score} </p>
        <p> Favorability Score {brandData?.brand_favorability_score} </p>
        <p> Reach Score {brandData?.brand_reach_score} </p>
      </div>
    </Card>
  );
};

export default BrandCard;
