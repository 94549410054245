// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPassword_actions__KLimK button {
    padding: 5px 12px;
    color: white;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Component/AuthComponent/ForgotPassword.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".actions button {\n    padding: 5px 12px;\n    color: white;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `ForgotPassword_actions__KLimK`
};
export default ___CSS_LOADER_EXPORT___;
