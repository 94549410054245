// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthComponent_button__ciN7V {
    width: 100%;
    text-align: center;
}

.AuthComponent_switchMode__SCb06 {
    text-align: center;
}

.AuthComponent_container__rY2sN {
    margin-top: -150px;
    padding: 200px;
}

.AuthComponent_email__t1tdz{
    margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/Component/AuthComponent/AuthComponent.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".button {\n    width: 100%;\n    text-align: center;\n}\n\n.switchMode {\n    text-align: center;\n}\n\n.container {\n    margin-top: -150px;\n    padding: 200px;\n}\n\n.email{\n    margin-top: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `AuthComponent_button__ciN7V`,
	"switchMode": `AuthComponent_switchMode__SCb06`,
	"container": `AuthComponent_container__rY2sN`,
	"email": `AuthComponent_email__t1tdz`
};
export default ___CSS_LOADER_EXPORT___;
