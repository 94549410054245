import React, { useState, useEffect, useContext } from "react";
import "./monitoringStyle.css";
import { layout } from "../LayoutComponent/LayoutComponent";
import Card from "react-bootstrap/Card";
import { Container, Spinner, Button } from "react-bootstrap";
import JSON5 from "json5";
import Markdown from "markdown-to-jsx";
import { useNavigate } from "react-router";
import {
  fetchDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";
import { FaArrowLeft } from "react-icons/fa";
import { useParams } from "react-router";
import { AuthContext } from "../../context/auth-context";
import { fetchGeminiAPI, fetchGpt4API } from "../../services/apiService";
import MonitorMenu from "./MonitorMenu";
import MonitorPrompt from "./MonitoringPrompt";
import LLMResultOutput from "./MonitorLLMResultOutput";
import { MonitoringMultiBarChart2 } from "./MonitoringLineChart";

const MonitoringTable = ({ data, selectedOption }) => {
  const { id } = useParams();
  const [aggregationPrompt, setAggregationPrompt] = useState("");
  const [baselineTablePrompt, setBaselineTablePrompt] = useState("");
  const [comparisonPrompt, setComparisonPrompt] = useState("");
  const [monitoringTablePrompt, setMonitoringTablePrompt] = useState("");
  const [togglePrompt, setTogglePrompt] = useState(false);
  const [toggleLLMResponse, setToggleLLMResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  useEffect(() => {
    // fetchDataFromFirebase((data) => {
    //   if (data !== null) {
    //     const monitoringPrompts = Object.values(data)[0];
    //     setAggregationPrompt(monitoringPrompts?.synthesizationPrompt);
    //     setBaselineTablePrompt(monitoringPrompts?.baselineTablePrompt);
    //     setComparisonPrompt(monitoringPrompts?.dataComparisonPrompt);
    //     setMonitoringTablePrompt(monitoringPrompts?.monitoringTablePrompt);
    //   } else {
    //   }
    // }, "MonitoringPrompts");
  }, []);

  const dataToDisplay = data.filter((obj, index) => {
    return index == id;
  });

  const synthesizedResponseResult = async (results) => {
    const resultsWithQuotes = results.map((result) => `'${result}'`);
    const inputPrompt = `${aggregationPrompt}. multiple results in raw strings are : ${resultsWithQuotes}`;
    try {
      let result = await fetchGeminiAPI(inputPrompt);
      result = result["Gemini"][0];
      return result;
    } catch (err) {
      // Handle error for Gemini API
      console.error("Error in Gemini API:", err);
      throw new Error(err);
    }
  };

  const regenerateBaselineTable = async (data) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Generating Table from Aggregated result...");
      const tablePrompt = `${baselineTablePrompt}.here is raw markdown string '${data[0]?.baselineSynthesized}'.`;
      let result = await fetchGpt4API(tablePrompt,undefined, undefined, undefined,undefined, undefined, 'Frontend Monitoring');
      result = result["GPT-4"][0];
     
      const dataToUpdate = data[0];
      dataToUpdate.monitoringData = result;
      updateDataInFirebase("MonitoringData", data[0]?.key, dataToUpdate);
      setLoadingMessage("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const getMonitoringTablePromptAndFrequency = (data, promptFromDB) => {
    let monitoringTablePrompt = promptFromDB;
   

    if (data[0]?.monitoringFrequency == "Daily") {
      monitoringTablePrompt = monitoringTablePrompt.replace(/week/g, "Day");
    } else if (data[0]?.monitoringFrequency == "Monthly") {
      monitoringTablePrompt = monitoringTablePrompt.replace(/week/g, "Month");
    }

    return monitoringTablePrompt;
  };

  const regenerateMonitoringTable = async (data) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Generating Table from compared result...");
      const prompt = getMonitoringTablePromptAndFrequency(
        data,
        monitoringTablePrompt
      );
      const tablePrompt = `${prompt}.Existing markdown table is ${
        data[0]?.previousMonitoringData || data[0]?.baselineData
      } and new dataset is ${
        Object.values(data[0]?.comparisonResults)[
          Object.values(data[0]?.comparisonResults).length - 1
        ]
      }`;
      let result = await fetchGpt4API(tablePrompt,undefined, undefined, undefined,undefined, undefined, 'Frontend Monitoring');
      result = result["GPT-4"][0];
     
      const dataToUpdate = data[0];
      dataToUpdate.monitoringData = result;
      updateDataInFirebase("MonitoringData", data[0]?.key, dataToUpdate);
      setLoadingMessage("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const regenerateBaselineResult = async (data) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Aggregating API Responses...");
      const synthesizedResponse = await synthesizedResponseResult(
        data[0]?.allApiResults
      );
    
      setLoadingMessage("Generating Table from Aggregated result...");
      const tablePrompt = `${baselineTablePrompt}.here is raw markdown string '${synthesizedResponse}'.`;
      let result = await fetchGpt4API(tablePrompt,undefined, undefined, undefined,undefined, undefined, 'Frontend Monitoring');
      result = result["GPT-4"][0];
      const dataToUpdate = data[0];
      dataToUpdate.monitoringData = result;
      dataToUpdate.baselineSynthesized = synthesizedResponse;
      updateDataInFirebase("MonitoringData", data[0]?.key, dataToUpdate);
      setLoadingMessage("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const regenerateMonitoringResult = async (data) => {
    try {
      setIsLoading(true);
      setLoadingMessage("Analyzing API Responses...");
      const analysisPrompt = `${comparisonPrompt} baseline data is ${data[0]?.baselineSynthesized} & latest version data is ${data[0]?.recentMonitoringAPIResult}`;
      let comparisonResult = await fetchGpt4API(analysisPrompt,undefined, undefined, undefined,undefined, undefined, 'Frontend Monitoring');
      comparisonResult = comparisonResult["GPT-4"][0];
     
      setLoadingMessage("Generating Table from compared result...");
      const tablePrompt = `${monitoringTablePrompt}.Existing markdown table is ${
        data[0]?.previousMonitoringData || data[0]?.baselineData
      } and new dataset is ${comparisonResult}`;
      let result = await fetchGpt4API(tablePrompt,undefined, undefined, undefined,undefined, undefined, 'Frontend Monitoring');
      result = result["GPT-4"][0];
      let existingComparisonResults = Object.keys(data[0]?.comparisonResults);
      let lastKey =
        existingComparisonResults[existingComparisonResults.length - 1];
      const dataToUpdate = data[0];
      dataToUpdate.monitoringData = result;
      dataToUpdate.comparisonResults[lastKey] = comparisonResult;

      updateDataInFirebase("MonitoringData", data[0]?.key, dataToUpdate);
      setLoadingMessage("");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  return (
    <>
      <hr />
      {dataToDisplay[0]?.allApiResults &&
        dataToDisplay[0]?.allApiResults.length && (
          <MonitorMenu
            setTogglePrompt={setTogglePrompt}
            setToggleLLMResponse={setToggleLLMResponse}
            data={dataToDisplay[0]}
            togglePrompt={togglePrompt}
            toggleLLMResponse={toggleLLMResponse}
          />
        )}

      {togglePrompt && (
        <>
          <MonitorPrompt
            aggregationPrompt={aggregationPrompt}
            setAggregationPrompt={setAggregationPrompt}
            baselineTablePrompt={baselineTablePrompt}
            setBaselineTablePrompt={setBaselineTablePrompt}
            comparisonPrompt={comparisonPrompt}
            monitoringTablePrompt={monitoringTablePrompt}
            setComparisonPrompt={setComparisonPrompt}
            setMonitoringTablePrompt={setMonitoringTablePrompt}
            regenerateBaselineResult={regenerateBaselineResult}
            regenerateBaselineTable={regenerateBaselineTable}
            regenerateMonitoringResult={regenerateMonitoringResult}
            regenerateMonitoringTable={regenerateMonitoringTable}
            data={dataToDisplay}
            isLoading={isLoading}
          />
          <hr />
        </>
      )}
      {toggleLLMResponse && (
        <>
          <LLMResultOutput
            frequency={dataToDisplay[0]?.monitoringFrequency}
            aggregatedResult={dataToDisplay[0]?.baselineSynthesized}
            LLM={dataToDisplay[0]?.llm}
            LLMResponses={dataToDisplay[0]?.allApiResults}
            data={dataToDisplay[0]}
            comparedResults={dataToDisplay[0]?.comparisonResults}
            monitoringMultipromptResults={
              dataToDisplay[0]?.multipromptAPIResults
            }
          />
          <hr />
        </>
      )}
      {isLoading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="container d-flex flex-column align-items-center mt-5"
        >
          <Spinner />
          <span className="mt-3">{loadingMessage}</span>
        </div>
      ) : (
        <div>
          {dataToDisplay.map((brandData, brandIndex) => {
            return (
              <div key={brandIndex}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {" "}
                  <h5>
                    {" "}
                    Brand/Product/Category: {
                      brandData?.brandOrProductToMonitor
                    }{" "}
                  </h5>
                  <h5> Type: {brandData?.focussingArea || ""} </h5>
                </div>

                <Card
                  id="monitoringreport"
                  style={{ paddingTop: "5px", marginBottom: "30px" }}
                >
                  {brandData?.visualizationData ? (
                    <>
                      <MonitoringMultiBarChart2
                        monitoringData={JSON5.parse(
                          brandData?.visualizationData
                        )}
                        frequency={brandData?.monitoringFrequency}
                        type={brandData?.focusAttribute}
                        focusAttribute = {brandData?.focusAttribute}
                      />
                      <hr/>
                      {brandData?.visualizationDataForSources && (
                        <MonitoringMultiBarChart2
                          monitoringData={JSON5.parse(
                            brandData?.visualizationDataForSources
                          )}
                          frequency={brandData?.monitoringFrequency}
                          type='Sources'
                        />
                      )}
                    </>
                  ) : (
                    <Markdown>
                      {brandData?.monitoringData || brandData?.baselineData}
                    </Markdown>
                  )}

                  {/* <MonitoringLineChart
                    monitoringData={
                      JSON5.parse(brandData?.visualizationData) || []
                    }
                  /> */}
                  <p id="extraSpacer"> </p>
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const MonitoringReport = () => {
  const [monitoringData, setMonitoringData] = useState([]);
  const { authUserEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // fetchDataFromFirebase(
    //   (data) => {
    //     if (data !== null) {
    //       const arrayOfObjects = Object.entries(data).map(([key, value]) => ({
    //         key,
    //         ...value,
    //       }));

    //       setMonitoringData(arrayOfObjects);
    //     } else {
    //       setMonitoringData(null);
    //     }
    //   },
    //   "MonitoringData",
    //   authUserEmail
    // );
  }, []);

  return (
    <div className="mainDiv">
      <Container fluid>
        <Container className="border-secondary-subtle borderSet">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "60%",
            }}
          >
            <Button
              // className={classes.actionBtn}
              variant="link"
              // onClick={downloadPDF}
            >
              <FaArrowLeft
                onClick={() => {
                  navigate(`/monitors?tab=1`);
                }}
              />
            </Button>
            <h4>Monitoring Report</h4>
          </div>

          <div>
            {monitoringData && monitoringData.length == 0 ? (
              <div className="container d-flex flex-column align-items-center mt-5">
                <Spinner />
                <span className="mt-3">Loading Monitoring Report...</span>
              </div>
            ) : monitoringData !== null ? (
              <MonitoringTable data={monitoringData} />
            ) : (
              <div className="container d-flex flex-column align-items-center mt-5">
                <span className="mt-3">No Reports Available !</span>
              </div>
            )}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default layout(MonitoringReport, false);
