// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandIndexSectionHeader_section_header__ZcGwM {
    position: sticky;
    top: 68px;
    top: 0px;
    background-color: white;
    width: 100%;
    display: block;
    margin: 0px;
    padding: 10px 5px;
    z-index: 5;
}`, "",{"version":3,"sources":["webpack://./src/Component/BrandIndexHistory/BrandIndexSectionHeader.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,QAAQ;IACR,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,WAAW;IACX,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".section_header {\n    position: sticky;\n    top: 68px;\n    top: 0px;\n    background-color: white;\n    width: 100%;\n    display: block;\n    margin: 0px;\n    padding: 10px 5px;\n    z-index: 5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section_header": `BrandIndexSectionHeader_section_header__ZcGwM`
};
export default ___CSS_LOADER_EXPORT___;
