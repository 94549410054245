import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import styles from "./AuthComponent.module.css";
import { AuthContext } from "../../context/auth-context";
import {
  doLogin,
  resetPassword,
} from "../../DatabaseFirebase/firebaseService";
import { layout } from "../LayoutComponent/LayoutComponent";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

const AuthComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [emailToResetPass, setEmailToResetPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState('');

  const handleClose = () => {
    setIsShowModal(false);
    setSuccessMessage("");
    setEmailToResetPass("");
  };

  const handleResetPassword = async () => {
    try {
      await resetPassword(emailToResetPass);
      setSuccessMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setSuccessMessage(error.message);
    }
  };

  const navigate = useNavigate();

  const { setUser } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsClicked(true);
    setError(null);
    doLogin(email, password)
      .then((response) => {
        const authUser = response.data.data;
        if(!authUser.is_verified) {
          setError("You need to verify your email before you login to the app")
          return;
        };
        if(authUser.is_active == 0) {
          setError("Your account is inactive. please contact admin.")
          return;
        }
        toast.success("Login successful");
        setUser(authUser.token, authUser?.email, authUser);
        if(authUser.user_role.role_id === 1) {
          navigate("/system");
        } else {
          navigate("/");
        }
      })
      .catch(() => {
        toast.error("Invalid Username/Password", { autoClose: 900 });
        setIsClicked(false);
      });
  };

  return (
    <>
      <Container className={styles.container}>
        <ForgotPasswordModal
          emailToResetPass={emailToResetPass}
          setEmailToResetPass={setEmailToResetPass}
          show={isShowModal}
          handleClose={handleClose}
          handleResetPassword={handleResetPassword}
          successMessage={successMessage}
        />

        <Row className="justify-content-md-center">
          <Col md={6}>
            <span style={{ marginLeft: "30%", display: "flex" }}>
              <img src="menu.png" width={40} height={40} />
              &nbsp;&nbsp;
              <h4 className="mt-1">Brand Luminaire</h4>
            </span>
            <Form onSubmit={handleSubmit}>
              <Form.Group className={styles.email} controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <p
                className="mt-2"
                onClick={() => setIsShowModal(true)}
                style={{ textAlign: "center", cursor: "pointer" }}
              >
                Forgot password?
              </p>
              { error && <ErrorMessage message={error}/> }
              <Button
                style={{
                  width: "-webkit-fill-available",
                  backgroundColor: isClicked
                    ? "rgba(61, 200, 99, 0.7)"
                    : "#3dc863",
                  color: "white",
                }}
                className={styles.button}
                type="submit"
              >
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};

export default layout(AuthComponent, false);