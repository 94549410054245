// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandIndexProgress_container__1RQhz {
    background: #545454;
    padding: 10px 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.BrandIndexProgress_responseText__uI3xN {
    color: white;
    margin-left: 10px;
    padding: 0;

    display: flex;
    flex-direction: column;
}

.BrandIndexProgress_responseText__uI3xN i {
    margin-top: 5px;
}

.BrandIndexProgress_step__EjpNA {
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Component/MainDataComponent/BrandIndexProgress.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,UAAU;;IAEV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".container {\n    background: #545454;\n    padding: 10px 10px;\n    border-radius: 3px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 20px;\n}\n.responseText {\n    color: white;\n    margin-left: 10px;\n    padding: 0;\n\n    display: flex;\n    flex-direction: column;\n}\n\n.responseText i {\n    margin-top: 5px;\n}\n\n.step {\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandIndexProgress_container__1RQhz`,
	"responseText": `BrandIndexProgress_responseText__uI3xN`,
	"step": `BrandIndexProgress_step__EjpNA`
};
export default ___CSS_LOADER_EXPORT___;
