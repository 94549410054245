import React, { useContext, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import VerifiedIcon from "@mui/icons-material/Verified";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Alert } from "react-bootstrap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSearchParams } from "react-router-dom";
import { CircularProgress, Switch } from "@mui/material";

import Table from "../Component/Table/Table";
import Modal from "../Component/Modal/Modal";
import AddCompanyUserForm from "./AddCompanyUserForm";
import {
  addUser,
  deleteUser,
  updateUser,
} from "./../redux/actions/userActions";
import { AuthContext } from "./../context/auth-context";
import EmptyView from "./../Component/EmptyView/EmptyView";

const headers = [
  "Id",
  "Email",
  "Customer",
  "Role",
  "Is Active",
  "Is Verified",
  "Created At",
];

function Users({ loading, data }) {
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type");

  const dispatch = useDispatch();
  const { addUserError, adduserSuccess } = useSelector((state) => state.user);
  const authCtx = useContext(AuthContext);

  if (type === "user" && !headers.includes("Actions")) {
    headers.push("Actions");
  } else if (headers.includes("Actions")) {
    headers.pop();
  }

  async function addCompanyHandler(company, email) {
    const payload = {
      email: email,
      password: 123456,
    };
    if (type === "user") {
      payload.customer_id = company;
      payload.role_id = 3;
    } else {
      payload.customer_name = company;
      payload.role_id = 2;
    }
    dispatch(addUser(authCtx.user, payload));
  }

  useEffect(() => {
    if (adduserSuccess || addUserError) setShowAddCustomerModal(false);
  }, [adduserSuccess, addUserError]);

  let errorMessage = null;
  if (addUserError) {
    errorMessage = `Failed to add an user - ${addUserError}`;
  }

  return (
    <>
      <Modal
        title={type === "user" ? "Add User" : "Add Company"}
        open={showAddCustomerModal}
        handleClose={() => {
          setShowAddCustomerModal(false);
        }}
      >
        <AddCompanyUserForm
          type={type}
          onSubmit={addCompanyHandler}
          onCancel={() => {
            setShowAddCustomerModal(false);
          }}
        />
      </Modal>
      {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
      <ToastContainer />
      <div className="d-flex flex-row justify-content-between">
        <h3></h3>
        <IconButton
          aria-label="add"
          onClick={() => {
            setShowAddCustomerModal(true);
          }}
        >
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </div>
      <hr />
      {!loading && data.length > 0 && (
        <Table
          headers={headers}
          render={() => (
            <>
              {data.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.customer.name}</TableCell>
                  <TableCell>{user.user_role.role.name}</TableCell>
                  <TableCell>
                    <Switch
                      defaultChecked={Boolean(user.is_active)}
                      onChange={(e) =>
                        dispatch(
                          updateUser(authCtx.user, user.id, {
                            is_active: e.target.checked ? 1 : 0,
                            is_verified: user.is_verified,
                          })
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {user.is_verified ? (
                      <VerifiedIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(user.date_added).format("DD-MM-YYYY h:mm A")}
                  </TableCell>
                  {type === "user" && (
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          dispatch(deleteUser(authCtx.user, user.id))
                        }
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </>
          )}
        />
      )}
      {loading && data.length == 0 && (
        <div className="d-flex justify-content-center">
          <CircularProgress size={28} />
        </div>
      )}
      {!loading && data.length === 0 && <EmptyView title="Nothing here." message="No users were found."  />}
    </>
  );
}

export default Users;
