import React, { useState, useEffect, useContext } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams  } from 'react-router-dom';

import Users from "./Users";
import { AuthContext } from "../context/auth-context";
import { getUsers } from "../redux/actions/userActions";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Home() {
  const [currentTab, setCurrentTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);
  const { users, isLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const filteredUsers = users.filter(user=>{
    if(currentTab === 0) return user.user_role.role_id === 2
    if(currentTab === 1) return user.user_role.role_id === 3
  })

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(()=>{
    setSearchParams({ type: currentTab ? "user": "company"})
  },[currentTab])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Customers" />
          <Tab label="Users" />
        </Tabs>
      </Box>
      <CustomTabPanel value={currentTab} index={0}>
        <Users data={filteredUsers} loading={isLoading} />
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index={1}>
        <Users data={filteredUsers} loading={isLoading} />
      </CustomTabPanel>
    </>
  );
}
