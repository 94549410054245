// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMessage_container__VgyyL {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.ErrorMessage_message__iuGrJ {
    color: red;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Component/ErrorMessage/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,UAAU;IACV,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n.message {\n    color: red;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorMessage_container__VgyyL`,
	"message": `ErrorMessage_message__iuGrJ`
};
export default ___CSS_LOADER_EXPORT___;
