import React, { useState } from "react";
import { Navbar, Container, Dropdown, Modal, Button } from "react-bootstrap";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import ResetPasswordModal from "../AuthComponent/ResetPasswordModal";
import NavTabs from "../NavigationComponent/NavigationComponent";
import { updatePasswordWithReauthentication } from "../../DatabaseFirebase/firebaseService";
import { useDispatch } from "react-redux";

function CustomNavbar({
  toggleDropdown,
  isDropdownOpen,
  subTitle,
  setIsShowModal,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isShowResetModal, setIsShowResetModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleUpdatePassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        setMessage("Passwords do not match.");
        return;
      }
      // Reauthenticate the user
      const updatePassword = await updatePasswordWithReauthentication(
        currentPassword,
        newPassword
      );
      setMessage(updatePassword);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handleClose = () => {
    setIsShowResetModal(false);
    setNewPassword("");
    setConfirmNewPassword("");
    setMessage("");
    setCurrentPassword("");
  };

  const navigate = useNavigate();
  const { clearSession, isCurrentUserAdmin, token } = useContext(AuthContext);

  const dispatch = useDispatch();

  const userProfile = {
    name: "John Doe",
    email: "john@example.com",
    bio: "Software Developer",
    location: "Cityville",
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLogOut = () => {
    clearSession();
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const handleScreenNavigation = (pathName) => {
    pathName === "admin" ? navigate("/admin") : navigate(`/${pathName || ""}`);
  };

  return (
    <div>
      <Navbar className="bg-white p-2 mb-5">
        <ResetPasswordModal
          currentPassword={currentPassword}
          newPassword={newPassword}
          confirmNewPassword={confirmNewPassword}
          setCurrentPassword={setCurrentPassword}
          setNewPassword={setNewPassword}
          setConfirmNewPassword={setConfirmNewPassword}
          handleClose={handleClose}
          show={isShowResetModal}
          handleResetPassword={handleUpdatePassword}
          successMessage={message}
        />
        <Container fluid>
          <Navbar.Brand className="navbar-brand mb-0 h1 d-none d-md-block">
            {token && (
              <>
                <img
                  alt=""
                  src="/menu.png"
                  width="30"
                  height="30"
                  style={{ cursor: "pointer" }}
                  className="d-inline-block align-top"
                  onClick={() => handleScreenNavigation()}
                />
                &nbsp; Brand Luminaire
                <br />
                <span className="fs-6" style={{ marginLeft: "2.5rem" }}>
                  {subTitle || ""}
                </span>
              </>
            )}

            {!token && (
              <span style={{ display: "flex" }}>
                <img src="menu.png" width={30} height={30} />
                &nbsp;
                <h4>Revere</h4>
              </span>
            )}
          </Navbar.Brand>
          <Navbar.Brand className="d-flex flex-1 d-block d-md-none">
            <a href="" className="sidebar-toggle ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />

          <Navbar.Collapse className="justify-content-end">
            {/* {isCurrentUserAdmin && (
              <Button
                onClick={() =>
                  handleScreenNavigation(
                    window.location.pathname === "/admin" ? "" : "admin"
                  )
                }
                className="height2"
                style={{
                  width: "15%",
                  backgroundColor: "#3dc863",
                  color: "white",
                  fontWeight: "bold",
                  marginRight: "36px",
                  fontSize: '16px'
                }}
              >
              
                {window.location.pathname === "/admin" ? "Discovery" : "Admin"}
              </Button>
            )} */}
            <NavTabs token={token} isCurrentUserAdmin={isCurrentUserAdmin} />
            &nbsp; &nbsp;
            {token !== null && (
              <>
                {/* <Button
                    onClick={() =>
                      handleScreenNavigation(
                        window.location.pathname === "/monitors" ? `Monitoring` : "monitors"
                      )
                    }
                  className="height2"
                  style={{
                    width: "25%",
                    backgroundColor: "#3dc863",
                    color: "white",
                    // marginTop: "15px",
                    marginRight: "30px",
                  }}
                >
                  {window.location.pathname === "/monitors" ? "Monitoring Setup" : "Monitoring Records"}
                </Button>  */}
                <Button
                  onClick={() => setIsShowModal(true)}
                  // className="height2"
                  style={{
                    width: "10%",
                    backgroundColor: "#3dc863",
                    color: "white",
                    // marginTop: "15px",
                    marginRight: "25px",
                  }}
                >
                  Help
                </Button>
                <Navbar.Text>
                  <img
                    alt=""
                    src="/profile.png"
                    onClick={toggleDropdown}
                    id="profileImage"
                    style={{ cursor: "pointer" }}
                  />
                </Navbar.Text>
                <Dropdown
                  show={isDropdownOpen}
                  align="end"
                  id="profileDropdown"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Dropdown.Menu className="mt-4">
                    <Dropdown.Item href="" onClick={openModal}>
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      onClick={() => setIsShowResetModal(true)}
                    >
                      Reset Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      onClick={() => navigate("/brandonboarding")}
                    >
                      Brand onboarding
                    </Dropdown.Item>
                    <hr className="mt-2 mb-2" />
                    <Dropdown.Item
                      onClick={() => {
                        handleLogOut();
                      }}
                      href=""
                      className="text-danger"
                    >
                      Sign out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>User Profile Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p>
              <strong>Name:</strong> {userProfile.name}
            </p>
            <p>
              <strong>Email:</strong> {userProfile.email}
            </p>
            <p>
              <strong>Bio:</strong> {userProfile.bio}
            </p>
            <p>
              <strong>Location:</strong> {userProfile.location}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modalClose rounded"
            style={{
              backgroundColor: "#3dc863",
              color: "white",
              border: "1px solid #3dc863",
            }}
            onClick={closeModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomNavbar;
