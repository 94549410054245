// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardLLMResults_container__vwDlr {
    background-color: #E8EBF1;
    padding: 30px 40px;
    border-radius: 5px;
}
.DashboardLLMResults_results__ICJmv {
  background-color: #000000cf;
  color: white;
}
.DashboardLLMResults_md__UKVG0 {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 12px 25px;
}
.DashboardLLMResults_badge_wrapper__tmAWe {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.DashboardLLMResults_badge__bIOTI {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -15px;
}

.DashboardLLMResults_actionBtn__MJ7G0 {
  padding: 7px 12px !important;
  font-size: 18px !important;
  color: rgb(51, 51, 51) !important;
  /* display: flex !important; */
  justify-content: center !important;
  align-items: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/DashboardComponent/DashboardLLMResults.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;EACE,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,0BAA0B;EAC1B,iCAAiC;EACjC,8BAA8B;EAC9B,kCAAkC;EAClC,8BAA8B;AAChC","sourcesContent":[".container {\n    background-color: #E8EBF1;\n    padding: 30px 40px;\n    border-radius: 5px;\n}\n.results {\n  background-color: #000000cf;\n  color: white;\n}\n.md {\n  border-radius: 10px;\n  margin-bottom: 20px;\n  padding: 12px 25px;\n}\n.badge_wrapper {\n  padding-top: 30px;\n  margin-bottom: 15px;\n  position: relative;\n}\n.badge {\n  background: #37ad52;\n  color: white;\n  padding: 5px;\n  border-radius: 2px;\n  position: relative;\n  left: -15px;\n}\n\n.actionBtn {\n  padding: 7px 12px !important;\n  font-size: 18px !important;\n  color: rgb(51, 51, 51) !important;\n  /* display: flex !important; */\n  justify-content: center !important;\n  align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DashboardLLMResults_container__vwDlr`,
	"results": `DashboardLLMResults_results__ICJmv`,
	"md": `DashboardLLMResults_md__UKVG0`,
	"badge_wrapper": `DashboardLLMResults_badge_wrapper__tmAWe`,
	"badge": `DashboardLLMResults_badge__bIOTI`,
	"actionBtn": `DashboardLLMResults_actionBtn__MJ7G0`
};
export default ___CSS_LOADER_EXPORT___;
