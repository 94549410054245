// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardDataDisplay_actionBtn__3hv-r {
    padding: 7px 12px !important;
    font-size: 18px !important;
    color: rgb(51, 51, 51) !important;
    /* display: flex !important; */
    justify-content: center !important;
    align-items: center !important;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Dashboard/DashboardComponent/DashboardDataDisplay.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,0BAA0B;IAC1B,iCAAiC;IACjC,8BAA8B;IAC9B,kCAAkC;IAClC,8BAA8B;EAChC","sourcesContent":[".actionBtn {\n    padding: 7px 12px !important;\n    font-size: 18px !important;\n    color: rgb(51, 51, 51) !important;\n    /* display: flex !important; */\n    justify-content: center !important;\n    align-items: center !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBtn": `DashboardDataDisplay_actionBtn__3hv-r`
};
export default ___CSS_LOADER_EXPORT___;
