// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorPrompt_form__R-uRD {
  width: 100%;
  background: #E8EBF1;
  border-radius: 6px;
  padding: 20px 24px;
}
.MonitorPrompt_actions__KX5Ph {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
}

.MonitorPrompt_actions1__7Ajzp {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
}

.MonitorPrompt_actions__KX5Ph button {
  color: white;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #37ad52;
}

.MonitorPrompt_actions__KX5Ph button:hover {
  color: white;
  background-color: #249e41;
}`, "",{"version":3,"sources":["webpack://./src/Component/MonitoringComponent/MonitorPrompt.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".form {\n  width: 100%;\n  background: #E8EBF1;\n  border-radius: 6px;\n  padding: 20px 24px;\n}\n.actions {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n  justify-content: flex-end;\n}\n\n.actions1 {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n  justify-content: flex-start;\n}\n\n.actions button {\n  color: white;\n  font-size: 14px;\n  padding: 10px 12px;\n  background-color: #37ad52;\n}\n\n.actions button:hover {\n  color: white;\n  background-color: #249e41;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `MonitorPrompt_form__R-uRD`,
	"actions": `MonitorPrompt_actions__KX5Ph`,
	"actions1": `MonitorPrompt_actions1__7Ajzp`
};
export default ___CSS_LOADER_EXPORT___;
