// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  
  .brand-card {
    /* border: 1px solid #ccc; */
    padding: 10px;
    width: 97%; /* Adjust the width as needed */
    margin-left: 1.5%;
  }
  
  .brand-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .brand-table th,
  .brand-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .brand-table th {
    background-color: #f2f2f2;
  }

  .header-text {
    text-align: center;
  }

  .mainDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/MonitoringComponent/monitoringStyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,sBAAsB;EACxB;;EAEA;IACE,4BAA4B;IAC5B,aAAa;IACb,UAAU,EAAE,+BAA+B;IAC3C,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,sBAAsB;EACxB","sourcesContent":[".brand-container {\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n  }\n  \n  .brand-card {\n    /* border: 1px solid #ccc; */\n    padding: 10px;\n    width: 97%; /* Adjust the width as needed */\n    margin-left: 1.5%;\n  }\n  \n  .brand-table {\n    border-collapse: collapse;\n    width: 100%;\n  }\n  \n  .brand-table th,\n  .brand-table td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .brand-table th {\n    background-color: #f2f2f2;\n  }\n\n  .header-text {\n    text-align: center;\n  }\n\n  .mainDiv {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
