// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_container__4hGHZ {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 18px 0;
}
`, "",{"version":3,"sources":["webpack://./src/Component/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  padding: 18px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Spinner_container__4hGHZ`
};
export default ___CSS_LOADER_EXPORT___;
