import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import Card from "react-bootstrap/Card";

import {
  fetchGeminiAPI,
  fetchGpt4API,
  fetchGooeyAPI,
  fetchLlama2API,
  checkWebLink,
  fetchClaude3API,
} from "../../../services/apiService";
import { fetchDataFromFirebase } from "../../../DatabaseFirebase/firebaseService";
import classes from "./ComparisionReporting.module.css";
import ReportPrompt from "./ReportPrompt";
import LLMResultOutput from "./LLMResultOutput";
import ReportMenu from "./ReportMenu";
import {
  generateMarkdownTable,
  extractLinks,
  allProgress,
  reportPrompts,
} from "../../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  "gpt-4": "GPT-4 (OpenAI/Microsoft)",
  gemini: "Gemini (Google)",
  llama2: "Llama2 (Meta)",
  claude3: "Claude3 (Anthropic)",
  palm2_text: "Palm2 (Google/Bard)",
  gpt_4_turbo: "GPT-4 Turbo (OpenAI)",
};

function validateMarkdownTable(
  markdownString,
  expectedRows,
  expectedColumns,
  numLLM,
  reportType
) {
  const tableLines = markdownString
    .trim()
    .split("\n")
    .filter((line) => line.includes("|"));

  if (tableLines.length < 2) {
    console.log("Not enough lines for a valid table.");
    return false;
  }

  const headerColumns = tableLines[0].split("|").length - 2;

  let numberOfRows = tableLines.length - 1;
  if (reportType === "Competitor Comparison") {
    numberOfRows = numberOfRows - numLLM + 1;
  }

  const isValid =
    headerColumns === expectedColumns && numberOfRows === expectedRows;

  console.log(
    `Expected rows: ${expectedRows}, Expected columns: ${expectedColumns}`
  );
  console.log(`Actual rows: ${numberOfRows}, Actual columns: ${headerColumns}`);

  return isValid;
}

function ComparisonReportingComponent() {
  const [reportData, setReportData] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [startPrompt, setStartPrompt] = useState("");
  const [endPrompt, setEndPrompt] = useState("");

  const [togglePrompt, setTogglePrompt] = useState(false);
  const [stringData, setStringData] = useState("");
  const [LLMUsedForReporting, setLLMUsedForReporting] = useState("");
  const [LLMsUsed, setLLMsUsed] = useState([]);
  const [reportTitle, setReportTitle] = useState("LLM Report Data");
  const [toggleLLMResponse, setToggleLLMResponse] = useState(false);
  const [LLMResponses, setLLMResponses] = useState([]);

  function constructPrompt() {
    setLoading(true);
    setError(null);
    setLoadingMessage("Fetching data from LLMs...");
    // fetchDataFromFirebase(promptData, "reportPrompt");
    let numberOfRows, numberofColums;

    async function promptData(configs) {
      const [{ LLM, prompt, endPrompt }] = configs;

      setLLMUsedForReporting(LLM);
      let storedQueries = localStorage.getItem("queries") || "[]";
      let { queries, attributes, reportType, inputs } =
        JSON.parse(storedQueries);

      setReportTitle(reportType);

      let promiseArr = [];
      for (let query of queries) {
        const abortController = new AbortController();
        if (query.llm === "gpt-4") {
          promiseArr.push(fetchGpt4API(query.inputPrompt, abortController));
        } else if (query.llm === "Gemini") {
          promiseArr.push(fetchGeminiAPI(query.inputPrompt, abortController));
        } else if (query.llm === "llama2_70b_chat") {
          promiseArr.push(fetchLlama2API(query.inputPrompt, abortController));
        } else if (query.llm === "Claude") {
          promiseArr.push(fetchClaude3API(query.inputPrompt, abortController));
        } else {
          promiseArr.push(
            fetchGooeyAPI(query.inputPrompt, query.llm, abortController)
          );
        }
      }

      //getting intermediate results here in promiseArr

      try {
        let LLMsResponseArr = await allProgress(promiseArr, (p) => {
          setLoadingMessage(
            `Fetching data from LLMs. <br/> <b> ${Number(p)} of 
            ${promiseArr.length} tasks done...</b>`
          );
        });

        LLMsResponseArr.forEach((res) => {
          if (res.status != "fulfilled") {
            toast.error(`Error! ${res.reason.message}`);
          }
        });

        LLMsResponseArr = LLMsResponseArr.filter(
          (res) => res.status === "fulfilled" && !res.value.error
        ).map((res) => res.value);

        setLLMResponses(LLMsResponseArr);
        const LLMsUsed = [];
        let stringData = ``;
        for (let llmResponse of LLMsResponseArr) {
          for (let key in llmResponse) {
            LLMsUsed.push(options[key.toLowerCase()]);
            stringData += `
            LLM: ${key}
            ${llmResponse[key]}
            `;
          }
        }

        setLLMsUsed(LLMsUsed);

        let promptText = null;

        const filteredAttributes = attributes.filter(
          (attribute) => attribute !== "Sources"
        );

        const compPrompt = reportPrompts.find(
          (prompt) => prompt.type === reportType
        );

        const updatedAttr = attributes.filter((attr) => attr != "Sources");

        if (compPrompt) {
          promptText = `
          ${compPrompt.start}

          ${stringData}
            
            Column names: Category, ${LLMsUsed.toString()}
            Row names: ${filteredAttributes.toString()}

          ${compPrompt.end}
        `;

          if (attributes.includes("Sources")) {
            promptText += `Add source links to each cell of table except headers which are utilized in response.`; //` Add last row of table named "Sources" which should include all source links with clickable hyperlinks, categorize them based on columns and rows. Don't add those in other rows, keep them in "Source" row only`
          }

          if (reportType === "Competitor Comparison") {
            promptText = promptText.replaceAll("<inputs>", inputs.toString());
            promptText = promptText.replaceAll(
              "<attributes>",
              filteredAttributes.toString()
            );
            promptText = promptText.replaceAll("<LLMs>", LLMsUsed.toString());
          } else {
            promptText = promptText.replaceAll("<LLMs>", LLMsUsed.toString());
            promptText = promptText.replaceAll(
              "<attributes>",
              filteredAttributes.toString()
            );
          }

          setStartPrompt(compPrompt.start);
          setEndPrompt(compPrompt.end);

          numberOfRows = filteredAttributes.length + 1;
          numberofColums = LLMsUsed.length + 1;
          if (reportType === "Competitor Comparison") {
            numberofColums = inputs.length + 1;
            numberOfRows = (filteredAttributes.length + 1) * LLMsUsed.length;
          }
        } else {
          // LLM comparison
          promptText = `${prompt}

            ${stringData}
            
            Column names: Category, ${LLMsUsed.toString()}
            Row names: ${filteredAttributes.toString()}
            
            ${endPrompt}`;

          if (attributes.includes("Sources")) {
            promptText += `Add source links to each cell of table except headers which are utilized in response.`;
          }
          setStartPrompt(prompt);
          setEndPrompt(endPrompt);
        }

        setStringData(stringData);

        Array.from({ length: 1 }).map((_) => {
          generateReport(
            promptText,
            LLM,
            numberOfRows,
            numberofColums,
            LLMsUsed.length,
            reportType
          );
        });
      } catch (error) {
        setLoading(false);
        setError("Something went wrong: " + error.message);
      }
    }
  }

  const generateReport = async (
    inputPrompt,
    LLM,
    numRows,
    numCols,
    numLLMs,
    reportType
  ) => {
    setLoading(true);
    setLoadingMessage("Summarizing data...");

    try {
      if (!reportData) setLoading(true);
      const abortController = new AbortController();
      let result;
      if (LLM === "GPT-4" || LLM === "GPT4") {
        let lastResult = null;
        for(let i of Array.from({ length: 3})) {
          result = await fetchGpt4API(inputPrompt, abortController, 1300);
          lastResult = result;
          const isValid = validateMarkdownTable(
            result["GPT-4"][0],
            numRows,
            numCols,
            numLLMs,
            reportType
          );
          if (!isValid) {
            console.log("LOG Retrying API", { isValid, reportData })
            continue;
          } else {
            setReportData(result["GPT-4"][0]);
            break;
          }
        }
        if(reportData === "") {
          setReportData(lastResult["GPT-4"][0]);
        }
      } else if (LLM === "Gemini") {
        result = await fetchGeminiAPI(inputPrompt, abortController);
        if (result["Gemini"]) {
          setReportData(result["Gemini"][0]);
        }
      } else if (LLM === "llama2_70b_chat") {
        result = await fetchLlama2API(inputPrompt, abortController);
        if (result["Llama2"]) {
          setReportData(result["Llama2"][0]);
        }
      } else {
        result = await fetchGooeyAPI(inputPrompt, LLM, abortController);
        setReportData(result[LLM][0]);
      }
    } catch (error) {
      setError("Something went wrong: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const links = extractLinks(reportData);
    validateLinks(links);
  }, [reportData]);

  async function validateLinks(links = []) {
    links = links ?? [];
    const linkPromises = links.map((link) =>
      checkWebLink(link, new AbortController())
    );
    const anchorsOnWeb = document.getElementsByTagName("a");

    for (let a of anchorsOnWeb) {
      a.innerText = a.href;
    }

    try {
      linkPromises.forEach((promise) => {
        promise
          .then((response) => {
            for (let anchorWeb of anchorsOnWeb) {
              if (anchorWeb.href === response.link.toString().trim()) {
                if (response.status == "success") {
                  anchorWeb.style.color = "rgb(13 110 253)";
                  anchorWeb.setAttribute("target", "_blank");
                } else {
                  anchorWeb.style.color = "gray";
                  anchorWeb.href = "#";
                  anchorWeb.classList.add("disabled");
                }
              } else {
                anchorWeb.setAttribute("target", "_blank");
                anchorWeb.classList.add("pending_link");
              }
            }
          })
          .catch((error) => {
            console.log("LOG ERRR", error);
          });
      });
    } catch (error) {
      console.log("Something went wrong: ", error.message);
    }
  }

  useEffect(() => {
    constructPrompt();
  }, []);

  const handleRegenerate = () => {
    let storedQueries = localStorage.getItem("queries") || "[]";
    let { attributes, reportType, inputs } = JSON.parse(storedQueries);

    let promptText = null;

    const compPrompt = reportPrompts.find(
      (prompt) => prompt.type === reportTitle
    );

    if (compPrompt) {
      promptText = `
          ${startPrompt}

          Raw String:
          ${stringData}

          Table should have following structure with your data in it
          ${generateMarkdownTable(attributes, inputs)}


          ${endPrompt}
        `;

      if (reportType === "Competitor Comparison") {
        promptText = promptText.replaceAll("<inputs>", inputs.toString());
        promptText = promptText.replaceAll(
          "<attributes>",
          attributes.toString()
        );
        promptText = promptText.replaceAll("<LLMs>", LLMsUsed.toString());
      } else {
        promptText = promptText.replaceAll("<LLMs>", LLMsUsed.toString());
      }
    } else {
      promptText = `${startPrompt}

            ${stringData}
            
            Column names: Category, ${LLMsUsed.toString()}
            Row names: ${attributes.toString()}
            
            ${endPrompt}`;
    }

    Array.from({ length: 1 }).map((_) => {
      generateReport(promptText, LLMUsedForReporting);
    });
  };

  return (
    <div className="p-4 d-flex flex-column align-items-center container">
      <ToastContainer autoClose={3000}></ToastContainer>
      <h3 className="mb-2">{reportTitle}</h3>
      <hr className="w-100" />
      {!loading && !error && (
        <ReportMenu
          setTogglePrompt={setTogglePrompt}
          reportTitle={reportTitle}
          setToggleLLMResponse={setToggleLLMResponse}
        />
      )}
      <div className="text-danger">{error}</div>
      {togglePrompt && (
        <ReportPrompt
          startPrompt={startPrompt}
          endPrompt={endPrompt}
          setStartPrompt={setStartPrompt}
          setEndPrompt={setEndPrompt}
          onSubmit={handleRegenerate}
          isLoading={loading}
        />
      )}
      {toggleLLMResponse && <LLMResultOutput LLMResponses={LLMResponses} />}
      <hr />
      {!loading && (
        <Card className={classes.results} id="report">
          <Markdown>{reportData}</Markdown>
        </Card>
      )}
      {loading && (
        <div className="container d-flex flex-column align-items-center mt-5">
          <Spinner />
          <span
            className="mt-3 text-center"
            dangerouslySetInnerHTML={{ __html: loadingMessage }}
          ></span>
        </div>
      )}
    </div>
  );
}

export default ComparisonReportingComponent;
