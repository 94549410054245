import { initializeApp } from "firebase/app";
import "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfigProd = {
  apiKey: "AIzaSyDze3iNTOBfsK4iuZnZOskwDAPt8zfa3kM",
  authDomain: "brand-luminaire-prod.firebaseapp.com",
  projectId: "brand-luminaire-prod",
  storageBucket: "brand-luminaire-prod.appspot.com",
  messagingSenderId: "543753959357",
  appId: "1:543753959357:web:cce3b7b1c4a09ea63bc4d4",
  measurementId: "G-DFV9CBLX1Z",
};

const firebaseConfigTest = {
  apiKey: "AIzaSyDSeIeLRM7rxxvhcoxlsHeaT75licd3p-8",
  authDomain: "brand-luminaire.firebaseapp.com",
  projectId: "brand-luminaire",
  storageBucket: "brand-luminaire.appspot.com",
  messagingSenderId: "372532793873",
  appId: "1:372532793873:web:0e54d4aa3b8825844617b0",
  measurementId: "G-DNC0DNYJC6",
};

const firebaseConfig =
  window.location.hostname === "www.brandluminaire.ai"
    ? firebaseConfigProd
    : firebaseConfigTest;

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

export default app;
