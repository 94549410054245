
import React, { useState, useRef } from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import PDFLayout from "../../MainDataComponent/PDFLayout";
import { ToastContainer, toast } from "react-toastify";

const BrandProductCoreAttributesTable = ({
  selectedCompCoreAttributes,
  brand,
  metrics,
  type,
  llm,
  categoryName,
  isOverview,
}) => {
  const [loading, setLoading] = useState(false);
  const pdfContentRef = useRef(null);

  // Predefined order of attributes
  const attributeOrder = [
    "Value",
    "Emotional Connection",
    "Customer Centricity",
    "Sustainability",
    "Quality",
    "Trustworthiness",
    "Innovation",
    "Social Responsibility",
  ];

  // Function to sort the attributes with handling for undefined cases
  function sortAttributes(data) {
    if (typeof data !== "object" || data === null) {
      throw new Error("Invalid input: data should be a non-null object.");
    }

    const sortedData = {};

    Object.keys(data).forEach((brand) => {
      const brandAttributes = data[brand];

      if (typeof brandAttributes !== "object" || brandAttributes === null) {
        throw new Error(`Invalid data structure for brand: ${brand}`);
      }

      sortedData[brand] = {};

      attributeOrder.forEach((attribute) => {
        sortedData[brand][attribute] =
          brandAttributes[attribute] !== undefined
            ? brandAttributes[attribute]
            : null; // Assign null if the attribute is missing
      });
    });

    return sortedData;
  }

  // Sort the attributes
  let sortedAttributes;
  try {
    const sortedBrandData = sortAttributes(selectedCompCoreAttributes);
    sortedAttributes = sortedBrandData;
  } catch (error) {
    console.error(error.message);
  }

  // Get the unique set of attributes
  const attributes = [
    ...new Set(
      Object.values(sortedAttributes).flatMap((brand) => Object.keys(brand))
    ),
  ];

  // Calculate the highest and lowest values for each attribute and store brands
  const attributeExtremes = attributes.reduce((acc, attribute) => {
    let max = -Infinity;
    let min = Infinity;
    let maxBrands = [];
    let minBrands = [];

    Object.keys(sortedAttributes).forEach((brand) => {
      const value = Number(sortedAttributes[brand][attribute]);
      if (!isNaN(value)) {
        if (value > max) {
          max = value;
          maxBrands = [brand]; // Reset maxBrands if a new max is found
        } else if (value === max) {
          maxBrands.push(brand); // Add to maxBrands if it matches the current max
        }

        if (value < min) {
          min = value;
          minBrands = [brand]; // Reset minBrands if a new min is found
        } else if (value === min) {
          minBrands.push(brand); // Add to minBrands if it matches the current min
        }
      }
    });

    acc[attribute] = { maxBrands, minBrands };
    return acc;
  }, {});

  const downloadPDF = async () => {
    setLoading(true);
    const options = {
      margin: [15, 15],
      filename: `${brand || "--"} ${metrics || ""}_${type || ""}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || "--"}
        metrics={metrics || "--"}
        type={type || "--"}
        llm={llm || "--"}
        categoryName={categoryName}
        targetEle={pdfContentRef.current}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      toast.error("Failed to generate PDF");
      console.error("Failed to download: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={(e) => {
            if (loading) return;
            downloadPDF();
          }}
        >
          {!loading && <FileDownloadOutlinedIcon />}
          {loading && <Spinner animation="border" />}
        </IconButton>
      </div>
      <div
        className="pdfContent"
        style={{ padding: "10px" }}
        ref={pdfContentRef}
      >
        <Table>
          <thead style={{ backgroundColor: "#CCCCCC" }}>
            <tr>
              <th>{isOverview ? "Date/Range" : "Brand/Product"}</th>
              {attributes.map((attribute, index) => (
                <th key={index}>{attribute}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(sortedAttributes).map((brand, index) => (
              <tr key={index}>
                <td>{brand}</td>
                {attributes.map((attribute, index) => {
                  const value = Number(sortedAttributes[brand][attribute]);
                  const isMax = attributeExtremes[attribute].maxBrands.includes(
                    brand
                  );
                  const isMin = attributeExtremes[attribute].minBrands.includes(
                    brand
                  );
                  const color = isMax
                    ? "green"
                    : isMin
                    ? "red"
                    : "black";
                  return (
                    <td style={{ color }} key={index}>
                      {isNaN(value) ? "N/A" : value.toFixed(0)}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </>
  );
};

export default BrandProductCoreAttributesTable;

