import { useRef } from "react";
import {
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";

import Spinner from "./../Component/Spinner/Spinner";

export default function AddCompanyUserForm({ customerId, onSubmit, onCancel, type }) {
  const { isAddingUser, users } = useSelector((state) => state.user);
  const companyInputRef = useRef();
  const companySelectRef = useRef();
  const emailInputRef = useRef();

  function handleFormSubmit(e) {
    e.preventDefault();
    let company = companyInputRef?.current?.value;
    if (type === "user") company = companySelectRef?.current?.value;
    onSubmit(company, emailInputRef?.current?.value);
  }

  return (
    <form className="d-flex flex-column mt-2" onSubmit={handleFormSubmit}>
      {type === "company" && (
        <TextField
          inputRef={companyInputRef}
          id="outlined-basic"
          size="small"
          label="Company Name"
          className="mb-4"
          variant="outlined"
        />
      )}
      {type === "user" && !customerId && (
        <FormControl fullWidth size="small" className="mb-4">
          <InputLabel id="company">Company</InputLabel>
          <Select
            labelId="company"
            label="Company"
            inputRef={companySelectRef}
          >
            {users
              .filter((user) => user.user_role.role_id === 2)
              .map((user) => (
                <MenuItem value={user.customer.id} key={user.customer.id}>
                  {user.customer.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      <TextField
        inputRef={emailInputRef}
        id="outlined-basic"
        size="small"
        label={type === "user" ? "User E-Mail" : "Admin E-Mail"}
        variant="outlined"
        type="email"
      />
      <div className="d-flex flex-row mt-4 justify-content-end">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <span style={{ marginRight: 10 }}></span>
        <Button
          disabled={isAddingUser}
          type="submit"
          variant="contained"
          className="ml-2"
        >
          {isAddingUser ? (
            <Spinner
              style={{ display: "flex", margin: 0, padding: 0 }}
              size={16}
            />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </form>
  );
}
