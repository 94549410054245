// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComparisionReporting_results__GADyl {
  padding: 30px;
}

table {
  margin-bottom: 24px !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.pending_link {
  color: #b8b856;
}`, "",{"version":3,"sources":["webpack://./src/Component/BasicCardComponent/Reporting/ComparisionReporting.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".results {\n  padding: 30px;\n}\n\n:global(table) {\n  margin-bottom: 24px !important;\n}\n\n:global(a.disabled) {\n  pointer-events: none;\n  cursor: default;\n}\n\n:global(.pending_link) {\n  color: #b8b856;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"results": `ComparisionReporting_results__GADyl`
};
export default ___CSS_LOADER_EXPORT___;
