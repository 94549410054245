import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Collapse } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";

const DashboardLLMsComponent = ({
  selectedItems,
  options,
  handleCheckChange,
  selectedLlmItems,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  // Function to handle click outside the component
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Form ref={ref}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant="default"
        style={{
          backgroundColor: "white",
          width: "240px",
          height: "50px",
          padding: "11.5px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1rem",
            fontWeight: "360",
          }}
        >
          <span>
            {selectedLlmItems.length !== 0 ? (
              <>
                {selectedLlmItems.length === 1 ? (
                  <span> {selectedLlmItems[0]} </span>
                ) : (
                  <span> ({selectedLlmItems.length}) selected </span>
                )}
              </>
            ) : (
              <>Select LLM</>
            )}
          </span>
          <FaAngleDown
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0)", // Rotate the icon based on the dropdown state
              transition: "transform 0.3s ease-in-out", // Add transition for smooth animation
            }}
          />
        </div>
      </Button>
      <Collapse
        style={{
          position: "absolute",
          backgroundColor: "white",
          width: "230px",
          zIndex: 1,
        }}
        in={open}
      >
        <div id="example-collapse-text">
          <Form.Group controlId="exampleForm.SelectCustom">
            {options.map((option) => (
              <Form.Check
                style={{ fontSize: "0.85rem", fontWeight: "600" }}
                key={option}
                type="checkbox"
                label={option}
                className={`mb-2 customData`}
                checked={selectedLlmItems.includes(option)}
                onChange={(event) => handleCheckChange(option)}
              />
            ))}
          </Form.Group>
        </div>
      </Collapse>
    </Form>
  );
};

export default DashboardLLMsComponent;
