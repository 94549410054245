import { Avatar } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";

function SentimentItem({ sentiment }) {
  return (
    <List>
      <ListItem disablePadding className="d-flex flex-column">
        {sentiment.map((product, index) => {
          return (
            <div key={index}>
              <span className="d-flex flex-row align-items-center mb-1">
                <h6 className="p-0 m-0">
                  {index + 1}. {product.name}
                </h6>
                <Chip
                  variant="outlined"
                  color="primary"
                  className="mx-2"
                  label="Sentiment"
                  avatar={<Avatar className="px-3">{product.sentiment}</Avatar>}
                />
              </span>
              <p>{product.response}</p>
              <hr />
            </div>
          );
        })}
      </ListItem>
    </List>
  );
}

export default SentimentItem;
