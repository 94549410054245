// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandIndexHistory_container__-2MU8 {
    max-height: 700px;
    overflow-y: scroll;
}
.BrandIndexHistory_header__-4kfL {
    position: sticky;
    top: 0;
    z-index: 10 !important;
    background-color: rgb(244, 244, 244);
    height: 70px !important;
}
.BrandIndexHistory_section__eFVyP {
    padding: 0px;
    margin-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Component/BrandIndexHistory/BrandIndexHistory.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,MAAM;IACN,sBAAsB;IACtB,oCAAoC;IACpC,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".container {\n    max-height: 700px;\n    overflow-y: scroll;\n}\n.header {\n    position: sticky;\n    top: 0;\n    z-index: 10 !important;\n    background-color: rgb(244, 244, 244);\n    height: 70px !important;\n}\n.section {\n    padding: 0px;\n    margin-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandIndexHistory_container__-2MU8`,
	"header": `BrandIndexHistory_header__-4kfL`,
	"section": `BrandIndexHistory_section__eFVyP`
};
export default ___CSS_LOADER_EXPORT___;
