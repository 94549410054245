import { Fragment } from "react";
import { Spinner } from "react-bootstrap";

import classes from "./BrandIndexProgress.module.css";

const steps = {
  0: "Getting products",
  1: "Calculating rank score",
  2: "Calculating favorability score",
  3: "Generating Brand Index Score Table",
};

const BrandIndexProgress = ({ step }) => {
  return (
    <Fragment>
      {steps[step] ? (
        <div className={classes.container}>
          <div className={classes.responseText}>
            <div>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className={classes.step}> {steps[step]}...</span>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default BrandIndexProgress;
