import React from "react";
import { Container, Card, Col, Form } from "react-bootstrap";
import "../../../../App.css";

function IndexScore({
  handlePromptBrandReachChange1,
  promptBrandReach1,
  onProductCountChange,
  productCount,
  industry,
  onChangeIndustry,
}) {
  return (
    <Container className="mb-3">
      <Card as={Col} md="12" className="border-0 whi">
        <Card.Body>
          <Card.Title className="mb-4">Brand Index Score</Card.Title>
          <Form.Group as={Col} md="5" style={{ width: "28rem" }}>
            <Form.Control
              as="textarea"
              rows={1}
              cols={2}
              placeholder="Enter Brand Category"
              name="promptBrandReach"
              value={promptBrandReach1}
              onChange={handlePromptBrandReachChange1}
              className="big1"
              style={{ height: "70px" }}
            />
          </Form.Group>
          <Form.Group style={{ width: "28rem", marginTop: "1rem" }}>
            <Form.Label>
              Number of products/brands you want to return
            </Form.Label>
            <Form.Select
              onChange={(e) => onProductCountChange(e.target.value)}
              value={productCount}
            >
              {Array.from({ length: 3 }).map((_, i) => {
                return (
                  <option key={i + 5} value={i + 5}>
                    {i + 5}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ width: "40rem", marginTop: "1rem" }}>
            <Form.Label>
              Specify the Industry and Country for the Entered Category
              (Optional)
            </Form.Label>
            <Form.Control
              type="text"
              style={{ width: "28rem" }}
              placeholder="e.g Manufacturing, Germany"
              name="industry"
              value={industry}
              onChange={(e) => onChangeIndustry(e.target.value)}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default IndexScore;