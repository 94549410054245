import { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

function ReportInput({
  selection,
  allSelections,
  index,
  onChange,
  LLMs,
  attributes,
  inputs,
}) {
  function Input({ type, input, idx }) {
    const inputRef = useRef();

    useEffect(() => {
      const lastFieldId = localStorage.getItem("idx");
      if (idx == lastFieldId) {
        inputRef?.current?.focus();
      }
    }, []);

    if (type === "checkbox") {
      const valueSet = selection.text === "Select LLMs" ? LLMs : attributes;
      return (
        <Form.Check
          type={"checkbox"}
          className="mb-2"
          id={input.value}
          label={input.name}
          onChange={onChange}
          checked={valueSet.includes(input.value)}
          disabled={input.name === "Google SGE"}
        />
      );
    } else {
      return (
        <div className="w-25 mb-4">
          <input
            ref={inputRef}
            type="text"
            id={`${idx}`}
            placeholder={input.name}
            onChange={(e) => {
              localStorage.setItem("idx", idx);
              onChange(e);
            }}
            onBlur={() => {
              localStorage.removeItem("idx");
            }}
            value={inputs.length > 0 ? inputs[idx] : ""}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <h6>{selection.text}</h6>
      <div>
        {selection.values.map((input, idx) => {
          return (
            <Input key={idx} type={selection.type} input={input} idx={idx} />
          );
        })}
      </div>
      {allSelections.length - 1 != index && <hr />}
    </div>
  );
}

export default ReportInput;
