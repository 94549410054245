import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

const ROUTE_MOCKUP = {
  Discovery: "",
  Monitoring: "monitors",
  Reporting: "reporting",
  Dashboard: "dashboard",
  Admin: "admin",
  // Dashboard2: "dashboard2",
};

function LinkTab(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // const { isCurrentUserAdmin } = React.useContext(AuthContext);

  const handleClick = (event) => {
    event.preventDefault();
    let tabValue = Object.keys(ROUTE_MOCKUP).indexOf(props.label);
    // tabValue = isCurrentUserAdmin ? tabValue : tabValue - 1;
    navigate(`/${ROUTE_MOCKUP[props.label]}?tab=${tabValue}`);
  };

  return (
    <Tab
      sx={{color:'black',fontWeight:'450'}}
      onClick={handleClick}
      selected={
        location.search ===
        `?tab=${Object.keys(ROUTE_MOCKUP).indexOf(props.label)}`
      }
      {...props}
    />
  );
}

export default function NavTabs() {
  const location = useLocation();
  const { isCurrentUserAdmin, token } = React.useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const tab = 0;
  const initialTabValue = searchParams.has("tab")
    ? parseInt(searchParams.get("tab"))
    : tab;
  const tabStyle = isCurrentUserAdmin ? { width: "68%" } : { width: "58%" };

  const [value, setValue] = React.useState(initialTabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);

  return (
    <Box sx={tabStyle}>
      <Tabs value={value} onChange={handleChange} role="navigation">
        {token !== null && <LinkTab label="Discovery" />}
        {token !== null && <LinkTab label="Monitoring" />}
        {token !== null && <LinkTab label="Reporting" />}
        {token !== null && <LinkTab label="Dashboard" />}
        {/* {token !== null && <LinkTab label="Dashboard2" />} */}
        {isCurrentUserAdmin && <LinkTab label="Admin" />}
        
      </Tabs>
    </Box>
  );
}
