// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BrandHistoryDetailItem_container__2Ngd- {
    background-color:#000000cf;
    border-radius: 4px;
    color: white;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
}

.BrandHistoryDetailItem_container__2Ngd- table th, td {
   /* border: 1px solid #545454 !important; */
}

.BrandHistoryDetailItem_divider__fz27L {
    border: 1px solid #545454;
}

.BrandHistoryDetailItem_badge__0N4Xs {
    background-color: #37ad52;
    color: #fff;
    padding: 5px;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    left: -13px;
    z-index: 6;
}

.BrandHistoryDetailItem_date__y-uFm {
    display: flex;
    align-self: flex-end;
    font-size: 12px;
    opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/Component/BrandIndexHistory/BrandHistoryDetailItem.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;GACG,0CAA0C;AAC7C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,UAAU;AACd;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".container {\n    background-color:#000000cf;\n    border-radius: 4px;\n    color: white;\n    margin-bottom: 40px;\n    padding: 20px;\n    position: relative;\n}\n\n.container table th, td {\n   /* border: 1px solid #545454 !important; */\n}\n\n.divider {\n    border: 1px solid #545454;\n}\n\n.badge {\n    background-color: #37ad52;\n    color: #fff;\n    padding: 5px;\n    border-radius: 2px;\n    position: absolute;\n    top: 8px;\n    left: -13px;\n    z-index: 6;\n}\n\n.date {\n    display: flex;\n    align-self: flex-end;\n    font-size: 12px;\n    opacity: 0.7;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BrandHistoryDetailItem_container__2Ngd-`,
	"divider": `BrandHistoryDetailItem_divider__fz27L`,
	"badge": `BrandHistoryDetailItem_badge__0N4Xs`,
	"date": `BrandHistoryDetailItem_date__y-uFm`
};
export default ___CSS_LOADER_EXPORT___;
