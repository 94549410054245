import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import html2pdf from "html2pdf.js";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Spinner from "react-bootstrap/Spinner";
import * as ReactDOMServer from "react-dom/server";

import { AuthContext } from "../../context/auth-context";
import {
  fetchDataFromFirebase,
  deleteDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";
import PDFLayout from "./PDFLayout";
import BrandIndexHistory from "../BrandIndexHistory/BrandIndexHistory";
import { BrandIndexContext } from "../../context/brand-index-context";
import BrandIndexHierarchical from "../BrandIndexHistory/BrandIndexHierarchical";
import BrandHistoryDetailItem from "../BrandIndexHistory/BrandHistoryDetailItem";
import BrandIndexProgress from "./BrandIndexProgress";

const mappingObj = {
  "gpt_4_turbo": "GPT4 Turbo",
  "GPT-4o": "GPT-4o",
  "GPT-3.5": "GPT-3.5",
  "palm2_text": "Palm2",
  "Llama2": "Llama2",
  "Llama3": "Llama3",
  "GPT-4": "GPT-4",
  "Gemini": "Gemini",
  "Perplexity": "Perplexity",
  "Claude3": "Claude3",
  "GPT-4_Web": "GPT-4_Web",
  "Google-SGE": "Google SGE"
}

const LLMDataDisplayComponent = ({
  dataItem,
  copyToClipboard,
  showGetData,
  handleEditData,
  editedData,
  promptData,
  selectedOption,
  brandIndexStep,
  showData,
  llm,
  userPositionInQueue
}) => {
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDateIds, setSelectedDateIds] = useState([]);
  const [historyTitle, setHistoryTitle] = useState("");
  const [brandAndType, setBrandAndType] = useState({ brand: "", type: "" });
  const [toggleBrandIndexTable, setToggleBrandIndexTable] = useState(true);

  const { authUserEmail } = useContext(AuthContext);
  const { showHistory, type: historyType, historyItem } = useContext(BrandIndexContext);

  //--------------------- Match Notification Data ---------------------//

  const handleOpenEditHistoryInput = (itemIndex, dateItemIndex, title) => {
    setSelectedIds([itemIndex]);
    setSelectedDateIds([dateItemIndex]);
    setHistoryTitle(title);
  };

  const handleEditHistoryTitle = (data) => {
    let currentData = data;
    currentData["customLabel"] = historyTitle;
    let collectionName = "FirebaseData";
    let documentId = data.key;
    updateDataInFirebase(collectionName, documentId, currentData).then(() => {
      setSelectedIds([]);
      setSelectedDateIds([]);
    });
  };

  function extractTableFromMarkdown(markdownString) {
    var tableRegex = /(\|.*?\|)\s*$/gm;
    var tableRows = markdownString.match(tableRegex);
    if (!tableRows) return '';
    var markdownTable = tableRows.join('\n');
    return markdownTable;
  }

  useEffect(() => {
    // fetchDataFromFirebase(
    //   (data) => {
    //     if (data !== null) {
    //       const arrayOfObjects = Object.entries(data).map(([key, value]) => ({
    //         key,
    //         ...value,
    //       }));
    //       setDataHistory(arrayOfObjects);
    //     }
    //   },
    //   "FirebaseData",
    //   authUserEmail
    // );
  }, []);

  const downloadPDF = async (targetEle, brand, type, llm) => {
    setLoading(true);
    const options = {
      margin: [15, 15],
      filename: `${llm}_${brand || brandAndType.brand || ""}_${
        type ?? brandAndType.type
      }`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || brandAndType.brand || "--"}
        llm={llm}
        type={type ?? brandAndType.type}
        targetEle={targetEle}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      toast.error("Failed to generate PDF");
      console.error("Failed to download: ", err);
    } finally {
      setLoading(false);
    }
  };

  const groupDataByDate = (data) => {
    return data.reduce((acc, entry) => {
      const date = entry.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].unshift(entry);
      return acc;
    }, {});
  };

  const groupedDataByDate = groupDataByDate(dataHistory);

  const isToday = (date) => {
    const todayDate = new Date();
    const entryDate = new Date(date);
    return (
      entryDate.getDate() === todayDate.getDate() &&
      entryDate.getMonth() === todayDate.getMonth() &&
      entryDate.getFullYear() === todayDate.getFullYear()
    );
  };

  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const entryDate = new Date(date);
    return (
      entryDate.getDate() === yesterday.getDate() &&
      entryDate.getMonth() === yesterday.getMonth() &&
      entryDate.getFullYear() === yesterday.getFullYear()
    );
  };

  const getDateDisplay = (date) => {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return "Previous";
    }
  };

  const sortedDates = Object.keys(groupedDataByDate).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateB - dateA;
  });

  const handleDeleteData = (itemKey) => {
    deleteDataFromFirebase(itemKey, "FirebaseData", () => {
      setDataHistory((prevData) =>
        prevData.filter((item) => item.key !== itemKey)
      );
    });
  };

  return (
    <Row className="mt-5 mb-4">
      <Col md="8">
        {showGetData && !showHistory && (
          <Card className="border border-secondary-subtle rounded-0">
            <Card.Header className="float-start p-3 bottom">LLMs</Card.Header>
            <Container className="mt-3">
              <Card.Body>
                {editedData ? (
                  <>
                    {Object.keys(editedData).map((name, index) => {
                      const options = {
                        overrides: {
                          a: {
                            component: (props) => (
                              <a
                                {...props}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {props.children}
                              </a>
                            ),
                          },
                        },
                      };
                      return (
                        <>
                          <div className="dboxcont" key={name}>
                            <nav className="card-header-actions">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() =>
                                  copyToClipboard(editedData[name][0])
                                }
                              >
                                <ContentCopyOutlinedIcon
                                  style={{ color: "whitesmoke" }}
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={(e) => {
                                  if (loading) return;
                                  const target = e.target
                                    .closest(".dboxcont")
                                    .querySelector(".content");
                                  downloadPDF(
                                    target,
                                    promptData,
                                    selectedOption,
                                    name
                                  );
                                }}
                              >
                                {!loading && (
                                  <FileDownloadOutlinedIcon
                                    style={{ color: "whitesmoke" }}
                                  />
                                )}
                                {loading && (
                                  <Spinner animation="border" style={{color: 'whitesmoke'}}/>
                                )}
                              </IconButton>
                            </nav>
                            <span className="brnd">
                              { mappingObj[name]}
                            </span>
                            <div
                              className="content"
                              style={{ padding: "10px" }}
                            >
                              <Markdown
                                options={options}
                                style={{
                                  display: "table",
                                  borderCollapse: "separate",
                                  boxSizing: "border-box",
                                  textIndent: "initial",
                                  borderSpacing: "2px",
                                  borderColor: "gray",
                                  tableLayout: "fixed",
                                  width: "100%",
                                  textWrap: "wrap"
                                }}
                                className="markTable"
                              >
                                {editedData[name][0]}
                              </Markdown>
                            </div>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {Object.keys(dataItem).map((name) => {
                      if (
                        name === "GPT-4_Web" &&
                        selectedOption === "Brand Index Score"
                      ) {
                        let llmResponseMarkdown = dataItem[name][0];
                        if (typeof llmResponseMarkdown == "string") {

                        

                          if (toggleBrandIndexTable) {
                            dataItem = {
                              [name]: [extractTableFromMarkdown(llmResponseMarkdown)],
                            };
                          } else {
                            dataItem = {
                              [name]: [llmResponseMarkdown],
                            };
                          }
                        } else {
                          dataItem = {
                            [name]: [""],
                          };
                        }
                      }
                      const options = {
                        overrides: {
                          a: {
                            component: (props) => (
                              <a
                                {...props}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {props.children}
                              </a>
                            ),
                          },
                        },
                      };
                      return (
                        <div className="dboxcont mb-4" key={name}>
                          <nav className="card-header-actions">
                            <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() =>
                                  copyToClipboard(dataItem[name][0])
                                }
                              >
                                <ContentCopyOutlinedIcon
                                  style={{ color: "whitesmoke" }}
                                  color="primary"
                                  fontSize="small"
                                />
                            </IconButton>
                            <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={(e) => {
                                  if (loading) return;
                                  const target = e.target
                                    .closest(".dboxcont")
                                    .querySelector(".content");
                                  downloadPDF(
                                    target,
                                    promptData,
                                    selectedOption,
                                    name
                                  );
                                }}
                              >
                                {!loading && (
                                  <FileDownloadOutlinedIcon
                                    style={{ color: "whitesmoke" }}
                                  />
                                )}
                                {loading && (
                                  <Spinner animation="border" style={{color: 'whitesmoke'}} />
                                )}
                              </IconButton>
                          </nav>

                          <span className="brnd">
                            { mappingObj[name] }
                          </span>
                          {/* <h4 className="card-title">{selectedOption} </h4>*/}
                          <div className="content" style={{ padding: "10px" }}>
                            <Markdown
                              options={options}
                              style={{
                                display: "table",
                                borderCollapse: "separate",
                                boxSizing: "border-box",
                                textIndent: "initial",
                                borderSpacing: "2px",
                                borderColor: "gray",
                                tableLayout: "fixed",
                                width: "100%",
                                textWrap: "wrap"
                              }}
                              className="markTable"
                            >
                              {dataItem[name][0]}
                            </Markdown>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </Card.Body>
            </Container>
          </Card>
        )}
        {/* { showData && selectedOption === "Brand Index Score v2" && <BrandIndexProgress step={brandIndexStep}/> } */}
        { showHistory && historyType === 'list' ? <BrandIndexHierarchical/>: null }
        { showHistory && historyType === 'individual' && historyItem ? <BrandHistoryDetailItem hierarchy={historyItem.hierarchy} markTable={historyItem.markTable} item={historyItem.hierarchy}/>: null }
      </Col>
      {/*----------------- Display Section --------------------*/}
      <Col md="4">
        <BrandIndexHistory />
        <hr />
        <Card className="border border-secondary-subtle mb-2 rounded-0">
          <Card.Header className="float-start  p-3 bottom">
            <strong>History</strong>
          </Card.Header>
          <Container className="mt-3">
            <Card.Body className="p-0">
              {sortedDates.map((date, dateIndex) => (
                <div key={dateIndex}>
                  <span className="d-block">
                    {getDateDisplay(date)} - {date}
                  </span>
                  {groupedDataByDate[date].map((data, dataIndex) => {
                    const title = data.customLabel
                      ? data.customLabel
                      : data.item
                      ? ` ${data.brandOrProduct || ""} ${data.category || ""} ${
                          data.item
                        }`
                      : "Key Prompt Data";
                    if (
                      selectedIds.includes(dataIndex) &&
                      selectedDateIds.includes(dateIndex)
                    ) {
                      return (
                        <div className="histoblck" key={dataIndex}>
                          <span>
                            <input
                              style={{ width: "240px" }}
                              value={historyTitle}
                              placeholder="History Label"
                              onChange={(e) => {
                                setHistoryTitle(e.target.value);
                              }}
                            />
                          </span>
                          <svg
                            style={{ marginTop: "3px" }}
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="1.2em"
                            width="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleEditHistoryTitle(data)}
                          >
                            <title>Save</title>
                            <path d="M5 13l4 4L19 7"></path>
                          </svg>
                          <svg
                            style={{ marginTop: "3px" }}
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="1.2em"
                            width="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              setSelectedDateIds([]);
                              setSelectedIds([]);
                            }}
                          >
                            <title>Cancel</title>
                            <path d="M6 18L18 6M6 6l12 12"></path>
                          </svg>
                        </div>
                      );
                    }

                    return (
                      <div className="histoblck" key={dataIndex}>
                        <h4 className="card-title2 mt-1">
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="15px"
                            width="15px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                          </svg>
                          {title}
                        </h4>
                        <span style={{ cursor: "pointer" }}>
                          {/*------------- View  -------------*/}
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="1.2em"
                            width="1.5em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              setBrandAndType({
                                brand: data.brandOrProduct,
                                type: data.item,
                              });
                              handleEditData(data.data, data);
                            }}
                          >
                            <title>View</title>
                            <ellipse cx="12" cy="12" rx="10" ry="6"></ellipse>
                            <circle cx="12" cy="12" r="3.5"></circle>
                          </svg>
                          {/*------------- Edit Name -------------*/}
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() =>
                              handleOpenEditHistoryInput(
                                dataIndex,
                                dateIndex,
                                title
                              )
                            }
                          >
                            <title>Edit</title>
                            <path d="M12 20h9"></path>
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                          </svg>
                          &nbsp;
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon-sm"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handleDeleteData(data.key)}
                          >
                            <title>Delete</title>
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </span>
                      </div>
                    );
                  })}
                  <hr className="mt-4" />
                </div>
              ))}
            </Card.Body>
          </Container>
        </Card>
      </Col>
      <ToastContainer />
    </Row>
  );
};

export default LLMDataDisplayComponent;
