import { useEffect, useContext, useState } from "react";
import { Table, Button, Alert } from "react-bootstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Switch } from "@mui/material";

import classes from "./../Settings/Component/Table.module.css";
import {
  deleteUser,
  getUsers,
  updateUser,
} from "../../redux/actions/userActions";
import { AuthContext } from "./../../context/auth-context";
import ErrorView from "../../Component/ErrorView/ErrorView";
import Spinner from "./../../Component/Spinner/Spinner";
import EmptyView from "./../../Component/EmptyView/EmptyView";

export default function Users() {
  const dispatch = useDispatch();
  const {
    users,
    isLoading,
    error,
    isDeleting,
    isUpdating,
    deleteUserSuccess,
    updateUserSuccess,
    deleteUserError,
    updateUserError,
    addUserError,
  } = useSelector((state) => state.user);
  const authCtx = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    dispatch(getUsers(authCtx.user.customer_id));
  }, []);

  const userDeleteHandler = (userId) => {
    dispatch(deleteUser(authCtx.user, userId));
  };

  const userUpdateHandler = (user) => {
    dispatch(
      updateUser(authCtx.user, user.id, {
        is_verified: !user.is_verified,
        is_active: user.is_active,
      })
    );
  };

  let errorMessage = null;
  if (deleteUserError) {
    errorMessage = `Failed to delete an user - ${deleteUserError}`;
  }
  if (updateUserError) {
    errorMessage = `Failed to update an user - ${updateUserError}`;
  }
  if (addUserError) {
    errorMessage = `Failed to add an user - ${addUserError}`;
  }

  return (
    <>
      {!isLoading && error && (
        <ErrorView
          title="Something went wrong"
          message="Couldn't load users. try again later."
        />
      )}
      {errorMessage && <Alert variant={"danger"}>{errorMessage}</Alert>}
      {isLoading && users.length === 0 && <Spinner />}
      {users.length === 0 && !isLoading && !error && (
        <EmptyView message="Nothing here." />
      )}
      {!error && users.length > 0 && (
        <Table>
          <thead style={{ backgroundColor: "#CCCCCC" }}>
            <tr>
              <th>Id</th>
              <th>Email</th>
              <th>Is Verified</th>
              <th>Is Active</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr>
                  <td>{user.id}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.is_verified ? (
                      <VerifiedIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </td>
                  <td>
                    <Switch
                      disabled={authCtx.user.id === user.id}
                      defaultChecked={Boolean(user.is_active)}
                      onChange={(e) =>
                        dispatch(
                          updateUser(authCtx.user, user.id, {
                            is_active: e.target.checked ? 1 : 0,
                            is_verified: user.is_verified,
                          })
                        )
                      }
                    />
                  </td>
                  <td>
                    {moment(user.date_added).format("DD-MM-YYYY hh:mm A")}
                  </td>
                  {authCtx.user.id !== user.id ? (
                    <td className={classes.actions}>
                      <Button
                        style={{
                          backgroundColor: "green",
                        }}
                        variant="success"
                        disabled={user.id === selectedUser?.id && isUpdating}
                        onClick={() => {
                          setSelectedUser(user);
                          userUpdateHandler(user);
                        }}
                      >
                        {user.id === selectedUser?.id && isUpdating ? (
                          <Spinner
                            style={{
                              display: "inline",
                            }}
                            color="white"
                            size={16}
                          />
                        ) : (
                          `Mark as ${
                            user.is_verified ? "un-verified" : "verified"
                          }`
                        )}
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#d32f2f",
                        }}
                        disabled={user.id === selectedUser?.id && isDeleting}
                        variant="danger"
                        onClick={() => {
                          setSelectedUser(user);
                          userDeleteHandler(user.id);
                        }}
                      >
                        {user.id === selectedUser?.id && isDeleting ? (
                          <Spinner
                            style={{
                              display: "inline",
                            }}
                            size={16}
                            color="white"
                          />
                        ) : (
                          "Delete"
                        )}
                      </Button>
                    </td>
                  ) : (
                    <td> -- </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
}
