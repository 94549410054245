import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import { fetchBrands } from "../../redux/actions/brandsActions";
import "react-toastify/dist/ReactToastify.css";
import DashboardLLMsComponent from "./DashboardComponent/DashboardLLMs";
import "../../App.css";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import DashboardBrandProductComponent from "./DashboardComponent/DashboardBrandProductComponent";
import DashboardBrandProductPersonaComponent from "./DashboardComponent/DashboardBrandProductPersonaComponent";
import DashboardFrequencyComponent from "./DashboardComponent/DashboardFrequencyComponent";
import { DashboardMultiBarChart } from "./common/Barchart";
import LineChart from "./common/Linechart";
import { layout } from "../LayoutComponent/LayoutComponent";
import {
  fetchBrandNames,
  fetchProductNames,
  // fetchLlms,
  getAttributes,
  getBrandIndexScores,
  fetchCustomerLlms,
  getLlms,
  getMarkets,
  getProductAttributes,
  getBrandAttributes,
  getBrandCoreAttributes,
  getProductCoreAttributes,
  getIndexScoreForBrand,
  getIndexScoreForProduct,
  getCompMetricsForBrand,
  getCompMetricsForProduct,
} from "../../services/apiService";
import { AuthContext } from "../../context/auth-context";
import useQuery from "./common/useQuery";
import BrandProductCoreAttributesTable from "./common/BrandProductCoreAttributesTable";
import BrandProductAttributesTable from "./common/BrandProductAttributesTable";
import BrandProductIndexScoresTable from "./common/BrandProductIndexScoresTable";
import MarketComponent from "./DashboardComponent/MarketComponent";

function Dashboard() {
  const [value, setValue] = useState(0);
  const [selectedDatevalue, setSelectedDateValue] = useState();
  const [selectedOptionShow, setSelectedOptionShow] = useState();

  const [showData, setShowData] = useState(false);
  const [positiveAttributes, setPositiveAttributes] = useState([]);
  const [negativeAttributes, setNegativeAttributes] = useState([]);
  const [isDone, setIsDone] = useState(true);
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedMarketItems, setSelectedMarketItems] = useState([]);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedFrequencyItems, setSelectedFrequencyItems] = useState([
    "Daily",
  ]);
  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] = useState([
    "Current",
  ]);
  const [brandItems, setBrandItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [selectedBrandItems, setSelectedBrandItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [customDate, setCustomDate] = useState();
  const [additionalDashboradInfo, setAdditionalDashboradInfo] = useState({});
  const [brandIndexDataSet, setBrandIndexDataSet] = useState([]);
  const [brandFavDataSet, setBrandFavDataSet] = useState([]);
  const [brandReachDataSet, setBrandReachDataSet] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);
  const [coreAttributesData, setCoreAttributesData] = useState([]);
  const [indexScores, setIndexScores] = useState();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [compCoreAttributes, setCompCoreAttributes] = useState();
  const [avgBrandIndex, setAvgBrandIndex] = useState();
  const [llmAttributes, setLlmAttributes] = useState();
  const [llmNegativeAttributes, setLlmNegativeAttributes] = useState();
  const [llmAttributesSources, setLlmAttributesSources] = useState();
  const [llmNegativeAttributesSources, setLlmNegativeAttributesSources] =
    useState();
  const [selectedCompCoreAttributes, setSelectedCompCoreAttributes] =
    useState();

  const [selectedAttributes, setSelectedAttributes] = useState();
  const [selectedNegativeAttributes, setSelectedNegativeAttributes] =
    useState();
  const [selectedAttributesSources, setSelectedAttributesSources] = useState();
  const [
    selectedNegativeAttributesSources,
    setSelectedNegativeAttributesSources,
  ] = useState();
  const [llmCompBrandIndex, setLlmCompBrandIndex] = useState([]);
  const [llmCompBrandReach, setLlmCompBrandReach] = useState([]);
  const [llmCompBrandFav, setLlmCompBrandFav] = useState([]);
  const [selectedLlmCompBrandIndex, setSelectedLlmCompBrandIndex] = useState(
    []
  );
  const [selectedLlmCompBrandReach, setSelectedLlmCompBrandReach] = useState(
    []
  );
  const [selectedLlmCompBrandFav, setSelectedLlmCompBrandFav] = useState([]);
  const [brandsAvgRankData, setBrandsAvgRankData] = useState([]);
  const [avgRankSourcesData, setAvgRankSourcesData] = useState([]);
  const [categoryDimensionData, setCategoryDimensionData] = useState();
  const [categoryDimensionSourceData, setCategoryDimensionSourceData] =
    useState();
  const [compPositiveAttributes, setCompPositiveAttributes] = useState();
  const [compNegativeAttributes, setCompNegativeAttributes] = useState();
  const [selectedCompPositiveAttributes, setSelectedCompPositiveAttributes] =
    useState();
  const [selectedCompNegativeAttributes, setSelectedCompNegativeAttributes] =
    useState();
  const [customerLlm, setCustomerLlm] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [brandIndexScore, setBrandIndexScore] = useState();
  const [brandCoreAttributesScore, setBrandCoreAttributesScore] = useState();
  const [brandPositiveAttributesStrength, setBrandPositiveAttributesStrength] =
    useState();
  const [brandNegativeAttributesStrength, setBrandNegativeAttributesStrength] =
    useState();
  const [indexScoreDataForChart, setIndexScoreDataForChart] = useState([]);

  const query = useQuery();
  const selectedBrandId = query.get("brandId") || 1;
  const productId = query.get("brandProductId");
  const selectedPersonaId = query.get("personaId");
  const selectedLlmId = query.get("llmId");
  const selectedMarketId = query.get("marketId");

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const brands = useSelector((state) => state.brands);

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id;

  const handleTabChange = (event, newValue) => {
    setShowData(false);
    setValue(newValue);
    // setCustomDate("");
    // setSelectedSubFrequencyItems([]);
    // setIsCustomDate(false);
    setSelectedLlmItems(["gpt-4o"]);
    if (newValue !== 3) {
      setSelectedMarketItems(["General Market"]);
    }

    if (newValue !== 4) {
      setSelectedPersona(["General Persona"]);
    }

    if (newValue !== 0) {
      setSelectedBrandItems(
        selectedBrandItems[0] ? [selectedBrandItems[0]] : []
      );
    }
  };

  const handleDateTabChange = (event, newValue) => {
    setSelectedDateValue(newValue);
    const filterKey = getDayWeekMonthYear(newValue)?.day;
    if (value == 1) {
      const filteredObject = Object.keys(compCoreAttributes)
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = compCoreAttributes[key];
          return result;
        }, {});

      setSelectedCompCoreAttributes(filteredObject[filterKey]);
    } else if (value == 2 || value == 3 || value == 4) {
      const filteredObject = Object.keys(llmAttributes)
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = llmAttributes[key];
          return result;
        }, {});
      const filteredNegativeAttributesObject = Object.keys(
        llmNegativeAttributes
      )
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = llmNegativeAttributes[key];
          return result;
        }, {});

      const filteredBrandIndexObject = Object.keys(llmCompBrandIndex)
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = llmCompBrandIndex[key];
          return result;
        }, {});

      const filteredBrandFavObject = Object.keys(llmCompBrandFav)
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = llmCompBrandFav[key];
          return result;
        }, {});

      const filteredBrandReachObject = Object.keys(llmCompBrandReach)
        .filter((key) => key == filterKey)
        .reduce((result, key) => {
          result[key] = llmCompBrandReach[key];
          return result;
        }, {});

      setSelectedLlmCompBrandIndex(filteredBrandIndexObject[filterKey]);
      setSelectedLlmCompBrandFav(filteredBrandFavObject[filterKey]);
      setSelectedLlmCompBrandReach(filteredBrandReachObject[filterKey]);
      setSelectedAttributes(filteredObject[filterKey]);
      setSelectedNegativeAttributes(
        filteredNegativeAttributesObject[filterKey]
      );
    }
  };

  useEffect(() => {
    if (selectedOptionShow === "Product") {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        // const activeData = brandProducts?.data?.data.filter(
        //   (obj) => obj.is_active
        // );

        const activeData = brandProducts?.data?.data;

        const selectedData = brandProducts?.data?.data.filter(
          (obj) => obj.id == productId
        );

        setSelectedProductItems([selectedData[0]?.brand_product?.name]);
        setSelectedPersona([
          selectedData[0]?.customer_brand_product_personas[0]?.persona?.name,
        ]);
        setSelectedLlmItems(["gpt-4o"]);
        setSelectedMarketItems(["General Market"]);

        setProductItems(activeData);

        setPersonas(selectedData[0]?.customer_brand_product_personas);
        const brandProductId = selectedData[0]?.id || null;

        const globalLlmId = dbLlms.filter((item) => item?.name === "gpt-4o")[0]
          ?.id;
        const marketId = markets.filter(
          (item) => item?.name === "General Market"
        )[0]?.id;
        const llmId = customerLlm.filter(
          (item) => item?.llm_id == globalLlmId && item?.market_id == marketId
        )[0]?.id;
        const personaId = [
          selectedData[0]?.customer_brand_product_personas[0]?.persona?.id,
        ];
        const productName = selectedData[0]?.brand_product?.name;
        handleDashboardInitialData(
          undefined,
          brandProductId,
          llmId,
          personaId,
          undefined,
          productName
        );
      }
    }
  }, [brandProducts, dbLlms]);

  useEffect(() => {
    if (selectedOptionShow === "Company/Brand") {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        // const activeData = brands?.data?.data;
        const selectedData = brands?.data?.data.filter(
          (obj) => obj.id == selectedBrandId
        );
        let personaIndex = selectedData[0]?.persona.findIndex(
          (obj) => obj?.id == selectedPersonaId
        );

        personaIndex = personaIndex > 0 ? personaIndex : 0;
        let llmFromQuery = dbLlms.filter((obj) => obj?.id == selectedLlmId);
        llmFromQuery =
          llmFromQuery && llmFromQuery.length !== 0
            ? llmFromQuery[0]?.name
            : "gpt-4o";
        let marketFromQuery = markets.filter(
          (obj) => obj?.id == selectedMarketId
        );
        marketFromQuery =
          marketFromQuery && marketFromQuery.length !== 0
            ? marketFromQuery[0]?.name
            : "General Market";

        setSelectedBrandItems([selectedData[0]?.brand?.name]);
        setSelectedPersona([
          selectedData[0]?.persona[personaIndex]?.persona?.name,
        ]);
        setSelectedLlmItems([llmFromQuery]);
        setSelectedMarketItems([marketFromQuery]);
        // setProductItems(brandProducts?.data?.data);
        setBrandItems(activeData);

        setPersonas(selectedData[0]?.persona);
        const brandId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const llmId = customerLlm.filter(
          (item) => item?.llm_id == globalLlmId && item?.market_id == marketId
        )[0]?.id;
        const personaId = [selectedData[0]?.persona[personaIndex]?.persona?.id];
        const brandName = selectedData[0]?.brand?.name;
        handleDashboardInitialData(
          brandId,
          undefined,
          llmId,
          personaId,
          brandName,
          undefined,
          selectedData[0]?.brand?.name
        );
      }
    }
  }, [brands, dbLlms]);

  useEffect(() => {
    const currentDate = new Date();
    const twoDayBefore = new Date();
    const oneDayBefore = new Date();
    twoDayBefore.setDate(currentDate.getDate() - 2);
    oneDayBefore.setDate(currentDate.getDate() - 1);
    currentDate.setDate(currentDate.getDate());

    setEndDate(currentDate.toISOString().split("T")[0]); // ISO format: YYYY-MM-DD
    setStartDate(twoDayBefore.toISOString().split("T")[0]);

    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }

    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }

    async function fetchData() {
      const llms = await getLlms();
      const market = await getMarkets();
      const customerSpecificLlms = await fetchCustomerLlms(customerId);
      setDbLlms(llms?.data);
      setCustomerLlm(customerSpecificLlms?.data);
      setMarkets(market?.data);
    }

    if (query.get("brandId") || query.get("brandProductId")) {
      if (query.get("brandId")) {
        setSelectedOptionShow("Company/Brand");
      } else if (query.get("brandProductId")) {
        setSelectedOptionShow("Product");
      }
    } else {
      setSelectedOptionShow("Company/Brand");
    }

    fetchData();
  }, []);

  useEffect(() => {
    const dateMapping = {
      Current: 0,
      "last 3 days Average": 2,
      "last 7 days Average": 6,
      "last 15 days Average": 14,
      "last 21 days Average": 20,
      "last 30 days Average": 29,
    };
    if (!selectedSubFrequencyItems.includes("Custom Date")) {
      const currentDate = new Date();
      const timeFactorDate = new Date();

      if (selectedSubFrequencyItems && selectedSubFrequencyItems.length !== 0) {
        currentDate.setDate(currentDate.getDate());
        timeFactorDate.setDate(
          currentDate.getDate() - dateMapping[selectedSubFrequencyItems[0]]
        );

        setEndDate(currentDate.toISOString().split("T")[0]); // ISO format: YYYY-MM-DD
        setStartDate(timeFactorDate.toISOString().split("T")[0]);
      }
    }
  }, [selectedSubFrequencyItems]);

  // useEffect(() => {
  //   if (value == 1) {
  //     if (
  //       selectedDatevalue &&
  //       Object.keys(compCoreAttributes) &&
  //       Object.keys(compCoreAttributes).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;
  //       const filteredObject = Object.keys(compCoreAttributes)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = compCoreAttributes[key];
  //           return result;
  //         }, {});

  //       const avgCoreAttributes =
  //         calculateAverageData(compCoreAttributes)["avg"];

  //       // setSelectedCompCoreAttributes(filteredObject[filterKey]);
  //       setSelectedCompCoreAttributes(avgCoreAttributes);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(compPositiveAttributes) &&
  //       Object.keys(compPositiveAttributes).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;
  //       const filteredObject = Object.keys(compPositiveAttributes)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = compPositiveAttributes[key];
  //           return result;
  //         }, {});
  //       const avgAttributes = calculateAverageData(compPositiveAttributes)[
  //         "avg"
  //       ];
  //       // setSelectedCompPositiveAttributes(filteredObject[filterKey]);
  //       setSelectedCompPositiveAttributes(avgAttributes);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(compNegativeAttributes) &&
  //       Object.keys(compNegativeAttributes).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;
  //       const filteredObject = Object.keys(compNegativeAttributes)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = compNegativeAttributes[key];
  //           return result;
  //         }, {});

  //       // setSelectedCompNegativeAttributes(filteredObject[filterKey]);
  //       const avgAttributes = calculateAverageData(compNegativeAttributes)[
  //         "avg"
  //       ];
  //       // setSelectedCompPositiveAttributes(filteredObject[filterKey]);
  //       setSelectedCompNegativeAttributes(avgAttributes);
  //     }
  //   } else if (value == 2 || value == 3 || value == 4) {
  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmAttributes) &&
  //       Object.keys(llmAttributes).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;
  //       const filteredObject = Object.keys(llmAttributes)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmAttributes[key];
  //           return result;
  //         }, {});

  //       const avgAttributes = calculateAverageData(llmAttributes)["avg"];

  //       // setSelectedAttributes(filteredObject[filterKey]);
  //       setSelectedAttributes(avgAttributes);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmAttributesSources) &&
  //       Object.keys(llmAttributesSources).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;
  //       const filteredObject = Object.keys(llmAttributesSources)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmAttributesSources[key];
  //           return result;
  //         }, {});
  //       const avgAttributes = calculateAverageData(llmAttributesSources)["avg"];
  //       setSelectedAttributesSources(avgAttributes);
  //       // setSelectedAttributesSources(filteredObject[filterKey]);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmNegativeAttributes) &&
  //       Object.keys(llmNegativeAttributes).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;

  //       const filteredNegativeAttributesObject = Object.keys(
  //         llmNegativeAttributes
  //       )
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmNegativeAttributes[key];
  //           return result;
  //         }, {});

  //       const avgAttributes = calculateAverageData(llmNegativeAttributes)[
  //         "avg"
  //       ];
  //       setSelectedNegativeAttributes(avgAttributes);

  //       // setSelectedNegativeAttributes(
  //       //   filteredNegativeAttributesObject[filterKey]
  //       // );
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmNegativeAttributesSources) &&
  //       Object.keys(llmNegativeAttributesSources).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;

  //       const filteredNegativeAttributesObject = Object.keys(
  //         llmNegativeAttributesSources
  //       )
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmNegativeAttributesSources[key];
  //           return result;
  //         }, {});
  //       const avgAttributes = calculateAverageData(
  //         llmNegativeAttributesSources
  //       )["avg"];
  //       setSelectedNegativeAttributesSources(avgAttributes);
  //       // setSelectedNegativeAttributesSources(
  //       //   filteredNegativeAttributesObject[filterKey]
  //       // );
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmCompBrandIndex) &&
  //       Object.keys(llmCompBrandIndex).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;

  //       const filteredBrandIndexObject = Object.keys(llmCompBrandIndex)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmCompBrandIndex[key];
  //           return result;
  //         }, {});

  //       const avgAttributes = calculateAverageData(llmCompBrandIndex)["avg"];
  //       setSelectedLlmCompBrandIndex(avgAttributes);
  //       // setSelectedLlmCompBrandIndex(filteredBrandIndexObject[filterKey]);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmCompBrandFav) &&
  //       Object.keys(llmCompBrandFav).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;

  //       const filteredBrandIndexObject = Object.keys(llmCompBrandFav)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmCompBrandFav[key];
  //           return result;
  //         }, {});
  //       const avgAttributes = calculateAverageData(llmCompBrandFav)["avg"];
  //       setSelectedLlmCompBrandFav(avgAttributes);

  //       // setSelectedLlmCompBrandFav(filteredBrandIndexObject[filterKey]);
  //     }

  //     if (
  //       selectedDatevalue &&
  //       Object.keys(llmCompBrandReach) &&
  //       Object.keys(llmCompBrandReach).length !== 0
  //     ) {
  //       const filterKey = getDayWeekMonthYear(selectedDatevalue)?.day;

  //       const filteredBrandIndexObject = Object.keys(llmCompBrandReach)
  //         .filter((key) => key == filterKey)
  //         .reduce((result, key) => {
  //           result[key] = llmCompBrandReach[key];
  //           return result;
  //         }, {});
  //       const avgAttributes = calculateAverageData(llmCompBrandReach)["avg"];
  //       setSelectedLlmCompBrandReach(avgAttributes);
  //       // setSelectedLlmCompBrandReach(filteredBrandIndexObject[filterKey]);
  //     }
  //   }
  // }, [
  //   selectedDatevalue,
  //   compCoreAttributes,
  //   llmAttributes,
  //   llmCompBrandIndex,
  //   llmCompBrandFav,
  //   llmCompBrandReach,
  //   llmAttributesSources,
  //   llmNegativeAttributesSources,
  //   compPositiveAttributes,
  //   compNegativeAttributes,
  // ]);

  const frequencyOptions = ["Daily", "Weekly", "Monthly", "Yearly"];
  const daysOptions = [
    "Current",
    "last 3 days Average",
    "last 7 days Average",
    // "last 15 days Average",
    // "last 21 days Average",
    "last 30 days Average",
    "Custom Date",
  ];

  const getDayWeekMonthYear = (date) => {
    if (!date) return { day: "", month: "", year: "" };
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Calculate the week number
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const week = getWeekNumber(dateObj);
    return { day, month, year, week };
  };

  const handleStartDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
  };

  const handleEndDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setEndDate(selectedDate);
  };

  const handleCustomDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setCustomDate(selectedDate);
    setEndDate(selectedDate);
    setStartDate(selectedDate);
  };

  const handleCheckChange = (key) => {
    if (selectedLlmItems.includes(key)) {
      setSelectedLlmItems(selectedLlmItems.filter((item) => item !== key));
    } else {
      const llms = value == 2 ? [...selectedLlmItems, key] : [key];
      setSelectedLlmItems(llms);
    }
  };

  const handleFrequencyCheckChange = (key) => {
    if (selectedFrequencyItems.includes(key)) {
      setSelectedFrequencyItems(
        selectedFrequencyItems.filter((item) => item !== key)
      );
    } else {
      setSelectedFrequencyItems([key]);
    }
  };

  const handlesubFrequencyCheckChange = (key) => {
    if (selectedSubFrequencyItems.includes(key)) {
      setSelectedSubFrequencyItems(
        selectedSubFrequencyItems.filter((item) => item !== key)
      );
      setIsCustomDate(false);
    } else {
      if (key === "Custom Date") {
        setIsCustomDate(true);
      } else {
        setIsCustomDate(false);
      }
      setSelectedSubFrequencyItems([key]);
    }
  };

  const handleMarketCheckChange = (key) => {
    if (selectedMarketItems.includes(key)) {
      setSelectedMarketItems(
        selectedMarketItems.filter((item) => item !== key)
      );
    } else {
      if (value === 3) {
        setSelectedMarketItems([...selectedMarketItems, key]);
      } else {
        setSelectedMarketItems([key]);
      }
    }
  };

  const handleBrandCheckChange = (key) => {
    if (selectedBrandItems.includes(key)) {
      setSelectedBrandItems(selectedBrandItems.filter((item) => item !== key));
    } else {
      if (value == 0) {
        setSelectedBrandItems([...selectedBrandItems, key]);
        setSelectedPersona(["General Persona"]);
        const selectedBrand = brands?.data?.data.filter(
          (data) => data?.brand?.name === key
        );
        const persona = selectedBrand[0]?.persona;
        setPersonas(persona);
      } else {
        setSelectedBrandItems([key]);
        setSelectedPersona(["General Persona"]);
        const selectedBrand = brands?.data?.data.filter(
          (data) => data?.brand?.name === key
        );
        const persona = selectedBrand[0]?.persona;
        setPersonas(persona);
      }
    }
  };

  const handlePersonaCheckChange = (key) => {
    if (selectedPersona.includes(key)) {
      setSelectedPersona(selectedPersona.filter((item) => item !== key));
    } else {
      if (value === 4) {
        setSelectedPersona([...selectedPersona, key]);
      } else {
        setSelectedPersona([key]);
      }
    }
  };

  const handleProductCheckChange = (key) => {
    if (selectedProductItems.includes(key)) {
      setSelectedProductItems(
        selectedProductItems.filter((item) => item !== key)
      );
    } else {
      setSelectedProductItems([key]);
      setSelectedPersona([]);
      const selectedProduct = brandProducts?.data?.data.filter(
        (data) => data?.brand_product?.name === key
      );

      const persona = selectedProduct[0]?.customer_brand_product_personas;
      setPersonas(persona);
    }
  };

  const handleDashboardInitialData = async (
    brandId,
    productId,
    llmId,
    personaId,
    brandName,
    productName,
    categoryName
  ) => {
    // await getDashboardDataFromDB(
    //   brandId,
    //   productId,
    //   llmId,
    //   personaId,
    //   true,
    //   brandName,
    //   productName
    // );
    await getCompDashboardDataFromDB(
      brandId,
      productId,
      llmId,
      personaId,
      true,
      brandName,
      productName,
      categoryName
    );
    setIsFirstTime(false);
  };

  const handleClickShow = async () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    const brandId =
      brandItems.filter(
        (item) => item?.brand?.name === selectedBrandItems[0]
      )[0]?.id || null;

    const productId =
      productItems.filter(
        (item) => item?.brand_product?.name === selectedProductItems[0]
      )[0]?.id || null;

    const brandIds = brandItems
      .filter((item) => selectedBrandItems.includes(item?.brand?.name))
      .map((obj) => obj?.id);

    const productIds = productItems
      .filter((item) =>
        selectedProductItems.includes(item?.brand_product?.name)
      )
      .map((obj) => obj?.id);

    const globalLlmId = dbLlms.filter(
      (item) => item?.name === selectedLlmItems[0]
    )[0]?.id;
    const marketId = markets.filter(
      (item) => item?.name === selectedMarketItems[0]
    )[0]?.id;
    const llmId = customerLlm.filter(
      (item) => item?.llm_id == globalLlmId && item?.market_id == marketId
    )[0]?.id;
    const personaId = personas
      .filter((item) => selectedPersona.includes(item?.persona?.name))
      .map((obj) => obj?.persona_id);
    if (value === 0) {
      // await getDashboardDataFromDB(brandId, productId, llmId, personaId, false);
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false,
        undefined,
        undefined,
        undefined,
        brandIds,
        productIds
      );
    } else if (value === 1) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    } else if (value === 2 || value === 3 || value === 4) {
      await getLlmComparisonDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    }
  };

  const calculateAverages = (data) => {
    const result = {
      id: "average",
      llm_id: 1,
      brand_product_id: 2,
      date_added: new Date().toISOString(),
      llm_efr_id: "average",
      sentiment_score: 0,
      brand_index_score: 0,
      brand_favorability_score: 0,
      brand_reach_score: 0,
      competitor_json: {},
    };

    const competitorData = {};

    data.forEach((item) => {
      result.brand_index_score += item.brand_index_score;
      result.brand_favorability_score += item.brand_favorability_score;
      result.brand_reach_score += item.brand_reach_score;

      item.competitor_json.forEach((comp) => {
        if (!competitorData[comp.name]) {
          competitorData[comp.name] = {
            rank: 0,
            total: 0,
            favorability: 0,
            count: 0,
          };
        }
        competitorData[comp.name].rank += comp.rank;
        competitorData[comp.name].total += comp.total;
        competitorData[comp.name].favorability += comp.favorability;
        competitorData[comp.name].count += 1;
      });
    });

    const totalItems = data.length;
    result.brand_index_score /= totalItems;
    result.brand_favorability_score /= totalItems;
    result.brand_reach_score /= totalItems;

    result.competitor_json = Object.keys(competitorData).map((name) => {
      const avgData = competitorData[name];
      const count = data.length;
      return {
        name,
        rank: avgData.rank / count,
        total: avgData.total / count,
        favorability: avgData.favorability / count,
      };
    });

    return result;
  };

  // total count is considered below is total dates considering main subkey as well...

  // function calculateAverageData(data) {
  //   const result = {};
  //   const allAttributes = new Set();

  //   // Collect all unique attributes
  //   Object.keys(data).forEach(date => {
  //     const markets = data[date];
  //     Object.keys(markets).forEach(market => {
  //       Object.keys(markets[market]).forEach(attribute => {
  //         allAttributes.add(attribute);
  //       });
  //     });
  //   });

  //   // Initialize result object with all attributes
  //   Object.keys(data).forEach(date => {
  //     const markets = data[date];
  //     Object.keys(markets).forEach(market => {
  //       if (!result[market]) {
  //         result[market] = {};
  //       }

  //       allAttributes.forEach(attribute => {
  //         if (!result[market][attribute]) {
  //           result[market][attribute] = { sum: 0, count: 0 };
  //         }
  //         const value = parseFloat(markets[market][attribute]) || 0;
  //         result[market][attribute].sum += value;
  //         result[market][attribute].count += 1;
  //       });
  //     });
  //   });

  //   // Calculate averages
  //   const averages = {};
  //   Object.keys(result).forEach(market => {
  //     averages[market] = {};
  //     Object.keys(result[market]).forEach(attribute => {
  //       averages[market][attribute] = result[market][attribute].sum / result[market][attribute].count;
  //     });
  //   });

  //   return { avg: averages };
  // }

  // calculating avg by considering total as occurence of attributes/llm/market etc. which has signal strength/score assigned in the each date and not selected total dates..

  function calculateAverageData(data) {
    const result = {};
    const dates = Object.keys(data);

    // Initialize result object with all markets and attributes
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        if (!result[market]) {
          result[market] = {};
        }
        Object.keys(markets[market]).forEach((attribute) => {
          if (!result[market][attribute]) {
            result[market][attribute] = { sum: 0, count: 0 };
          }
        });
      });
    });

    // Accumulate sums and counts
    dates.forEach((date) => {
      const markets = data[date];
      Object.keys(markets).forEach((market) => {
        Object.keys(markets[market]).forEach((attribute) => {
          const value = parseFloat(markets[market][attribute]) || 0;
          result[market][attribute].sum += value;
          result[market][attribute].count += 1;
        });
      });
    });

    // Calculate averages
    const averages = {};
    Object.keys(result).forEach((market) => {
      averages[market] = {};
      Object.keys(result[market]).forEach((attribute) => {
        const { sum, count } = result[market][attribute];
        averages[market][attribute] = sum / count;
      });
    });

    return { avg: averages };
  }

  // total count is considered below is total dates irrespecifve of main subkey present...

  // function calculateAverageData(data) {
  //   const result = {};
  //   const allAttributes = new Set();
  //   const dates = Object.keys(data);
  //   const numDates = dates.length;

  //   // Collect all unique attributes and all markets
  //   dates.forEach((date) => {
  //     const markets = data[date];
  //     Object.keys(markets).forEach((market) => {
  //       if (!result[market]) {
  //         result[market] = {};
  //       }
  //       Object.keys(markets[market]).forEach((attribute) => {
  //         allAttributes.add(attribute);
  //       });
  //     });
  //   });

  //   // Initialize result object with all attributes for all markets
  //   Object.keys(result).forEach((market) => {
  //     allAttributes.forEach((attribute) => {
  //       if (!result[market][attribute]) {
  //         result[market][attribute] = { sum: 0, count: 0 };
  //       }
  //     });
  //   });

  //   // Accumulate sums and counts
  //   dates.forEach((date) => {
  //     const markets = data[date];
  //     Object.keys(result).forEach((market) => {
  //       allAttributes.forEach((attribute) => {
  //         const value =
  //           markets[market] && markets[market][attribute]
  //             ? parseFloat(markets[market][attribute])
  //             : 0;
  //         result[market][attribute].sum += value;
  //         result[market][attribute].count += 1;
  //       });
  //     });
  //   });

  //   // Calculate averages
  //   const averages = {};
  //   Object.keys(result).forEach((market) => {
  //     averages[market] = {};
  //     Object.keys(result[market]).forEach((attribute) => {
  //       averages[market][attribute] = result[market][attribute].sum / numDates;
  //     });
  //   });

  //   return { avg: averages };
  // }

  const handleRadioSectionShow = (option) => {
    setSelectedOptionShow(option);
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedPersona([]);
    if (option === "Company/Brand") {
      if (brands?.data?.data && brands?.data?.data.length !== 0) {
        const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        // const activeData = brands?.data?.data;
        setBrandItems(activeData);
      }
    }

    if (option === "Product") {
      if (brandProducts?.data?.data && brandProducts?.data?.data.length !== 0) {
        // const activeData = brandProducts?.data?.data.filter(
        //   (obj) => obj.is_active
        // );
        const activeData = brandProducts?.data?.data;
        setProductItems(activeData);
      }
    }
  };

  const getAttributesSignalStrengthData = (result, isInitial) => {
    let positiveData1;
    let positiveData2;
    let negativeData1;
    let negativeData2;
    let positiveDataset1 = {};
    let positiveDataset2 = {};
    let negativeDataset1 = {};
    let negativeDataset2 = {};
    if (result?.date1 && result?.date1.length !== 0) {
      const positiveAttributesData = result?.date1.filter(
        (data) => data?.attribute?.is_positive === 1
      );
      positiveData1 = positiveAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(positiveData1)
        .sort((a, b) => positiveData1[b] - positiveData1[a])
        .forEach((key) => {
          positiveDataset1[key] = positiveData1[key];
        });

      const negativeAttributesData = result?.date1.filter(
        (data) => data?.attribute?.is_positive === 0
      );
      negativeData1 = negativeAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(negativeData1)
        .sort((a, b) => negativeData1[b] - negativeData1[a])
        .forEach((key) => {
          negativeDataset1[key] = negativeData1[key];
        });
    }

    if (result?.date2 && result?.date2.length !== 0) {
      const positiveAttributesData = result?.date2.filter(
        (data) => data?.attribute?.is_positive === 1
      );
      positiveData2 = positiveAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(positiveData2)
        .sort((a, b) => positiveData2[b] - positiveData2[a])
        .forEach((key) => {
          positiveDataset2[key] = positiveData2[key];
        });

      const negativeAttributesData = result?.date2.filter(
        (data) => data?.attribute?.is_positive === 0
      );
      negativeData2 = negativeAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(negativeData2)
        .sort((a, b) => negativeData2[b] - negativeData2[a])
        .forEach((key) => {
          negativeDataset2[key] = negativeData2[key];
        });
    }

    let positiveAttributesToDisplay = [positiveDataset1, positiveDataset2];
    if (isInitial) {
      positiveAttributesToDisplay = [positiveDataset2];
    }
    positiveAttributesToDisplay = positiveAttributesToDisplay.filter(
      (data) => data !== undefined
    );

    let negativeAttributesToDisplay = [negativeDataset1, negativeDataset2];
    if (isInitial) {
      negativeAttributesToDisplay = [negativeDataset2];
    }
    negativeAttributesToDisplay = negativeAttributesToDisplay.filter(
      (data) => data !== undefined
    );
    setPositiveAttributes(positiveAttributesToDisplay);
    setNegativeAttributes(negativeAttributesToDisplay);
  };

  const getIndexScores = (result, isInitial) => {
    let date1ScoresData;
    let date2ScoresData;
    if (
      result?.date1?.main_brand_product_score &&
      result?.date1?.main_brand_product_score.length !== 0
    ) {
      date1ScoresData = result?.date1?.main_brand_product_score[0];
    } else if (
      result?.date1?.main_brand_score &&
      result?.date1?.main_brand_score.length !== 0
    ) {
      date1ScoresData = result?.date1?.main_brand_score[0];
    }

    if (
      result?.date2?.main_brand_product_score &&
      result?.date2?.main_brand_product_score.length !== 0
    ) {
      date2ScoresData = result?.date2?.main_brand_product_score[0];
    } else if (
      result?.date2?.main_brand_score &&
      result?.date2?.main_brand_score.length !== 0
    ) {
      date2ScoresData = result?.date2?.main_brand_score[0];
    }

    if (isInitial) {
      setIndexScores({
        date1: date1ScoresData,
        date2: date2ScoresData,
        isInitial: isInitial,
      });
    } else {
      setIndexScores({ date1: date1ScoresData, date2: date2ScoresData });
    }
  };

  const getCompetitorIndexScores = (result) => {
    if (result?.brandIndexcumulativeData) {
      const brandIndexArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_index_score"
      );
      const brandFavArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_favorability_score"
      );
      const brandReachArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_reach_score"
      );
      const avgIndex = calculateAverageIndex(brandIndexArray);
      const avgFav = calculateAverageIndex(brandFavArray);
      const avgReach = calculateAverageIndex(brandReachArray);
      let finalObject = {
        "Brand Index Score": avgIndex,
        Favorability: avgFav,
        Rank: avgReach,
      };

      if (value == 0) {
        // const brandToKeep = selectedBrandItems[0] || selectedProductItems[0];
        // const newKeyName = selectedSubFrequencyItems.includes("Custom Date")
        //   ? customDate
        //   : selectedSubFrequencyItems[0];

        // const filteredData =
        //   finalObject &&
        //   Object.keys(finalObject).reduce((result, key) => {
        //     result[key] = { [newKeyName]: finalObject[key][brandToKeep] };
        //     return result;
        //   }, {});

        const brandIndexArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_index_score"
        );

        const brandFavArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_favorability_score"
        );
        const brandReachArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_reach_score"
        );
        const avgIndex = calculateAverageIndex(brandIndexArray);
        const avgFav = calculateAverageIndex(brandFavArray);
        const avgReach = calculateAverageIndex(brandReachArray);
        const finalObject = {
          "Brand Index Score": avgIndex,
          Favorability: avgFav,
          Rank: avgReach,
        };

        setBrandIndexScore(finalObject);
        // let brandIndex =
        //   selectedOptionShow === "Product"
        //     ? result?.brandIndexcumulativeData?.product
        //     : result?.brandIndexcumulativeData?.brand;
        // brandIndex = brandIndex.map((obj) => {
        //   return {
        //     "Brand Index Score": obj?.avg_brand_index_score,
        //     "Brand Favorability Score": obj?.avg_brand_favorability_score,
        //     "Brand Reach Score": obj?.avg_brand_reach_score,
        //   };
        // });
        // setIndexScoreDataForChart(brandIndex || []);
      } else {
        setAvgBrandIndex(finalObject);
      }
      setBrandIndexDataSet(brandIndexArray);
      setBrandFavDataSet(brandFavArray);
      setBrandReachDataSet(brandReachArray);
    }
  };

  const getCoreAttributesScoreData = (result, isInitial) => {
    let coreAttributeData1;
    let coreAttributeData2;

    let coreAttributeDataset1 = {};
    let coreAttributeDataset2 = {};

    if (result?.date1 && result?.date1.length !== 0) {
      const coreAttributesData = result?.date1;
      coreAttributeData1 = coreAttributesData.reduce((acc, obj) => {
        acc[obj?.core_attribute?.name] = obj?.avg_score;
        return acc;
      }, {});

      Object.keys(coreAttributeData1)
        .sort((a, b) => coreAttributeData1[b] - coreAttributeData1[a])
        .forEach((key) => {
          coreAttributeDataset1[key] = coreAttributeData1[key];
        });
    }

    if (result?.date2 && result?.date2.length !== 0) {
      const coreAttributesData = result?.date2;

      coreAttributeData2 = coreAttributesData.reduce((acc, obj) => {
        acc[obj?.core_attribute?.name] = obj?.avg_score;
        return acc;
      }, {});

      Object.keys(coreAttributeData2)
        .sort((a, b) => coreAttributeData2[b] - coreAttributeData2[a])
        .forEach((key) => {
          coreAttributeDataset2[key] = coreAttributeData2[key];
        });
    }

    let coreAttributesToDisplay = [
      coreAttributeDataset1,
      coreAttributeDataset2,
    ];
    if (isInitial) {
      coreAttributesToDisplay = [coreAttributeDataset2];
    }
    coreAttributesToDisplay = coreAttributesToDisplay.filter(
      (data) => data !== undefined
    );

    setCoreAttributesData(coreAttributesToDisplay);
  };

  const getDates = (startDate, endDate) => {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const getLlmComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);

    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) && marketIds.includes(item?.market_id)
      )
      .map((obj) => obj?.id);

    let result;
    let result2;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getBrandAttributes(payload);
      result2 = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getProductAttributes(payload);
      result2 = await getCompMetricsForProduct(payload);
    }

    const data = transformDataForAttributes(result?.cumulativeData);

    const sourceData = transformDataForAttributesSources(
      result?.cumulativeSourcesData
    );

    const categoryDimension = result2?.llmCategoryDimensions;

    const categoryDimensionsData =
      processCategoryDimensionArray(categoryDimension);

    const avgRankData =
      selectedOptionShow == "Product"
        ? result2?.compProductAvgRankData
        : result2?.compBrandAvgRankData;
    const averageRankData = processAverageRankArray(avgRankData);

    const cumulativeCompData =
      selectedOptionShow == "Product"
        ? result2?.brandIndexcumulativeData?.compProducts
        : result2?.brandIndexcumulativeData?.compBrands;
    const cumulativebrandOrProductData =
      selectedOptionShow == "Product"
        ? result2?.brandIndexcumulativeData?.product
        : result2?.brandIndexcumulativeData?.brand;
    const brandIndexData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_index_score"
    );

    const brandFavData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_favorability_score"
    );

    const brandReachData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_reach_score"
    );

    setLlmAttributes(
      calculateAverageData(data?.positiveAttributesStructuredData)["avg"]
    );
    setLlmNegativeAttributes(
      calculateAverageData(data?.negativeAttributesStructuredData)["avg"]
    );
    setLlmAttributesSources(
      calculateAverageData(sourceData?.positiveAttributesStructuredData)["avg"]
    );
    setLlmNegativeAttributesSources(
      calculateAverageData(sourceData?.negativeAttributesStructuredData)["avg"]
    );
    setLlmCompBrandIndex(calculateAverageData(brandIndexData)["avg"]);
    setLlmCompBrandFav(calculateAverageData(brandFavData)["avg"]);
    setLlmCompBrandReach(calculateAverageData(brandReachData)["avg"]);

    setSelectedDateValue(startDate);
    setBrandsAvgRankData(averageRankData?.result);
    setAvgRankSourcesData(averageRankData?.sourceResult);

    setCategoryDimensionData(
      calculateAverageData(categoryDimensionsData?.result)["avg"]
    );
    setCategoryDimensionSourceData(
      calculateAverageData(categoryDimensionsData?.sourceResult)["avg"]
    );

    const selectedBrandCategory = brands?.data?.data.filter(
      (data) => data?.brand?.name === selectedBrandItems[0]
    )[0]?.category?.name;

    const selectedBrandProductCategory = brands?.data?.data.filter(
      (data) => data?.brand?.name === selectedProductItems[0]
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0],
      product: selectedProductItems[0],
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
      brandCategory: selectedBrandCategory,
      productCategory: selectedBrandProductCategory,
      llm: selectedLlmItems[0],
    });
  };

  const getCompetitorComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);
    let result;

    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) && marketIds.includes(item?.market_id)
      )
      .map((obj) => obj?.id);

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        brandIds: brandIds,
        llmIds: customerLlmIds.length == 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        personaIds: personaId,
        productIds: productIds,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getCompMetricsForProduct(payload);
    }

    getCompetitorIndexScores(result, isInitial);
    const cumulativeCompData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.compProducts
        : result?.coreAttributescumulativeData?.compBrands;
    const cumulativebrandOrProductData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.product
        : result?.coreAttributescumulativeData?.brand;
    const data = transformDataForCoreAttributes(
      cumulativeCompData,
      cumulativebrandOrProductData
    );

    const cumulativeCompAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.compProducts
        : result?.attributescumulativeData?.compBrands;
    const cumulativebrandOrProductAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.product
        : result?.attributescumulativeData?.brand;

    const attributesData = transformDataForCompAttributes(
      cumulativeCompAttributesData,
      cumulativebrandOrProductAttributesData
    );

    if (value == 0) {
      const cumulativebrandOrProductData =
        selectedOptionShow == "Product"
          ? result?.coreAttributescumulativeData?.product
          : result?.coreAttributescumulativeData?.brand;
      const data = brandCoreAttDataTransform(cumulativebrandOrProductData);

      const cumulativebrandOrProductAttributesData =
        selectedOptionShow == "Product"
          ? result?.attributescumulativeData?.product
          : result?.attributescumulativeData?.brand;

      const attributesData = brandAttDataTransform(
        cumulativebrandOrProductAttributesData
      );

      const filteredData = calculateAverageData(data)["avg"];
      const filteredData2 = calculateAverageData(
        attributesData?.structuredData
      )["avg"];

      const filteredData3 = calculateAverageData(
        attributesData?.negativeAttributesStructuredData
      )["avg"];
      setBrandCoreAttributesScore(filteredData);
      setBrandPositiveAttributesStrength(filteredData2);
      setBrandNegativeAttributesStrength(filteredData3);
    } else {
      setCompCoreAttributes(calculateAverageData(data)["avg"]);

      setCompPositiveAttributes(
        calculateAverageData(attributesData?.mergedData)["avg"]
      );
      setCompNegativeAttributes(
        calculateAverageData(attributesData?.negativeAttributesMergedData)[
          "avg"
        ]
      );
    }

    setSelectedDateValue(startDate);

    const selectedBrandCategory = brands?.data?.data.filter(
      (data) => data?.brand?.name === categoryName
    )[0]?.category?.name;

    const selectedBrandProductCategory = brands?.data?.data.filter(
      (data) => data?.brand?.name === selectedProductItems[0]
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0] || brandName,
      product: selectedProductItems[0] || productName,
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
      brandCategory: selectedBrandCategory,
      productCategory: selectedBrandProductCategory,
      llm: selectedLlmItems[0],
    });
  };

  const getAttributesData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial,
    brandName,
    productName
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);
    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) && marketIds.includes(item?.market_id)
      )
      .map((obj) => obj?.id);
    let result;
    let result2;
    let result3;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };
      result = await getBrandAttributes(payload);
      result2 = await getBrandCoreAttributes(payload);
      result3 = await getIndexScoreForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };
      result = await getProductAttributes(payload);
      result2 = await getProductCoreAttributes(payload);
      result3 = await getIndexScoreForProduct(payload);
    }

    getAttributesSignalStrengthData(result, isInitial);
    getCoreAttributesScoreData(result2, isInitial);
    getIndexScores(result3, isInitial);
    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0] || brandName,
      product: selectedProductItems[0] || productName,
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
    });
  };

  // const groupByDate = (array) => {
  //   const grouped = array.reduce((acc, obj) => {
  //     const date = obj.date_added.split("T")[0]; // Extract the date part
  //     if (!acc[date]) {
  //       acc[date] = [];
  //     }
  //     acc[date].push(obj);
  //     return acc;
  //   }, {});

  //   return Object.values(grouped); // Return the grouped arrays as an array
  // };

  function transformBrandIndexMultiLlmData(compData, data, targetIndex) {
    const result = {};
    const structuredData = {};
    const selectedBrandOrProductName =
      selectedOptionShow === "Product"
        ? selectedProductItems[0] || "Main"
        : selectedBrandItems[0] || "Main";

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, customer_llm, customer_brand_persona } = entry;
      const { llm, market } = customer_llm;
      const { persona } = customer_brand_persona;
      const displayName =
        value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [displayName]: entry[targetIndex],
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow === "Product"
          ? item.brand_product.name
          : item.brand.name;
      const llmName = item?.customer_llm?.llm?.name;
      const marketName = item?.customer_llm?.market?.name;
      const personaName = item?.customer_brand_persona?.persona?.name;
      const displayName =
        value === 3 ? marketName : value === 4 ? personaName : llmName;
      const avgScore = item[targetIndex];

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][displayName] = avgScore;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByMarket(mergedData);
    return mergedData;
  }

  const transformData = (data, index) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const compBrandsData =
      selectedOptionShow == "Product" ? data?.compProducts : data?.compBrands;
    const selectedBrandOrProduct =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    const groupByDay = (arr, keyField, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const key =
          keyField === selectedBrandOrProduct
            ? keyField
            : selectedOptionShow == "Product"
            ? obj.brand_product.name
            : obj.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const compBrandsGrouped = compBrandsData.length
      ? groupByDay(compBrandsData, "name", index)
      : {};
    const brandGrouped = brandData.length
      ? groupByDay(brandData, selectedBrandOrProduct, index)
      : {};

    const allDays = new Set([
      ...Object.keys(compBrandsGrouped),
      ...Object.keys(brandGrouped),
    ]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(compBrandsGrouped[day] || {}),
        ...(brandGrouped[day] || {}),
      };
    });

    return finalArray;
  };

  const brandIndextransformData = (data, index) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const groupByDay = (arr, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const key =
          selectedOptionShow == "Product"
            ? obj?.customer_brand_product_persona?.customer_brand_product
                ?.brand_product?.name
            : obj?.customer_brand_persona?.customer_brand?.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const brandGrouped = brandData.length ? groupByDay(brandData, index) : {};

    const allDays = new Set([...Object.keys(brandGrouped)]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(brandGrouped[day] || {}),
      };
    });
    return finalArray;
  };

  const calculateAverageIndex = (arr) => {
    const totals = {};
    const counts = {};

    // Iterate over each object in the array
    arr.forEach((obj) => {
      for (const key in obj) {
        if (totals[key]) {
          totals[key] += obj[key];
          counts[key] += 1;
        } else {
          totals[key] = obj[key];
          counts[key] = 1;
        }
      }
    });

    // Calculate the averages
    const averages = {};
    for (const key in totals) {
      averages[key] = totals[key] / counts[key];
    }

    return averages;
  };

  const getBrandIndexData = (
    inputData,
    selectedIndex,
    selectedSimilarIndex
  ) => {
    // const mainArray = groupByDate(inputData);
    // let firstChildArray = mainArray.shift();
    // mainArray[0].push(...firstChildArray);

    const brandIndexArray = inputData.map((data, index) => {
      let brandIndexScoreDate1 = data.filter((data) => {
        return data.competitor_json !== null;
      });

      let brandIndexDate1 = calculateAverages(brandIndexScoreDate1);
      const productName = selectedProductItems[0];
      const productIndexValue = brandIndexDate1[selectedIndex];

      let productIndexScore = { [productName]: productIndexValue };
      let productCompetitorsIndexScores = brandIndexDate1["competitor_json"];
      const outputObject = {};

      productCompetitorsIndexScores.forEach((item) => {
        outputObject[item.name] = item[selectedSimilarIndex];
      });

      const finalBrandIndex = { ...productIndexScore, ...outputObject };

      return finalBrandIndex;
    });

    return brandIndexArray;
  };

  // const getBrandIndexScoreData = async (
  //   brandId,
  //   productId,
  //   llmId,
  //   startDate,
  //   endDate,
  //   selectedIndex,
  //   selectedSimilarIndex
  // ) => {
  //   const payloadForBrandIndexScore = {
  //     brandId: brandId,
  //     productId: productId,
  //     llmId: llmId,
  //     startDate: startDate,
  //     endDate: endDate,
  //   };

  //   const brandIndexScoreData = await getBrandIndexScores(
  //     payloadForBrandIndexScore
  //   );

  //   let brandIndexScoreDate1 = brandIndexScoreData.date1.filter((data) => {
  //     return data.competitor_json !== null;
  //   });

  //   let brandIndexDate1 = calculateAverages(brandIndexScoreDate1);

  //   const productName = selectedProductItems[0];
  //   const productIndexValue = brandIndexDate1[selectedIndex];

  //   let productIndexScore = { [productName]: productIndexValue };
  //   let productCompetitorsIndexScores = brandIndexDate1["competitor_json"];
  //   const outputObject = {};

  //   productCompetitorsIndexScores.forEach((item) => {
  //     outputObject[item.name] = item[selectedSimilarIndex];
  //   });

  //   const brandIndexScoreDate2 = brandIndexScoreData.date2.filter((data) => {
  //     return data.competitor_json !== null;
  //   });

  //   let brandIndexDate2 = calculateAverages(brandIndexScoreDate2);

  //   const productName2 = selectedProductItems[0];
  //   const productIndexValue2 = brandIndexDate2[selectedIndex];
  //   let productIndexScore2 = { [productName2]: productIndexValue2 };

  //   let productCompetitorsIndexScores2 = brandIndexDate2["competitor_json"];
  //   const outputObject2 = {};

  //   productCompetitorsIndexScores2.forEach((item) => {
  //     outputObject2[item.name] = item[selectedSimilarIndex];
  //   });

  //   const finalBrandIndex = { ...productIndexScore, ...outputObject };
  //   const finalBrandIndex2 = { ...productIndexScore2, ...outputObject2 };
  //   return { finalBrandIndex, finalBrandIndex2 };
  // };

  const getDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      // const brandId =
      //   brandItems.filter(
      //     (item) => item?.brand?.name === selectedBrandItems[0]
      //   )[0]?.id || null;

      // const productId =
      //   productItems.filter(
      //     (item) => item?.brand_product?.name === selectedProductItems[0]
      //   )[0]?.id || null;
      // const llmId = dbLlms.filter(
      //   (item) => item?.llm?.name === selectedLlmItems[0]
      // )[0]?.llm_id;
      // const personaId = personas.filter(
      //   (item) => item?.persona?.name === selectedPersona[0]
      // )[0]?.persona_id;

      // const payloadForBrandIndexScore = {
      //   brandId: brandId,
      //   productId: productId,
      //   llmId: llmId,
      //   startDate: startDate,
      //   endDate: endDate,
      // };

      // const brandIndexScoreData = await getBrandIndexScores(
      //   payloadForBrandIndexScore
      // );

      // const brandIndex = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_index_score",
      //   "total"
      // );

      // const brandFav = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_favorability_score",
      //   "favorability"
      // );

      // const brandReach = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_reach_score",
      //   "rank"
      // );

      // setBrandIndexDataSet(brandIndex);
      // setBrandFavDataSet(brandFav);
      // setBrandReachDataSet(brandReach);

      await getAttributesData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial,
        brandName,
        productName
      );

      // await getCompetitorComparisonData(
      //   brandId,
      //   productId,
      //   personaId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   isInitial
      // );

      // await getAttributesData();

      // const brandIndex = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_index_score",
      //   "total"
      // );

      // const brandFav = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_favorability_score",
      //   "favorability"
      // );

      // const brandReach = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_reach_score",
      //   "rank"
      // );

      // setBrandIndexSet1(brandIndex.finalBrandIndex);
      // setBrandIndexSet2(brandIndex.finalBrandIndex2);
      // setBrandFavSet1(brandFav.finalBrandIndex);
      // setBrandFavSet2(brandFav.finalBrandIndex2);
      // setBrandReachSet1(brandReach.finalBrandIndex);
      // setBrandReachSet2(brandReach.finalBrandIndex2);

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      // setShowData(true);
      setIsDone(true);
    }
  };

  const transformDataForAttributesSources = (data) => {
    let positiveAttributesStructuredData = {};
    let negativeAttributesStructuredData = {};

    const positiveAttributes = data.filter((obj) => obj.is_positive);
    const negativeAttributes = data.filter((obj) => !obj.is_positive);

    // Process each entry in the data array

    positiveAttributes.forEach((entry) => {
      const {
        day,
        source,
        signal_strength,
        customer_llm,
        customer_brand_persona,
      } = entry;
      const { domain_url } = source;
      const { llm, market } = customer_llm;
      const { persona } = customer_brand_persona;
      const displayName =
        value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;

      if (!positiveAttributesStructuredData[day]) {
        positiveAttributesStructuredData[day] = {};
      }

      positiveAttributesStructuredData[day][displayName] = {
        ...positiveAttributesStructuredData[day][displayName],
        [domain_url]: signal_strength,
      };
    });

    negativeAttributes.forEach((entry) => {
      const {
        day,
        source,
        signal_strength,
        customer_llm,
        customer_brand_persona,
      } = entry;
      const { domain_url } = source;
      const { llm, market } = customer_llm;
      const { persona } = customer_brand_persona;
      const displayName =
        value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;
      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][displayName] = {
        ...negativeAttributesStructuredData[day][displayName],
        [domain_url]: signal_strength,
      };
    });

    positiveAttributesStructuredData = sortDataByTargetKey(
      positiveAttributesStructuredData
    );
    negativeAttributesStructuredData = sortDataByTargetKey(
      negativeAttributesStructuredData
    );

    return {
      positiveAttributesStructuredData,
      negativeAttributesStructuredData,
    };
  };

  function processCategoryDimensionArray(data) {
    let result = {};
    let sourceResult = {};

    const dataArray = data?.llmCategoryDimensions;
    const sourceDataArray = data?.llmCategoryDimensionsSource;

    if (Array.isArray(dataArray)) {
      dataArray.forEach((entry) => {
        const { day, category_dimension, signal_strength, customer_llm } =
          entry;
        const { name } = category_dimension;
        const { llm, market } = customer_llm;
        const { persona } =
          selectedOptionShow == "Product"
            ? entry?.customer_brand_product_persona
            : entry?.customer_brand_persona;
        const displayName =
          value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;

        if (!result[day]) {
          result[day] = {};
        }

        result[day][displayName] = {
          ...result[day][displayName],
          [name]: signal_strength,
        };
      });
    }

    if (Array.isArray(sourceDataArray)) {
      sourceDataArray.forEach((entry) => {
        const {
          day,
          source,
          signal_strength,
          customer_llm,
          customer_brand_persona,
        } = entry;
        const { domain_url } = source;
        const { llm, market } = customer_llm;
        const { persona } = customer_brand_persona;
        const displayName =
          value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;
        if (!sourceResult[day]) {
          sourceResult[day] = {};
        }

        sourceResult[day][displayName] = {
          ...sourceResult[day][displayName],
          [domain_url]: signal_strength,
        };
      });
    }
    result = sortDataByMarket(result);
    sourceResult = sortDataByMarket(sourceResult);

    return { result, sourceResult };
  }

  function processAverageRankArray(dataArray) {
    let result = {};
    let sourceResult = {};

    if (Array.isArray(dataArray)) {
      dataArray.forEach((item) => {
        // Check if necessary properties exist to avoid undefined cases

        const displayName =
          value === 3
            ? item?.customer_llm?.market?.name
            : value === 4
            ? item?.customer_brand_persona?.persona?.name
            : item?.customer_llm?.llm?.name;
        const brands = item?.llm_execution_final_stat?.json_response?.brands;
        const sources = item?.llm_execution_final_stat?.json_response?.sources;

        if (displayName && brands) {
          result[displayName] = brands;
        }

        if (displayName && sources) {
          sourceResult[displayName] = sources;
        }
      });
    }
    result = sortSingleSetDataByMarket(result);
    sourceResult = sortSingleSetDataByMarket(sourceResult);
    return { result, sourceResult };
  }

  function sortSingleSetDataByMarket(data) {
    const sortedData = {};

    const locations = Object.keys(data).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    locations.forEach((location) => {
      sortedData[location] = data[location];
    });

    return sortedData;
  }

  // function sortDataByMarket(data) {
  //   const sortedData = {};

  //   // Sort dates in ascending order
  //   const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));
  //   const firstDate = dates[0];

  //   // Collect all unique markets and sort, placing "General Market" first
  //   const allMarkets = new Set();
  //   Object.values(data[firstDate]).forEach((locations) => {
  //     Object.keys(locations).forEach((market) => {
  //       allMarkets.add(market);
  //     });
  //   });
  //   const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
  //     if (a === "General Market") return -1;
  //     if (b === "General Market") return 1;
  //     return 0;
  //   });

  //   // Sort data according to the first date's sorted markets
  //   dates.forEach((date) => {
  //     sortedData[date] = {};
  //     Object.keys(data[date]).forEach((company) => {
  //       sortedData[date][company] = {};
  //       sortedMarkets.forEach((market) => {
  //         if (data[date][company][market] !== undefined) {
  //           sortedData[date][company][market] = data[date][company][market];
  //         }
  //       });
  //     });
  //   });

  //   return sortedData;
  // }

  function sortDataByMarket(data) {
    const sortedData = {};

    // Sort dates in ascending order
    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.values(data[date]).forEach((locations) => {
        Object.keys(locations).forEach((market) => {
          allMarkets.add(market);
        });
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      Object.keys(data[date]).forEach((company) => {
        sortedData[date][company] = {};
        sortedMarkets.forEach((market) => {
          if (data[date][company][market] !== undefined) {
            sortedData[date][company][market] = data[date][company][market];
          }
        });
      });
    });

    return sortedData;
  }

  function sortDataByTargetKey(data) {
    const sortedData = {};

    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.keys(data[date]).forEach((market) => {
        allMarkets.add(market);
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      sortedMarkets.forEach((market) => {
        if (data[date][market] !== undefined) {
          sortedData[date][market] = data[date][market];
        }
      });
    });

    return sortedData;
  }

  const transformDataForAttributes = (data) => {
    let positiveAttributesStructuredData = {};
    let negativeAttributesStructuredData = {};

    const positiveAttributes = data.filter((obj) => obj.is_positive);
    const negativeAttributes = data.filter((obj) => !obj.is_positive);

    // Process each entry in the data array

    positiveAttributes.forEach((entry) => {
      const {
        day,
        attribute,
        signal_strength,
        customer_llm,
        customer_brand_persona,
      } = entry;
      const { name } = attribute;
      const { llm, market } = customer_llm;
      const { persona } = customer_brand_persona;
      const displayName =
        value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;

      if (!positiveAttributesStructuredData[day]) {
        positiveAttributesStructuredData[day] = {};
      }

      positiveAttributesStructuredData[day][displayName] = {
        ...positiveAttributesStructuredData[day][displayName],
        [name]: signal_strength,
      };
    });

    negativeAttributes.forEach((entry) => {
      const {
        day,
        attribute,
        signal_strength,
        customer_llm,
        customer_brand_persona,
      } = entry;
      const { name } = attribute;
      const { llm, market } = customer_llm;
      const { persona } = customer_brand_persona;
      const displayName =
        value === 3 ? market?.name : value === 4 ? persona?.name : llm?.name;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][displayName] = {
        ...negativeAttributesStructuredData[day][displayName],
        [name]: signal_strength,
      };
    });

    positiveAttributesStructuredData = sortDataByTargetKey(
      positiveAttributesStructuredData
    );
    negativeAttributesStructuredData = sortDataByTargetKey(
      negativeAttributesStructuredData
    );
    return {
      positiveAttributesStructuredData,
      negativeAttributesStructuredData,
    };
  };

  function transformDataForCompAttributes(compData, data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);
    const positiveCompAttributesData = compData.filter(
      (obj) => obj.is_positive
    );
    const negativeAttributesData = data.filter((obj) => !obj.is_positive);
    const negativeCompAttributesData = compData.filter(
      (obj) => !obj.is_positive
    );
    // Initialize the result object
    const result = {};
    const structuredData = {};
    const negativeAttributesResult = {};
    const negativeAttributesStructuredData = {};

    const selectedBrandOrProductName =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    positiveCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item.brand.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      result[day][brandName][attributeName] = signalStrength;
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    negativeCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item.brand.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!negativeAttributesResult[day]) {
        negativeAttributesResult[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!negativeAttributesResult[day][brandName]) {
        negativeAttributesResult[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      negativeAttributesResult[day][brandName][attributeName] = signalStrength;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    let negativeAttributesMergedData = mergeDeep(
      { ...negativeAttributesResult },
      negativeAttributesStructuredData
    );
    mergedData = sortDataByTargetKey(mergedData);
    negativeAttributesMergedData = sortDataByTargetKey(
      negativeAttributesMergedData
    );

    return { mergedData, negativeAttributesMergedData };
  }

  function brandAttDataTransform(data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);

    const negativeAttributesData = data.filter((obj) => !obj.is_positive);

    // Initialize the result object

    const structuredData = {};

    const negativeAttributesStructuredData = {};

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    return { structuredData, negativeAttributesStructuredData };
  }

  function brandCoreAttDataTransform(data) {
    const structuredData = {};

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, core_attribute, avg_score } = entry;
      const { name } = core_attribute;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: avg_score,
      };
    });

    return structuredData;
  }

  function transformDataForCoreAttributes(compData, data) {
    // Initialize the result object
    const result = {};
    const structuredData = {};
    const selectedBrandOrProductName =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, core_attribute, avg_score } = entry;
      const { name } = core_attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: avg_score,
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.brand_product?.name || "test"
          : item.brand.name;
      const coreAttributeName = item.core_attribute.name;
      const avgScore = item.avg_score;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][coreAttributeName] = avgScore;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByTargetKey(mergedData);
    return mergedData;
  }

  const mergeDeep = (target, source) => {
    for (const key in source) {
      if (source[key] instanceof Object && target[key] instanceof Object) {
        // Merge subkeys
        for (const subKey in source[key]) {
          target[key][subKey] = source[key][subKey];
        }
      } else if (source[key] instanceof Object) {
        // Add new nested object
        target[key] = { ...source[key] };
      } else {
        // Assign value from source to target
        target[key] = source[key];
      }
    }
    return target;
  };

  const getLlmComparisonDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName
  ) => {
    setShowData(false);
    setIsDone(false);
    setSelectedAttributes();
    setSelectedNegativeAttributes();

    try {
      await getLlmComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const getCompDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    setShowData(false);
    setIsDone(false);
    setSelectedCompCoreAttributes();

    try {
      await getCompetitorComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial,
        brandName,
        productName,
        categoryName,
        brandIds,
        productIds
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const handleClickReset = () => {
    setSelectedLlmItems([]);
    setShowData(false);
    setIsDone(true);
    setStartDate("");
    setEndDate("");
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedFrequencyItems([]);
    setSelectedPersona([]);
    setSelectedMarketItems([]);
  };

  function calculatePercentageDifference(score1 = 0, score2 = 0) {
    // Calculate the difference between score2 and score1
    const difference = score2 - score1;

    // Calculate the percentage difference based on score1
    const percentageDifference = (difference / score1) * 100;

    const formattedPercentageDifference =
      (percentageDifference >= 0 ? "+" : "") + percentageDifference.toFixed(2);

    // Return the formatted percentage difference
    return formattedPercentageDifference;
  }

  const handleInputValidation = () => {
    let errorMessage = "";
    if (
      selectedOptionShow === "Company/Brand" &&
      selectedBrandItems &&
      selectedBrandItems.length === 0
    ) {
      errorMessage = "Please Select a Brand !";
    } else if (
      selectedOptionShow === "Product" &&
      selectedProductItems &&
      selectedProductItems.length === 0
    ) {
      errorMessage = "Please Select a Product !";
    } else if (selectedPersona && selectedPersona.length == 0) {
      errorMessage = "Please select a Persona !";
    } else if (selectedFrequencyItems && selectedFrequencyItems.length == 0) {
      errorMessage = "Please select Frequency !";
    } else if (value == 0 && (!startDate || !endDate)) {
      errorMessage = "Date is Missing, Please Select!";
    } else if (
      value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length == 0
    ) {
      errorMessage = "Please Select a valid date";
    } else if (
      value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length !== 0 &&
      selectedSubFrequencyItems.includes("Custom Date") &&
      !customDate
    ) {
      errorMessage = "Please Select a valid date";
    } else if (selectedLlmItems && selectedLlmItems.length == 0) {
      errorMessage = "Please select LLM !";
    } else if (selectedMarketItems && selectedMarketItems.length == 0) {
      errorMessage = "Please select Market !";
    }

    return errorMessage;
  };

  // if (!isDone) {
  //   return <p> Please wait, we are preparing inital dashboad !!</p>;
  // }

  return (
    <div className="">
      <Container fluid>
        <Container className="border border-secondary-subtle borderSet mt">
          <h4 className="float-start text1">Dashboard</h4>

          <div className="p-3">
            <Container className="back">
              <Form className="form-inline form-5yquicksearch mx-auto mt-5 p-3">
                <Row className="mb-5">
                  <Form.Group as={Col} md="12">
                    <Row>
                      <ul className="nav brand-tabs">
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Company/Brand"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() =>
                                handleRadioSectionShow("Company/Brand")
                              }
                            >
                              <span></span> Company/Brand
                            </a>
                          </li>
                        </Col>
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Product"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() => handleRadioSectionShow("Product")}
                            >
                              <span> </span> Product
                            </a>
                          </li>
                        </Col>
                        {selectedOptionShow === "Company/Brand" && (
                          <>
                            <Col md="2">
                              <DashboardBrandProductComponent
                                options={brandItems}
                                handleCheckChange={handleBrandCheckChange}
                                selectedBrandProductItems={selectedBrandItems}
                                isBrand={true}
                              />
                            </Col>
                            <Col md="2">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={true}
                              />
                            </Col>
                          </>
                        )}
                        {selectedOptionShow === "Product" && (
                          <>
                            <Col md="2">
                              <DashboardBrandProductComponent
                                options={productItems}
                                handleCheckChange={handleProductCheckChange}
                                selectedBrandProductItems={selectedProductItems}
                              />
                            </Col>
                            <Col md="2">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={false}
                              />
                            </Col>
                          </>
                        )}
                        <Col style={{ marginLeft: "1%" }} md="2">
                          <DashboardFrequencyComponent
                            options={frequencyOptions}
                            handleCheckChange={handleFrequencyCheckChange}
                            selectedFrequencyItems={selectedFrequencyItems}
                          />
                        </Col>
                        &nbsp; &nbsp;
                        <Col style={{ marginLeft: "2%" }} md="2">
                          <MarketComponent
                            options={markets}
                            handleCheckChange={handleMarketCheckChange}
                            selectedMarketItems={selectedMarketItems}
                          />
                        </Col>
                      </ul>
                    </Row>

                    <Row
                      style={{ marginBottom: "-25px" }}
                      // className="justify-content-center"
                    >
                      <ul className="nav brand-tabs">
                        {/* {value !== 0 ? (
                          <> */}
                        <Col
                          style={{ marginLeft: "1%", marginRight: "2%" }}
                          md="2"
                        >
                          <DashboardFrequencyComponent
                            options={daysOptions}
                            handleCheckChange={handlesubFrequencyCheckChange}
                            selectedFrequencyItems={selectedSubFrequencyItems}
                          />
                        </Col>
                        {isCustomDate && (
                          <Col style={{ marginRight: "4%" }} md="2">
                            <Form.Group controlId="exampleForm.DateInput">
                              <Form.Control
                                style={{ height: "51px", width: "120%" }}
                                type="date"
                                value={customDate}
                                onChange={handleCustomDateCheckChange}
                              />
                            </Form.Group>
                          </Col>
                        )}
                        {/* </>
                        ) : (
                          <>
                            <Col style={{ marginRight: "55px" }} md="2">
                              <Form.Group controlId="exampleForm.DateInput">
                                <Form.Control
                                  style={{ height: "51px", width: "120%" }}
                                  type="date"
                                  value={startDate}
                                  onChange={handleStartDateCheckChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col style={{ marginRight: "55px" }} md="2">
                              <Form.Group controlId="exampleForm.DateInput">
                                <Form.Control
                                  style={{ height: "51px", width: "120%" }}
                                  type="date"
                                  value={endDate}
                                  onChange={handleEndDateCheckChange}
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )} */}
                        <Col style={{ marginRight: "6.5%" }} md="2">
                          <DashboardLLMsComponent
                            options={dbLlms}
                            handleCheckChange={handleCheckChange}
                            selectedLlmItems={selectedLlmItems}
                          />
                        </Col>
                        <Col md="2">
                          <Button
                            type="button"
                            name="firstName"
                            placeholder="Your Brand/Product"
                            className="height2 "
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#3dc863",
                              color: "white",
                            }}
                            onClick={handleClickShow}
                            disabled={!isDone}
                          >
                            {!isDone && !isFirstTime ? (
                              <div style={{ fontSize: "19px" }}>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Please Wait...
                              </div>
                            ) : (
                              <div>LAUNCH</div>
                            )}
                          </Button>
                        </Col>
                        &nbsp;
                        <Col md="2">
                          <Button
                            type="button"
                            name="firstName"
                            placeholder="Your Brand/Product"
                            className="height2"
                            style={{
                              width: "-webkit-fill-available",
                              backgroundColor: "#3dc863",
                              color: "white",
                            }}
                            onClick={handleClickReset}
                          >
                            RESET
                          </Button>
                        </Col>
                      </ul>
                    </Row>
                  </Form.Group>
                </Row>
              </Form>
              <Box
              // sx={tabStyle}
              >
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  role="navigation"
                >
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={0}
                      label="Overview Dashboard"
                    />
                  }
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={1}
                      label="Competitor Comparison"
                    />
                  }
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={2}
                      label="LLM Comparison"
                    />
                  }

                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={3}
                      label="Market Comparison"
                    />
                  }

                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={4}
                      label="Persona Comparison"
                    />
                  }
                </Tabs>
              </Box>
            </Container>
            {/*-------------------- Data Display Section --------------------*/}

            {showData && isDone ? (
              value == 0 ? (
                // <Card>
                //   <Card.Body>
                //     {indexScores?.date1 || indexScores?.date2 ? (
                //       <>
                //         <div
                //           style={{
                //             display: "flex",
                //             justifyContent: "space-between",
                //             padding: "0px 15px 0px 15px",
                //           }}
                //         >
                //           <div>
                //             <h5> Index score </h5>
                //             {indexScores?.isInitial ? (
                //               <p>
                //                 Date ({additionalDashboradInfo?.endDate}) &nbsp;
                //                 {Number(
                //                   indexScores?.date2?.avg_brand_index_score
                //                 ).toFixed(2)}
                //               </p>
                //             ) : (
                //               <>
                //                 <p>
                //                   Date1 ({additionalDashboradInfo?.startDate})
                //                   &nbsp;
                //                   {indexScores?.date1?.avg_brand_index_score
                //                     ? Number(
                //                         indexScores?.date1
                //                           ?.avg_brand_index_score
                //                       ).toFixed(2)
                //                     : "Not Available"}
                //                 </p>
                //                 <p>
                //                   Date2 ({additionalDashboradInfo?.endDate})
                //                   &nbsp;
                //                   {indexScores?.date2?.avg_brand_index_score
                //                     ? `${Number(
                //                         indexScores?.date2
                //                           ?.avg_brand_index_score
                //                       ).toFixed(2)}

                //                   ${
                //                     indexScores?.date1?.avg_brand_index_score &&
                //                     indexScores?.date2?.avg_brand_index_score
                //                       ? `(${calculatePercentageDifference(
                //                           indexScores?.date1
                //                             ?.avg_brand_index_score,
                //                           indexScores?.date2
                //                             ?.avg_brand_index_score
                //                         )}%)`
                //                       : ""
                //                   }`
                //                     : "Not Available"}
                //                 </p>
                //               </>
                //             )}
                //           </div>

                //           <div>
                //             <h5> Favorability score </h5>
                //             {indexScores?.isInitial ? (
                //               <p>
                //                 Date ({additionalDashboradInfo?.endDate}) &nbsp;
                //                 {Number(
                //                   indexScores?.date2
                //                     ?.avg_brand_favorability_score
                //                 ).toFixed(2)}
                //               </p>
                //             ) : (
                //               <>
                //                 <p>
                //                   Date1 ({additionalDashboradInfo?.startDate})
                //                   &nbsp;
                //                   {indexScores?.date1
                //                     ?.avg_brand_favorability_score
                //                     ? Number(
                //                         indexScores?.date1
                //                           ?.avg_brand_favorability_score
                //                       ).toFixed(2)
                //                     : "Not Available"}
                //                 </p>
                //                 <p>
                //                   Date2 ({additionalDashboradInfo?.endDate})
                //                   &nbsp;
                //                   {indexScores?.date2
                //                     ?.avg_brand_favorability_score
                //                     ? `${Number(
                //                         indexScores?.date2
                //                           ?.avg_brand_favorability_score
                //                       ).toFixed(2)}

                //                 ${
                //                   indexScores?.date1
                //                     ?.avg_brand_favorability_score &&
                //                   indexScores?.date2
                //                     ?.avg_brand_favorability_score
                //                     ? `(${calculatePercentageDifference(
                //                         indexScores?.date1
                //                           ?.avg_brand_favorability_score,
                //                         indexScores?.date2
                //                           ?.avg_brand_favorability_score
                //                       )}%)`
                //                     : ""
                //                 }`
                //                     : "Not Available"}
                //                 </p>
                //               </>
                //             )}
                //           </div>

                //           <div>
                //             <h5> Reach score </h5>
                //             {indexScores?.isInitial ? (
                //               <p>
                //                 Date ({additionalDashboradInfo?.endDate}) &nbsp;
                //                 {Number(
                //                   indexScores?.date2?.avg_brand_reach_score
                //                 ).toFixed(2)}
                //               </p>
                //             ) : (
                //               <>
                //                 <p>
                //                   Date1 ({additionalDashboradInfo?.startDate})
                //                   &nbsp;
                //                   {indexScores?.date1?.avg_brand_reach_score
                //                     ? Number(
                //                         indexScores?.date1
                //                           ?.avg_brand_reach_score
                //                       ).toFixed(2)
                //                     : "Not Available"}
                //                 </p>
                //                 <p>
                //                   Date2 ({additionalDashboradInfo?.endDate})
                //                   &nbsp;
                //                   {indexScores?.date2?.avg_brand_reach_score
                //                     ? `${Number(
                //                         indexScores?.date2
                //                           ?.avg_brand_reach_score
                //                       ).toFixed(2)}

                //                ${
                //                  indexScores?.date1?.avg_brand_reach_score &&
                //                  indexScores?.date2?.avg_brand_reach_score
                //                    ? `(${calculatePercentageDifference(
                //                        indexScores?.date1
                //                          ?.avg_brand_reach_score,
                //                        indexScores?.date2?.avg_brand_reach_score
                //                      )}%)`
                //                    : ""
                //                }`
                //                     : "Not Available"}
                //                 </p>
                //               </>
                //             )}
                //           </div>
                //         </div>
                //       </>
                //     ) : (
                //       <>
                //         <hr />
                //         <p style={{ textAlign: "center" }}>
                //           No Brand Index Score Data available !
                //         </p>
                //       </>
                //     )}
                //     <hr />
                //     {positiveAttributes &&
                //     positiveAttributes.some(
                //       (obj) => Object.keys(obj).length > 0
                //     ) ? (
                //       <>
                //         <DashboardMultiBarChart
                //           additionalDashboradInfo={additionalDashboradInfo}
                //           focusAttribute={"Positive Attributes"}
                //           dashboardData={positiveAttributes}
                //           selectedFrequency={selectedFrequencyItems[0]}
                //         />
                //       </>
                //     ) : (
                //       <p style={{ textAlign: "center" }}>
                //         No positive attributes Data available !
                //       </p>
                //     )}

                //     <hr />

                //     {negativeAttributes &&
                //     negativeAttributes.some(
                //       (obj) => Object.keys(obj).length > 0
                //     ) ? (
                //       <DashboardMultiBarChart
                //         additionalDashboradInfo={additionalDashboradInfo}
                //         focusAttribute={"Negative Attributes"}
                //         dashboardData={negativeAttributes}
                //         selectedFrequency={selectedFrequencyItems[0]}
                //       />
                //     ) : (
                //       <p style={{ textAlign: "center" }}>
                //         No negative attributes Data available !
                //       </p>
                //     )}

                //     <hr />

                //     {coreAttributesData &&
                //     coreAttributesData.some(
                //       (obj) => Object.keys(obj).length > 0
                //     ) ? (
                //       <DashboardMultiBarChart
                //         additionalDashboradInfo={additionalDashboradInfo}
                //         focusAttribute={"Core Attributes"}
                //         dashboardData={coreAttributesData}
                //         selectedFrequency={selectedFrequencyItems[0]}
                //       />
                //     ) : (
                //       <p style={{ textAlign: "center" }}>
                //         No core attributes Data available !
                //       </p>
                //     )}
                //   </Card.Body>
                // </Card>
                <Card>
                  <Card.Body>
                    {/* {brandIndexDataSet && brandIndexDataSet.length !== 0 ? (
                    <LineChart
                      additionalDashboradInfo={additionalDashboradInfo}
                      brandIndexData={brandIndexDataSet}
                      label="Brand Index Score"
                    />
                  ) : (
                    <>
                      <hr />
                      <p style={{ textAlign: "center" }}>
                        No Brand Index Score Data available !
                      </p>
                    </>
                  )}

                  {brandFavDataSet && brandFavDataSet.length !== 0 ? (
                    <>
                      <hr />
                      <LineChart
                        additionalDashboradInfo={additionalDashboradInfo}
                        brandIndexData={brandFavDataSet}
                        label="Brand favorability Score"
                      />
                    </>
                  ) : (
                    <>
                      <hr />
                      <p style={{ textAlign: "center" }}>
                        No Brand Favorability Score Data available !
                      </p>
                    </>
                  )}

                  {brandReachDataSet && brandReachDataSet.length !== 0 ? (
                    <>
                      <hr />
                      <LineChart
                        additionalDashboradInfo={additionalDashboradInfo}
                        brandIndexData={brandReachDataSet}
                        label="Brand Reach Score"
                      />
                    </>
                  ) : (
                    <>
                      <hr />
                      <p style={{ textAlign: "center" }}>
                        No Brand Reach Score Data available !
                      </p>
                    </>
                  )} */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Index score
                      </p>
                      {/* <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product Category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p> */}
                    </div>

                    <p> </p>
                    <>
                      {brandIndexScore &&
                      Object.values(brandIndexScore) &&
                      Object.values(brandIndexScore).length !== 0 &&
                      Object.values(brandIndexScore)[0] !== undefined ? (
                        <BrandProductAttributesTable
                          selectedAttributes={brandIndexScore}
                          isBrandIndex={true}
                          isOverview={true}
                          brand={
                            additionalDashboradInfo?.brand ||
                            additionalDashboradInfo?.product
                          }
                          metrics="Brand Index Score"
                          type="Brand Overview"
                          llm={additionalDashboradInfo?.llm}
                          categoryName={
                            additionalDashboradInfo?.brandCategory ||
                            additionalDashboradInfo?.productCategory
                          }
                        />
                      ) : (
                        <>
                          <hr />
                          <p style={{ textAlign: "center" }}>
                            No brand index Data available !
                          </p>
                        </>
                      )}

                      {indexScoreDataForChart &&
                        indexScoreDataForChart.length > 1 && (
                          <>
                            <hr />
                            <LineChart
                              additionalDashboradInfo={additionalDashboradInfo}
                              brandIndexData={indexScoreDataForChart}
                              label="Brand Index Score"
                            />
                          </>
                        )}
                    </>

                    <hr />

                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <Box
                    // sx={tabStyle}
                    >
                      <Tabs
                        value={selectedDatevalue}
                        onChange={handleDateTabChange}
                        role="navigation"
                      >
                        {getDates(
                          additionalDashboradInfo?.startDate,
                          additionalDashboradInfo?.endDate
                        ).map((date) => {
                          return (
                            <Tab
                              sx={{ color: "black", fontWeight: "450" }}
                              value={date}
                              label={date}
                            />
                          );
                        })}
                      </Tabs>
                    </Box>
                  </div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Core Attributes Average score
                      </p>
                      {/* <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p> */}
                    </div>

                    <p> </p>
                    {brandCoreAttributesScore &&
                    Object.values(brandCoreAttributesScore) &&
                    Object.values(brandCoreAttributesScore).length !== 0 &&
                    Object.values(brandCoreAttributesScore)[0] !== undefined ? (
                      <BrandProductCoreAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        isOverview={true}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedCompCoreAttributes={brandCoreAttributesScore}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Core Attributes Average score"
                        type="Brand Overview"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Core attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Positive Attributes : Signal Strength
                      </p>
                      {/* <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p> */}
                    </div>

                    <p> </p>
                    {brandPositiveAttributesStrength &&
                    Object.values(brandPositiveAttributesStrength) &&
                    Object.values(brandPositiveAttributesStrength).length !==
                      0 &&
                    Object.values(brandPositiveAttributesStrength)[0] !==
                      undefined ? (
                      <BrandProductAttributesTable
                        isOverview={true}
                        selectedAttributes={brandPositiveAttributesStrength}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type="Brand Overview"
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Negative Attributes : Signal Strength
                      </p>
                      {/* <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p> */}
                    </div>

                    <p> </p>
                    {brandNegativeAttributesStrength &&
                    Object.values(brandNegativeAttributesStrength) &&
                    Object.values(brandNegativeAttributesStrength).length !==
                      0 &&
                    Object.values(brandNegativeAttributesStrength)[0] !==
                      undefined ? (
                      <BrandProductAttributesTable
                        selectedAttributes={brandNegativeAttributesStrength}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        isOverview={true}
                        metrics="Negative Attributes signal strength"
                        type="Brand Overview"
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No negative attributes Data available !
                        </p>
                      </>
                    )}
                  </Card.Body>
                </Card>
              ) : value == 1 ? (
                <Card>
                  <Card.Body>
                    {/* {brandIndexDataSet && brandIndexDataSet.length !== 0 ? (
                      <LineChart
                        additionalDashboradInfo={additionalDashboradInfo}
                        brandIndexData={brandIndexDataSet}
                        label="Brand Index Score"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Index Score Data available !
                        </p>
                      </>
                    )}

                    {brandFavDataSet && brandFavDataSet.length !== 0 ? (
                      <>
                        <hr />
                        <LineChart
                          additionalDashboradInfo={additionalDashboradInfo}
                          brandIndexData={brandFavDataSet}
                          label="Brand favorability Score"
                        />
                      </>
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Favorability Score Data available !
                        </p>
                      </>
                    )}

                    {brandReachDataSet && brandReachDataSet.length !== 0 ? (
                      <>
                        <hr />
                        <LineChart
                          additionalDashboradInfo={additionalDashboradInfo}
                          brandIndexData={brandReachDataSet}
                          label="Brand Reach Score"
                        />
                      </>
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Reach Score Data available !
                        </p>
                      </>
                    )} */}

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Brand Index score
                    </p>

                    <p> </p>
                    {avgBrandIndex &&
                    Object.keys(avgBrandIndex) &&
                    Object.keys(avgBrandIndex).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={avgBrandIndex}
                        isBrandIndex={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Index Score"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No brand index Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                      <Box
                      // sx={tabStyle}
                      >
                        <Tabs
                          value={selectedDatevalue}
                          onChange={handleDateTabChange}
                          role="navigation"
                        >
                          {getDates(
                            additionalDashboradInfo?.startDate,
                            additionalDashboradInfo?.endDate
                          ).map((date) => {
                            return (
                              <Tab
                                sx={{ color: "black", fontWeight: "450" }}
                                value={date}
                                label={date}
                              />
                            );
                          })}
                        </Tabs>
                      </Box>
                    </div> */}

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Core Attributes Average score
                    </p>

                    <p> </p>
                    {compCoreAttributes &&
                    Object.keys(compCoreAttributes) &&
                    Object.keys(compCoreAttributes).length !== 0 ? (
                      <BrandProductCoreAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedCompCoreAttributes={compCoreAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Core Attributes Average score"
                        type="Competitor comparison"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp core attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Positive Attributes : Signal strength
                    </p>

                    <p> </p>
                    {compPositiveAttributes &&
                    Object.keys(compPositiveAttributes) &&
                    Object.keys(compPositiveAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={compPositiveAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Negative Attributes : Signal strength
                    </p>

                    <p> </p>
                    {compNegativeAttributes &&
                    Object.keys(compNegativeAttributes) &&
                    Object.keys(compNegativeAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={compNegativeAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes signal strength"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp negative attributes Data available !
                        </p>
                      </>
                    )}
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    {/* <div style={{ display: "flex", justifyContent: "center" }}>
                      <Box
                      // sx={tabStyle}
                      >
                        <Tabs
                          value={selectedDatevalue}
                          onChange={handleDateTabChange}
                          role="navigation"
                        >
                          {getDates(
                            additionalDashboradInfo?.startDate,
                            additionalDashboradInfo?.endDate
                          ).map((date) => {
                            return (
                              <Tab
                                sx={{ color: "black", fontWeight: "450" }}
                                value={date}
                                label={date}
                              />
                            );
                          })}
                        </Tabs>
                      </Box>
                    </div> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Index Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandIndex &&
                    Object.keys(llmCompBrandIndex) &&
                    Object.keys(llmCompBrandIndex).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandIndex}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Index Score"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Index Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Reach Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandReach &&
                    Object.keys(llmCompBrandReach) &&
                    Object.keys(llmCompBrandReach).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandReach}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Reach Score"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Reach Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Favorability Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandFav &&
                    Object.keys(llmCompBrandFav) &&
                    Object.keys(llmCompBrandFav).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandFav}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Favorability Score"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Favorability Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Positive Attributes
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    <p> </p>
                    {llmAttributes &&
                    Object.keys(llmAttributes) &&
                    Object.keys(llmAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedAttributes={llmAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Positive Attributes Sources
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    <p> </p>
                    {llmAttributesSources &&
                    Object.keys(llmAttributesSources) &&
                    Object.keys(llmAttributesSources).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedAttributes={llmAttributesSources}
                        isSourceData={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes Source signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Sources Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Negative Attributes
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    {llmNegativeAttributes &&
                    Object.keys(llmNegativeAttributes) &&
                    Object.keys(llmNegativeAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedAttributes={llmNegativeAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Negative attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Negative Attributes Sources
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    {llmNegativeAttributesSources &&
                    Object.keys(llmNegativeAttributesSources) &&
                    Object.keys(llmNegativeAttributesSources).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        // categoryName={
                        //   additionalDashboradInfo?.brandCategory ||
                        //   additionalDashboradInfo?.productCategory
                        // }
                        selectedAttributes={llmNegativeAttributesSources}
                        isSourceData={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes Source signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Negative attributes Sources Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Category Dimension : Signal Strength
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {categoryDimensionData &&
                    Object.keys(categoryDimensionData) &&
                    Object.keys(categoryDimensionData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={categoryDimensionData}
                        isCategoryDimension={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Category Dimension signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Category Dimension Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Category Dimension Sources : Signal Strength
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {categoryDimensionSourceData &&
                    Object.keys(categoryDimensionSourceData) &&
                    Object.keys(categoryDimensionSourceData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={categoryDimensionSourceData}
                        isCategoryDimensionSource={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Category Dimensions Source signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Category Dimension source Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Average Rank (%)
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {brandsAvgRankData &&
                    Object.keys(brandsAvgRankData) &&
                    Object.keys(brandsAvgRankData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={brandsAvgRankData}
                        isAverageRank={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Average Rank"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Average Rank Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Average Rank Sources
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {avgRankSourcesData &&
                    Object.keys(avgRankSourcesData) &&
                    Object.keys(avgRankSourcesData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={avgRankSourcesData}
                        isSourceData={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Average Rank Sources signal strength"
                        type={`${
                          value == 2 ? "LLM" : value == 3 ? "Market" : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Average Rank Sources Data available !
                        </p>
                      </>
                    )}
                  </Card.Body>
                </Card>
              )
            ) : isFirstTime ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "22px",
                  alignItems: "center",
                  padding: "20px",
                  marginTop: "30px",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Dashboard is being prepared Please Wait...
              </div>
            ) : null}
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </div>
  );
}
export default Dashboard;
