import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrandProducts } from "../../../redux/actions/brandProductsActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { AuthContext } from "../../../context/auth-context";

const ProductCategoryComponent = () => {
  const dispatch = useDispatch();
  const productCategories = useSelector((state) => state.brandProducts);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id;

  useEffect(() => {
    if (productCategories?.data && productCategories?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }
  }, [dispatch]);

  if (productCategories.loading) {
    return <p>Loading...</p>;
  }

  if (productCategories.error) {
    return <p>Error: {productCategories.error}</p>;
  }
  const productCategoriesData = productCategories.data.data;

  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Brand category</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {productCategoriesData &&
          productCategoriesData.length !== 0 &&
          productCategoriesData.map((data) => {
            return (
              <tr>
                <td>{data?.customer_brand_product_category?.category?.name}</td>
                <td>
                  {data?.customer_brand_product_category?.category?.description}
                </td>
                <td className={classes.actions}>
                  <Button
                    style={{
                      backgroundColor: "green",
                    }}
                    variant="success"
                    // onClick={() => handleUpdate(data.id)}
                  >
                    Update
                  </Button>{" "}
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                    }}
                    variant="danger"
                    // onClick={() => handleDelete(data.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ProductCategoryComponent;
