// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserQueue_container__D7boo {
    background-color: #545454;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    max-width: 400px;
}

.UserQueue_status__p1KR7 {
    padding-left: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Component/UserQueue.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".container {\n    background-color: #545454;\n    padding: 10px 20px;\n    border-radius: 4px;\n    color: white;\n    display: flex;\n    align-items: center;\n    max-width: 400px;\n}\n\n.status {\n    padding-left: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserQueue_container__D7boo`,
	"status": `UserQueue_status__p1KR7`
};
export default ___CSS_LOADER_EXPORT___;
