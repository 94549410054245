import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JSON5 from "json5";
import DashboardDataDisplayComponent from "./DashboardComponent/DashboardDataDisplayComponent";
import DashboardLLMsComponent from "./DashboardComponent/DashboardLLMs";
import "../../App.css";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import DashboardFocusAreaComponent from "./DashboardComponent/DashboardFocusArea";
import DashboardMetricsComponent from "./DashboardComponent/DashboardAttributesComponent";
import { AuthContext } from "../../context/auth-context";
//import DashboardAnalysisTypeComponent from "./DashboardComponent/DashboardAnalysisType";

import { layout } from "../LayoutComponent/LayoutComponent";
import {
  generateDashboardPrompt,
  generateRankingObject,
  generateRankingOrderObjects,
  calculateStrength,
} from "../../utils/helpers";
import {
  fetchDataFromFirebase,
  addDataToFirebase,
} from "../../DatabaseFirebase/firebaseService";
import {
  fetchClaude3API,
  fetchDataFromChatGPTWeb,
  fetchGeminiAPI,
  fetchGpt4API,
  fetchLlama2API,
} from "../../services/apiService";

const LLM_APIS = {
  "GPT-4 (OpenAI/Microsoft)": fetchGpt4API,
  "GPT-4o (OpenAI/Microsoft)": fetchGpt4API,
  "Gemini (Google)": fetchGeminiAPI,
  "Llama2 (Meta)": fetchLlama2API,
  "Claude3 (Anthropic)": fetchClaude3API,
  "GPT-4 Web": fetchDataFromChatGPTWeb,
};

const SOURCES_CONSOLIDATION_PROMPT = `Please follow below steps and provide accurate result as directed..
Step 1 - Identify Variations:
Review the main array containing child arrays of brand/product information source names. Identify any variations in wording/phrasing across 
source names that convey the same meaning. Record these variations.
Step 2 - Assign Unique Names:
From the identified variations, select a single, descriptive name for each. 
Ensure that the chosen name is unique and represents the source name accurately. 
Choose the most commonly understood name. For example, if variations include "Amazon," 
"Amazon.com," and "Amazon website," opt for a representative name like 
"Amazon.com" to replace all instances.
Step 3 - Modify the Array:
For each item/source name in the array, replace any variation with its single, 
unique name determined in Step 2.
Step 4 - Provide Modified Array:
Submit the final modified array, where each source name is represented by its unique name.`;

const ATTRIBUTES_OPTIMIZATION_PROMPT = `Please follow below steps and provide accurate result as directed..
Step 1 - Identify Variations:
Review the main array containing child arrays of brand/product attributes. Identify any variations in wording/phrasing across 
attributes that convey the same meaning. Record these variations.
Step 2 - Assign Unique Names:
From the identified variations, select a single, descriptive name for each. 
Ensure that the chosen name is unique and represents the attribute accurately. 
Choose the most commonly understood name. For example, if variations include "camera is good," 
"excellent camera," and "superior quality camera," opt for a representative name like 
"excellent camera" to replace all instances.
Step 3 - Modify the Array:
For each item/attribute in the array, replace any variation with its single, 
unique name determined in Step 2.
Step 4 - Provide Modified Array:
Submit the final modified array, where each attribute is represented by its unique name.`;

const DATA_OPTIMIZATION_PROMPT = `Please follow below steps and provide accurate result as directed..

Step 1 - Identify Variations:
Examine the array to find any variations in the names of the same brand/product. Note down these variations.
Step 2 - Assign Unique Names:
Decide on a single unique name for each brand/product variation identified. For example, if "Apple" and "Apple.com" both refer to the same brand, choose "Apple" as the unique name.
Step 3 - Modify the Array:
Go through each element in the array and replace any variation of a brand/product with its unique name.
Step 4 - Provide Modified Array:
Once all variations have been replaced, submit the final modified array with unique names for each brand/product.`;

const LLM_COMPARISON_PROMPT = `Please follow below steps and provide accurate result as directed....
You have two objects containing attributes such as source, brand, product names or reviews
 as keys and attribute values as corresponding values....
 Step 1 - compare the keys of the second object with the keys of the
  first object. If a key in the second object conveys the same meaning but
   has different wording or phrasing compared to a key in the first object, 
   you should replace it with the corresponding key from the first object. 
  Step 2 - Provide modified second JSON object with the replaced keys`;

const LLM_RESPONSE = {
  "GPT-4 (OpenAI/Microsoft)": "GPT-4",
  "GPT-4o (OpenAI/Microsoft)": "GPT-4",
  "Gemini (Google)": "Gemini",
  "Llama2 (Meta)": "Llama2",
  "Claude3 (Anthropic)": "Claude3",
  "GPT-4 Web": "GPT-4_Web",
};

const METRIC_LLM_SPECIFIC_COLOR = {
  "Top 5 positive attributes Gemini (Google)": "#3DC5C4",
  "Top 5 positive attributes Llama2 (Meta)": "lightgreen",
  "Top 5 positive attributes Claude3 (Anthropic)": "#A5C5F7",
  "Top 5 positive attributes GPT-4 (OpenAI/Microsoft)": "#5C85FF",
  "Top 5 positive attributes GPT-4o (OpenAI/Microsoft)": "#b5d09c",
  "Top 5 negative attributes Gemini (Google)": "#FF3A7A",
  "Top 5 negative attributes Llama2 (Meta)": "pink",
  "Top 5 negative attributes Claude3 (Anthropic)": "#D67FFF",
  "Top 5 negative attributes GPT-4 (OpenAI/Microsoft)": "#F0B2F0",
  "Top 5 negative attributes GPT-4o (OpenAI/Microsoft)": "#9999ff",
  "Top 5 positive facts Gemini (Google)": "#3DC5C4",
  "Top 5 positive facts Llama2 (Meta)": "lightgreen",
  "Top 5 positive facts Claude3 (Anthropic)": "#A5C5F7",
  "Top 5 positive facts GPT-4 (OpenAI/Microsoft)": "#85A3FF",
  "Top 5 positive facts GPT-4o (OpenAI/Microsoft)": "#9999ff",
  "Top 5 negative facts Gemini (Google)": "#FF3A7A",
  "Top 5 negative facts Llama2 (Meta)": "pink",
  "Top 5 negative facts Claude3 (Anthropic)": "#D67FFF",
  "Top 5 negative facts GPT-4 (OpenAI/Microsoft)": "#FF1919",
  "Top 5 negative facts GPT-4o (OpenAI/Microsoft)": "#b5d09c",
  "Brand performance Gemini (Google)": "#3DC5C4",
  "Brand performance Llama2 (Meta)": "lightgreen",
  "Brand performance Claude3 (Anthropic)": "#A5C5F7",
  "Brand performance GPT-4 (OpenAI/Microsoft)": "#2EB82E",
  "Brand performance GPT-4o (OpenAI/Microsoft)": "#a78eb8",
  "Product performance Gemini (Google)": "#FF3A7A",
  "Product performance Llama2 (Meta)": "pink",
  "Product performance Claude3 (Anthropic)": "#D67FFF",
  "Product performance GPT-4 (OpenAI/Microsoft)": "#85A3FF",
  "Product performance GPT-4o (OpenAI/Microsoft)": "#94b1ff",
  "Buying criteria Llama2 (Meta)": "#3DC5C4",
  "Buying criteria Gemini (Google)": "lightgreen",
  "Buying criteria Claude3 (Anthropic)": "#A5C5F7",
  "Buying criteria GPT-4 (OpenAI/Microsoft)": "#2EB82E",
  "Buying criteria GPT-4o (OpenAI/Microsoft)": "#ae7bb5",
};

const SOURCES_LLM_SPECIFIC_COLOR = {
  "Gemini (Google)": "#4B8DEF",
  "Llama2 (Meta)": "#9EE1E1",
  "Claude3 (Anthropic)": "#FDEDA0",
  "GPT-4 (OpenAI/Microsoft)": "#A5C5F7",
  "GPT-4o (OpenAI/Microsoft)": "#c3add8",
};

const SOURCES_TYPE = {
  "Top 5 positive attributes": "Top 5 positive attributes-Sources",
  "Top 5 negative attributes": "Top 5 negative attributes-Sources",
  "Top 5 positive facts": "Top 5 positive facts-Sources",
  "Top 5 negative facts": "Top 5 negative facts-Sources",
  "Product performance": "Product performance-Sources",
  "Brand performance": "Brand performance-Sources",
  "Buying criteria": "Buying criteria Dimensions-Sources",
};

// const llmAnalysisTypeOptions = ["Single LLM", "Multiple (LLM Comparison)"];

const brandTitles = [
  "Brand Attributes",
  "Category Dimensions",
  "Category Performance",
];

const productTitles = [
  "Product Attributes",
  "Category Dimensions",
  "Category Performance",
];

function Dashboard() {
  const [selectedOptionShow, setSelectedOptionShow] = useState("Company/Brand");
  const [showData, setShowData] = useState(false);
  const [isDone, setIsDone] = useState(true);
  const [isHistoryData, setIsHistoryData] = useState(false);
  const [brandProductInput, setBrandProductInput] = useState("");
  const [promptCountInput, setPromptCountInput] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [focusItems, setFocusItems] = useState([]);
  const [llmAnalysisHistory, setLlmAnalysisHistory] = useState({});
  const [attributeItems, setAttributeItems] = useState([]);
  const [metricItems, setMetricItems] = useState([]);
  const [abortControllers, setAbortControllers] = useState([]);
  const [metricsPrompt, setMetricsPrompt] = useState({});
  const [prompts, setPrompts] = useState([]);
  const [multipleLlmResponses, setMultipleLlmResponses] = useState();
  const [aggregatedResults, setAggregatedResults] = useState();
  const [toggleLLMResponse, setToggleLLMResponse] = useState(false);
  const [llmAnalysisData, setLlmAnalysisData] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [additionalDashboradInfo, setAdditionalDashboradInfo] = useState({});
  const { isCurrentUserAdmin, authUserEmail } = useContext(AuthContext);

  const METRICS_PROMPT = {
    "Top 5 positive attributes": prompts?.positiveAttributesSignal,
    "Top 5 negative attributes": prompts?.negativeAttributesSignal,
    "Top 5 positive facts": prompts?.positiveFactsSignal,
    "Top 5 negative facts": prompts?.negativeFactsSignal,
    "Buying criteria": prompts?.buyingCriteriaSignalStrength,
    "Brand performance": prompts?.averageRankSignalStrength,
    "Product performance": prompts?.averageRankSignalStrength,
  };

  const CONSOLIDATION_PROMPT = {
    "Top 5 positive attributes": ATTRIBUTES_OPTIMIZATION_PROMPT,
    "Top 5 negative attributes": ATTRIBUTES_OPTIMIZATION_PROMPT,
    "Top 5 positive facts": ATTRIBUTES_OPTIMIZATION_PROMPT,
    "Top 5 negative facts": ATTRIBUTES_OPTIMIZATION_PROMPT,
    "Buying criteria": ATTRIBUTES_OPTIMIZATION_PROMPT,
    "Brand performance": DATA_OPTIMIZATION_PROMPT,
    "Product performance": DATA_OPTIMIZATION_PROMPT,
  };

  const SOURCE_METRICS_PROMPT = {
    "Top 5 positive attributes": prompts?.positiveAttributeSourcesSignal,
    "Top 5 negative attributes": prompts?.negativeAttributeSourcesSignal,
    "Top 5 positive facts": prompts?.positiveAttributeSourcesSignal,
    "Top 5 negative facts": prompts?.negativeAttributeSourcesSignal,
    "Product performance": prompts?.averageRankSourcesStrength,
    "Brand performance": prompts?.averageRankSourcesStrength,
    "Buying criteria": prompts?.buyingCriteriaSourcesSignalStrength,
  };

  useEffect(() => {
    // fetchDataFromFirebase((data) => {
    //   if (data !== null) {
    //     const promptsData = Object.values(data);
    //     setPrompts(promptsData[0]);
    //   }
    // }, "DashboardPrompts");
  }, []);

  useEffect(() => {
    if (isDataAvailable) {
      if (llmAnalysisData && llmAnalysisData.length !== 0) {
        try {
          addDataToFirebase("dashboard-data", {
            date: Date.now(),
            email: authUserEmail,
            llmAnalysisData: JSON.stringify(llmAnalysisData),
            brandProductInput: brandProductInput,
            focusItem: focusItems[0],
            selectedLlmItem: selectedLlmItems[0],
            metricItem: metricItems[0],
            multipleLlmResponses: JSON.stringify(multipleLlmResponses),
            promptCount: promptCountInput || 3,
          });
        } catch (error) {
          toast.error(`Error adding data to Firebase: ${error}`, {
            autoClose: 1000,
          });
        }
      }
      // This block will execute regardless of whether addDataToFirebase throws an error
      setAdditionalDashboradInfo({
        brandProductInput: brandProductInput,
        focusItem: focusItems[0],
        selectedLlmItem: selectedLlmItems[0],
        metricItem: metricItems[0],
        multipleLlmResponses: multipleLlmResponses,
        promptCount: promptCountInput || 3,
      });
      setIsDataAvailable(false);
    }
  }, [isDataAvailable]);

  const llmOptions = [
    "GPT-4 (OpenAI/Microsoft)",
    "GPT-4o (OpenAI/Microsoft)",
    "Gemini (Google)",
    "Llama2 (Meta)",
    "Claude3 (Anthropic)",
    // "GPT-4 Web",
    // "Google SGE",
  ];

  const focusAreaOptions =
    selectedOptionShow === "Product" ? productTitles : brandTitles;

  const generalMetrics = ["Signal Strength"];
  const categoryPerformenceMetrics = [
    "Average Rank",
    "Rank Order Distribution",
  ];

  const metricsOption = focusItems.includes("Category Performance")
    ? categoryPerformenceMetrics
    : generalMetrics;

  const subOptionsData = {
    "Brand Attributes": [
      "Top 5 positive attributes",
      "Top 5 negative attributes",
      "Top 5 positive facts",
      "Top 5 negative facts",
    ],
    "Product Attributes": [
      "Top 5 positive attributes",
      "Top 5 negative attributes",
      "Top 5 positive facts",
      "Top 5 negative facts",
    ],
    "Category Dimensions": ["Buying criteria"],
    "Category Performance":
      selectedOptionShow === "Company/Brand"
        ? ["Brand performance"]
        : ["Product performance"],
  };

  const resetAbortControllers = () => {
    try {
      abortControllers.forEach((controller) => controller.abort());
      setAbortControllers([]);
    } catch (err) {
      throw new Error();
    }
  };

  const handleViewHistoryData = (data) => {
    setShowData(false);
    setLlmAnalysisHistory(data);
    setIsHistoryData(true);
  };

  const handleFocusAreaCheckChange = (key) => {
    if (focusItems.includes(key)) {
      setFocusItems(focusItems.filter((item) => item !== key));
      setMetricsPrompt([]);
      setAttributeItems([]);
      setMetricItems([]);
      setSelectedLlmItems([]);
    } else {
      setFocusItems([key]);
      setMetricsPrompt([]);
      setAttributeItems([]);
      setMetricItems([]);
      setSelectedLlmItems([]);
    }
  };

  const handleBrandProductInputChange = (e) => {
    let currentMetricsPrompt = { ...metricsPrompt };
    setBrandProductInput(e.target.value);
    if (
      Object.keys(currentMetricsPrompt) &&
      Object.keys(currentMetricsPrompt).length !== 0
    ) {
      Object.keys(currentMetricsPrompt).forEach((key) => {
        const prompt = generateDashboardPrompt(
          prompts,
          focusItems,
          [key],
          e.target.value
        );
        currentMetricsPrompt[key] = prompt;
      });
      setMetricsPrompt(currentMetricsPrompt);
    }
  };

  const handleMetricsCheckChange = (key) => {
    if (metricItems.includes(key)) {
      setMetricItems(metricItems.filter((item) => item !== key));
      setSelectedLlmItems([]);
    } else {
      setMetricItems([key]);
      setSelectedLlmItems([]);
    }
  };

  const handleAttributesCheckChange = (key) => {
    if (attributeItems.includes(key)) {
      setAttributeItems(attributeItems.filter((item) => item !== key));
      const currentMetricsPrompt = { ...metricsPrompt };
      delete currentMetricsPrompt[key];
      setMetricsPrompt(currentMetricsPrompt);
    } else {
      const prompt = generateDashboardPrompt(
        prompts,
        focusItems,
        [key],
        brandProductInput
      );
      if (focusItems.includes("Category Performance")) {
        setAttributeItems([key]);
        setMetricsPrompt({ [key]: prompt });
      } else {
        setAttributeItems([...attributeItems, key]);
        setMetricsPrompt({ ...metricsPrompt, [key]: prompt });
      }
    }
  };

  const multipleAPIResults = async (llm, prompt) => {
    let apiCountArray = [1, 2, 3];
    if (promptCountInput && promptCountInput !== 0) {
      let tempArray = [];
      for (var i = 1; i <= promptCountInput; i++) {
        tempArray.push(i);
      }
      apiCountArray = tempArray;
    }

    try {
      const promises = apiCountArray.map(async () => {
        let result;
        if (llm === "GPT-4o (OpenAI/Microsoft)") {
          result = await LLM_APIS[llm](
            prompt,
            new AbortController(),
            4000,
            0.1,
            "gpt-4o",
            undefined,
            "Dashboard"
          );
          result = result[LLM_RESPONSE[llm]][0];
          return result;
        }
        if (llm === "Gemini (Google)" || llm === "Llama2 (Meta)") {
          result = await LLM_APIS[llm](
            prompt,
            undefined,
            undefined,
            "Dashboard"
          );
        } else if (llm === "Claude3 (Anthropic)") {
          result = await LLM_APIS[llm](
            prompt,
            undefined,
            undefined,
            undefined,
            "Dashboard"
          );
        } else {
          result = await LLM_APIS[llm](
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Dashboard"
          );
        }
        result = result[LLM_RESPONSE[llm]][0];
        return result;
      });

      // responses from multiple api calls
      const allResults = await Promise.all(promises);

      return allResults;
    } catch (err) {
      console.log(err);
    }
  };

  const generateUniqueDataForAnalysis = async (results, metric) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${METRICS_PROMPT[metric]}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Dashboard"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${CONSOLIDATION_PROMPT[metric]}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Dashboard"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      throw new Error(err);
    }
  };

  const generateUniqueSourcesDataForAnalysis = async (results, metric) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${SOURCE_METRICS_PROMPT[metric]}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Dashboard"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${SOURCES_CONSOLIDATION_PROMPT}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Dashboard"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      throw new Error(err);
    }
  };

  const rankOrderAnalysis = async (llm) => {
    let prompt = Object.values(metricsPrompt)[0];
    let metric = Object.keys(metricsPrompt);
    try {
      const results = await multipleAPIResults(llm, prompt);

      if (results && results.length !== 0) {
        setMultipleLlmResponses((prevData) => {
          return { ...prevData, [`${metric} ${llm}`]: results };
        });
        let result = await generateUniqueDataForAnalysis(results, metric);
        const rankOrderObjects = generateRankingOrderObjects(result);
        const sourcesData = await getmetricSourcesJson(results, metric, llm);

        let topRankerData = {
          color: "#3DC5C4",
          title: `Top`,
          data: rankOrderObjects?.topRanker,
          type: metric,
        };
        let topHalfRankerData = {
          color: "lightgreen",
          title: `Top Half`,
          data: rankOrderObjects?.topHalfRanker,
        };
        let bottomHalfRankerData = {
          color: "#A5C5F7",
          title: `Bottom Half`,
          data: rankOrderObjects?.bottomHalfRanker,
        };
        let absentRankerData = {
          color: "#FF3A7A",
          title: `Absent`,
          data: rankOrderObjects?.absentItems,
        };
        const dataToDisplay = [
          [
            topRankerData,
            topHalfRankerData,
            bottomHalfRankerData,
            absentRankerData,
          ],
          [sourcesData],
        ];

        setLlmAnalysisData((prevData) => {
          return [...prevData, dataToDisplay];
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error(`Error occured!==> ${err}`, {
        autoClose: 800,
      });
      throw err;
    }
  };

  const averageRankAnalysis = async (results, metric) => {
    let result = await generateUniqueDataForAnalysis(results, metric);
    const rankingObj = generateRankingObject(result);
    return rankingObj;
  };

  const signalStrengthAnalysis = async (results, metric) => {
    let result = await generateUniqueDataForAnalysis(results, metric);
    const signalStrengthObj = calculateStrength(result);
    return signalStrengthObj;
  };

  const signalStrengthAnalysisForSources = async (results, metric) => {
    let result = await generateUniqueSourcesDataForAnalysis(results, metric);
    const signalStrengthObj = calculateStrength(result);
    return signalStrengthObj;
  };

  const jsonResultForUniqueAttributes = async (key, data) => {
    let isExistingObj = false;
    if (!sessionStorage.getItem(key)) {
      sessionStorage.setItem(key, JSON.stringify(data?.data));
    } else {
      isExistingObj = true;
    }

    try {
      if (isExistingObj) {
        let prevSet = sessionStorage.getItem(key);
        let newSet = JSON.stringify(data?.data);
        const inputPrompt = `${LLM_COMPARISON_PROMPT}......first object is==> ${prevSet}.....and second object is ==> ${newSet}`;
        let result = await fetchGpt4API(
          inputPrompt,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "Dashboard"
        );
        result = result["GPT-4"][0];
        result = result.match(/\{[\s\S]*\}/);
        result = result ? result[0] : null;
        let jsObject = JSON5.parse(result);
        data.data = jsObject;
        return data;
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const getmetricJson = async (apiResults, metric, llm) => {
    try {
      let jsonData;
      if (!focusItems.includes("Category Performance")) {
        jsonData = await signalStrengthAnalysis(apiResults, metric);
      } else {
        jsonData = await averageRankAnalysis(apiResults, metric);
      }

      let data = {
        color: METRIC_LLM_SPECIFIC_COLOR[`${metric} ${llm}`],
        title: `${llm}`,
        data: jsonData,
        type: metric,
      };
      const modifiedMetricData = await jsonResultForUniqueAttributes(
        metric,
        data
      );
      return modifiedMetricData || data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const getmetricSourcesJson = async (apiResults, metric, llm) => {
    try {
      const jsonData = await signalStrengthAnalysisForSources(
        apiResults,
        metric
      );

      let data = {
        color: SOURCES_LLM_SPECIFIC_COLOR[llm],
        title: `${llm}`,
        data: jsonData,
        type: SOURCES_TYPE[metric],
        category: "Sources",
      };
      const modifiedMetricSourcesData = await jsonResultForUniqueAttributes(
        SOURCES_TYPE[metric],
        data
      );
      return modifiedMetricSourcesData || data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const processAnalysisData = async (llm, prompt, metric) => {
    try {
      const apiResults = await multipleAPIResults(llm, prompt);
      if (apiResults && apiResults.length !== 0) {
        setMultipleLlmResponses((prevData) => {
          return { ...prevData, [`${metric} ${llm}`]: apiResults };
        });
        const metricData = await getmetricJson(apiResults, metric, llm);
        const metricSourcesData = await getmetricSourcesJson(
          apiResults,
          metric,
          llm
        );
        updateState(metricData, metricSourcesData);
      } else {
        throw new Error();
      }
    } catch (err) {
      toast.error(`Error occured!==> ${err}`, {
        autoClose: 800,
      });
      throw err;
    }
  };

  const updateState = (metricDataNew, metricSourcesDataNew) => {
    setLlmAnalysisData((prevData) => {
      // Check if there's any previous data
      if (prevData.length === 0) {
        // If no previous data, create a new dataToDisplay
        return [[[metricDataNew], [metricSourcesDataNew]]];
      } else {
        // Iterate over each entry in prevData to check for 'type' properties
        const newDataToDisplay = [[metricDataNew], [metricSourcesDataNew]];
        let newDataInserted = false;

        const updatedPrevData = prevData.map(
          ([prevMetricData, prevMetricSourcesData]) => {
            // Check if the 'type' properties are the same for metricData and metricSourcesData
            if (
              prevMetricData.length > 0 &&
              prevMetricData[0].type === metricDataNew.type &&
              prevMetricSourcesData.length > 0 &&
              prevMetricSourcesData[0].type === metricSourcesDataNew.type
            ) {
              // If 'type' properties are the same, update existing dataToDisplay
              newDataInserted = true;
              return [
                [...prevMetricData, metricDataNew],
                [...prevMetricSourcesData, metricSourcesDataNew],
              ];
            }
            return [prevMetricData, prevMetricSourcesData];
          }
        );

        // If 'type' properties are different for all previous data entries, add new dataToDisplay
        if (!newDataInserted) {
          updatedPrevData.unshift(newDataToDisplay);
        }

        return updatedPrevData;
      }
    });
  };

  const handleMultipleLlmForRankAnalysis = async () => {
    handleResetCurrentSet();
    try {
      const llmAnalysis = selectedLlmItems.map((llm) => {
        return rankOrderAnalysis(llm);
      });

      await Promise.allSettled(llmAnalysis);
    } catch (error) {
      // Handle errors if needed
      toast.error(`Something went wrong, Please try again !`, {
        autoClose: 800,
      });
      setIsDone(true);
      console.error(error);
      // throw error;
    } finally {
      setIsDataAvailable(true);
      setShowData(true);
      setIsDone(true);
    }
  };

  const handleMultipleLlmAnalysis = async () => {
    handleResetCurrentSet();
    try {
      const llmAnalysis = selectedLlmItems.map((llm) => {
        return handleDashboardLaunch(llm);
      });

      await Promise.allSettled(llmAnalysis);
    } catch (error) {
      // Handle errors if needed
      toast.error(`Something went wrong, Please try again !`, {
        autoClose: 800,
      });
      setIsDone(true);
      console.error(error);
      // throw error;
    } finally {
      sessionStorage.clear();
      setIsDataAvailable(true);
      setShowData(true);
      setIsDone(true);
    }
  };

  const handleDashboardLaunch = async (llm) => {
    try {
      const attributeAnalysis = Object.entries(metricsPrompt).map(
        (attributes) => {
          return processAnalysisData(llm, attributes[1], attributes[0]);
        }
      );
      await Promise.allSettled(attributeAnalysis);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleCheckChange = (key) => {
    if (selectedLlmItems.includes(key)) {
      setSelectedLlmItems(selectedLlmItems.filter((item) => item !== key));
    } else {
      if (metricItems.includes("Rank Order Distribution")) {
        setSelectedLlmItems([key]);
      } else {
        setSelectedLlmItems([...selectedLlmItems, key]);
      }
    }
  };

  const handleRadioSectionShow = (option) => {
    setSelectedOptionShow(option);
    setFocusItems([]);
    setBrandProductInput("");
  };

  const handleClickShow = async () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    if (metricItems.includes("Rank Order Distribution")) {
      handleMultipleLlmForRankAnalysis();
    } else {
      handleMultipleLlmAnalysis();
    }
  };

  const handleResetCurrentSet = () => {
    setIsDataAvailable(false);
    setIsDone(false);
    setLlmAnalysisData([]);
    setShowData(false);
    setAggregatedResults({});
    setMultipleLlmResponses({});
    setToggleLLMResponse(false);
    setIsHistoryData(false);
    setLlmAnalysisHistory({});
  };

  const handleClickReset = () => {
    setIsDataAvailable(false);
    resetAbortControllers();
    setBrandProductInput("");
    setSelectedItems({});
    setIsHistoryData(false);
    setLlmAnalysisHistory({});
    setSelectedLlmItems([]);
    setShowData(false);
    setPromptCountInput("");
    setFocusItems([]);
    setAttributeItems([]);
    setMetricItems([]);
    setLlmAnalysisData([]);
    setIsDone(true);
    setAggregatedResults({});
    setMultipleLlmResponses({});
    setToggleLLMResponse(false);
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (!brandProductInput.trim()) {
      errorMessage = "Please Enter Brand/Product/Category !";
    } else if (focusItems && focusItems.length == 0) {
      errorMessage = "Please select Focus Area !";
    } else if (selectedLlmItems && selectedLlmItems.length == 0) {
      errorMessage = "Please select LLM !";
    } else if (metricItems && metricItems.length == 0) {
      errorMessage = "Please select Metric !";
    }

    return errorMessage;
  };

  return (
    <div className="">
      <Container fluid>
        <Container className="border border-secondary-subtle borderSet mt">
          <h4 className="float-start text1">Analysis Dashboard</h4>

          <div className="p-3">
            <Container className="back">
              <Form className="form-inline form-5yquicksearch mx-auto mt-5 p-3">
                <Row className="mb-5">
                  <Form.Group as={Col} md="12">
                    <Row>
                      <ul className="nav brand-tabs">
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Company/Brand"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() =>
                                handleRadioSectionShow("Company/Brand")
                              }
                            >
                              <span></span> Company/Brand
                            </a>
                          </li>
                        </Col>
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Product"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() => handleRadioSectionShow("Product")}
                            >
                              <span></span> Product
                            </a>
                          </li>
                        </Col>
                        <Col md="5">
                          <Form.Control
                            style={{ padding: "12px 12px" }}
                            as="textarea"
                            rows={1}
                            cols={2}
                            name="firstName"
                            placeholder={
                              selectedOptionShow === "Product"
                                ? "Product/Category (input)"
                                : "Brand/Category (input)"
                            }
                            // className="big"
                            value={brandProductInput}
                            onChange={(e) => handleBrandProductInputChange(e)}
                          />
                        </Col>
                        {isCurrentUserAdmin && (
                          <Col style={{ marginLeft: "15px" }} md="3">
                            <Form.Control
                              style={{ padding: "12px 12px" }}
                              type="number"
                              rows={1}
                              cols={2}
                              placeholder="Enter Prompt count (3 as Default)"
                              // className="big"
                              value={promptCountInput}
                              onChange={(e) =>
                                e.target.value
                                  ? setPromptCountInput(Number(e.target.value))
                                  : setPromptCountInput("")
                              }
                            />
                          </Col>
                        )}
                      </ul>
                    </Row>

                    <Row
                      style={{ marginBottom: "-25px" }}
                      // className="justify-content-center"
                    >
                      <Col style={{ marginRight: "55px" }} md="2">
                        <DashboardFocusAreaComponent
                          selectedItems={selectedItems}
                          options={focusAreaOptions}
                          subOptionsData={subOptionsData}
                          handleMainOptionClick={handleFocusAreaCheckChange}
                          handleSubOptionClick={handleAttributesCheckChange}
                          mainOption={focusItems}
                          subOptions={attributeItems}
                        />
                      </Col>
                      <Col style={{ marginRight: "55px" }} md="2">
                        <DashboardMetricsComponent
                          options={metricsOption}
                          handleCheckChange={handleMetricsCheckChange}
                          metricItems={metricItems}
                        />
                      </Col>
                      <Col style={{ marginRight: "65px" }} md="2">
                        <DashboardLLMsComponent
                          selectedItems={selectedItems}
                          options={llmOptions}
                          handleCheckChange={handleCheckChange}
                          selectedLlmItems={selectedLlmItems}
                        />
                      </Col>
                      <Col md="2">
                        <Button
                          type="button"
                          name="firstName"
                          placeholder="Your Brand/Product"
                          className="height2 "
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#3dc863",
                            color: "white",
                          }}
                          onClick={handleClickShow}
                          disabled={!isDone}
                        >
                          {!isDone ? (
                            <div style={{ fontSize: "19px" }}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Please Wait...
                            </div>
                          ) : (
                            <div>LAUNCH</div>
                          )}
                        </Button>
                      </Col>
                      <Col md="2">
                        <Button
                          type="button"
                          name="firstName"
                          placeholder="Your Brand/Product"
                          className="height2"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#3dc863",
                            color: "white",
                          }}
                          onClick={handleClickReset}
                        >
                          RESET
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Form>
            </Container>
            {/*-------------------- Data Display Section --------------------*/}

            <DashboardDataDisplayComponent
              data={llmAnalysisData || []}
              additionalDashboradInfo={additionalDashboradInfo}
              toggleLLMResponse={toggleLLMResponse}
              setToggleLLMResponse={setToggleLLMResponse}
              brandProductInput={brandProductInput}
              focusArea={focusItems[0]}
              llm={selectedLlmItems[0]}
              metricItem={metricItems[0]}
              showData={showData}
              multipleLlmResponses={multipleLlmResponses}
              aggregatedResults={aggregatedResults}
              handleViewHistoryData={handleViewHistoryData}
              isHistoryData={isHistoryData}
              llmAnalysisHistory={llmAnalysisHistory}
            />
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </div>
  );
}
export default layout(Dashboard, false);
