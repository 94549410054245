// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorPromptPreview_actions__ohVX6 button {
    padding: 5px 12px;
    color: white;
    font-size: 14px;
}

.MonitorPromptPreview_container__Jw6cv {
    background-color: #E8EBF1;
    padding: 30px 40px;
    border-radius: 5px;
}
.MonitorPromptPreview_results__aglQ- {
  background-color: #000000cf;
  color: white;
}
.MonitorPromptPreview_cardHeaders__B8vw9 {
  /* background-color: #f0e0e0cf; */
  color: white;
  border: 0.7px solid #ccc;
}
.MonitorPromptPreview_md__KoIZ1 {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 8px 15px;
}
.MonitorPromptPreview_badge_wrapper__5cV0D {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}

.MonitorPromptPreview_header_badge_wrapper__am5QX {
  padding-top: 10px;
  margin-bottom: 15px;
  position: relative;
}
.MonitorPromptPreview_badge__Bl\\+YX {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -30px;
}

.MonitorPromptPreview_headerbadge__OaHGj {
  background: #4aa35d;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  /* left: -10px; */
  top: -6.5px;
}
`, "",{"version":3,"sources":["webpack://./src/Component/MonitoringComponent/MonitorPromptPreview.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;EACE,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,iCAAiC;EACjC,YAAY;EACZ,wBAAwB;AAC1B;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".actions button {\n    padding: 5px 12px;\n    color: white;\n    font-size: 14px;\n}\n\n.container {\n    background-color: #E8EBF1;\n    padding: 30px 40px;\n    border-radius: 5px;\n}\n.results {\n  background-color: #000000cf;\n  color: white;\n}\n.cardHeaders {\n  /* background-color: #f0e0e0cf; */\n  color: white;\n  border: 0.7px solid #ccc;\n}\n.md {\n  border-radius: 10px;\n  margin-bottom: 20px;\n  padding: 8px 15px;\n}\n.badge_wrapper {\n  padding-top: 30px;\n  margin-bottom: 15px;\n  position: relative;\n}\n\n.header_badge_wrapper {\n  padding-top: 10px;\n  margin-bottom: 15px;\n  position: relative;\n}\n.badge {\n  background: #37ad52;\n  color: white;\n  padding: 5px;\n  border-radius: 2px;\n  position: relative;\n  left: -30px;\n}\n\n.headerbadge {\n  background: #4aa35d;\n  color: white;\n  padding: 5px;\n  border-radius: 2px;\n  position: relative;\n  /* left: -10px; */\n  top: -6.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `MonitorPromptPreview_actions__ohVX6`,
	"container": `MonitorPromptPreview_container__Jw6cv`,
	"results": `MonitorPromptPreview_results__aglQ-`,
	"cardHeaders": `MonitorPromptPreview_cardHeaders__B8vw9`,
	"md": `MonitorPromptPreview_md__KoIZ1`,
	"badge_wrapper": `MonitorPromptPreview_badge_wrapper__5cV0D`,
	"header_badge_wrapper": `MonitorPromptPreview_header_badge_wrapper__am5QX`,
	"badge": `MonitorPromptPreview_badge__Bl+YX`,
	"headerbadge": `MonitorPromptPreview_headerbadge__OaHGj`
};
export default ___CSS_LOADER_EXPORT___;
