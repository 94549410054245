// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportPrompt_form__Ai2i1 {
    width: 100%;
    background: #E8EBF1;
    border-radius: 6px;
    padding: 20px 24px;
}
.ReportPrompt_actions__hBifs {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
}

.ReportPrompt_actions__hBifs button {
    color: white;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #37ad52;
}

.ReportPrompt_actions__hBifs button:hover {
    color: white;
    background-color: #249e41;
}`, "",{"version":3,"sources":["webpack://./src/Component/BasicCardComponent/Reporting/ReportPrompt.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".form {\n    width: 100%;\n    background: #E8EBF1;\n    border-radius: 6px;\n    padding: 20px 24px;\n}\n.actions {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    justify-content: flex-end;\n}\n\n.actions button {\n    color: white;\n    font-size: 14px;\n    padding: 10px 12px;\n    background-color: #37ad52;\n}\n\n.actions button:hover {\n    color: white;\n    background-color: #249e41;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ReportPrompt_form__Ai2i1`,
	"actions": `ReportPrompt_actions__hBifs`
};
export default ___CSS_LOADER_EXPORT___;
