import {
  SET_USERS,
  FETCH_USER_STARTED,
  FETCH_USER_FAILED,
  DELETE_USER_STARTED,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  ADD_USER_STARTED,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  users: [],
  isLoading: false,
  error: null,
  isDeleting: false,
  isUpdating: false,
  deleteUserSuccess: false,
  updateUserSuccess: false,
  deleteUserError: null,
  updateUserError: null,
  isAddingUser: false,
  addUserError: null,
  adduserSuccess: false,
};

export default function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
        error: null,
      };
    case FETCH_USER_STARTED: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case FETCH_USER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case DELETE_USER_STARTED: {
      return {
        ...state,
        isDeleting: true,
        deleteUserError: null,
        deleteUserSuccess: false,
      };
    }
    case DELETE_USER_FAILED: {
      return {
        ...state,
        isDeleting: false,
        deleteUserError: action.payload,
        deleteUserSuccess: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        isDeleting: false,
        deleteUserError: null,
        deleteUserSuccess: true,
      };
    }
    case UPDATE_USER_STARTED: {
      return {
        ...state,
        isUpdating: true,
        updateUserError: null,
        updateUserSuccess: false,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        updateUserError: null,
        updateUserSuccess: true,
      };
    }
    case UPDATE_USER_FAILED: {
      return {
        ...state,
        isUpdating: false,
        updateUserError: action.payload,
        updateUserSuccess: false,
      };
    }
    case ADD_USER_STARTED: {
      return {
        ...state,
        isAddingUser: true,
        addUserError: null,
        adduserSuccess: false,
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        isAddingUser: false,
        addUserError: null,
        adduserSuccess: true,
      };
    }
    case ADD_USER_FAILED: {
      return {
        ...state,
        isAddingUser: false,
        addUserError: action.payload,
        adduserSuccess: false,
      };
    }

    default:
      return state;
  }
}
