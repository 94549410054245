import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

const BarChart = ({ outputData, metricItem }) => {
  // Extracting unique labels from all datasets
  const allLabels = outputData.reduce((acc, dataset) => {
    const datasetLabels = Object.keys(dataset.data || {});
    return [...acc, ...datasetLabels];
  }, []);

  const uniqueLabels = [...new Set(allLabels)];

  // Data for the bar chart
  const data = {
    labels: uniqueLabels,
    datasets: outputData.map((dataset, index) => {
      return {
        label: dataset.title,
        backgroundColor: dataset.color,
        // borderWidth: 1,
        barThickness: 28,
        data: uniqueLabels.map((label) => dataset?.data?.[label] || null),
      };
    }),
  };

  const options = {
    indexAxis: "y", // Renders the bars horizontally
    skipNull: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            weight: "bold", // Make legend text bold
          },
        },
        borderWidth: 2,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        min: 0,
        max: 100, // Set max value based on type
        ticks: {
          stepSize: 10, // Adjust step size based on type
          font: {
            weight: "bold",
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
      y: {
        ticks: {
          font: {
            weight: "bold", // Making all labels bold
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
    },
  };

  let scaleFactor;
  switch (outputData.length) {
    case 1:
      scaleFactor = 1.4;
      break;
    case 2:
      scaleFactor = 1.7;
      break;
    case 3:
      scaleFactor = 2.4;
      break;
    default:
      scaleFactor = 2.6;
  }

  return (
    <>
      <p style={{ padding: "5px", fontWeight: "600" }}>
        {outputData[0]?.category
          ? `Signal Strength: ${outputData[0]?.category}`
          : `${metricItem}: ${outputData[0]?.type}`}
      </p>
      <div>
        <Bar
          key={uniqueLabels}
          data={data}
          options={options}
          height={data.labels.length * scaleFactor}
          width={15}
        />
      </div>
    </>
  );
};

export default BarChart;
