import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import { fetchBrands } from "../../redux/actions/brandsActions";
import "react-toastify/dist/ReactToastify.css";
import DashboardLLMsComponent from "./DashboardComponent/DashboardLLMs";
import "../../App.css";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import DashboardBrandProductComponent from "./DashboardComponent/DashboardBrandProductComponent";
import DashboardBrandProductPersonaComponent from "./DashboardComponent/DashboardBrandProductPersonaComponent";
import DashboardFrequencyComponent from "./DashboardComponent/DashboardFrequencyComponent";
import { DashboardMultiBarChart } from "./common/Barchart";
import LineChart from "./common/Linechart";
import { layout } from "../LayoutComponent/LayoutComponent";
import {
  fetchBrandNames,
  fetchProductNames,
  // fetchLlms,
  getAttributes,
  getBrandIndexScores,
  fetchCustomerLlms,
  getProductAttributes,
  getBrandAttributes,
  getBrandCoreAttributes,
  getProductCoreAttributes,
  getIndexScoreForBrand,
  getIndexScoreForProduct,
} from "../../services/apiService";
import { AuthContext } from "../../context/auth-context";
import useQuery from "./common/useQuery";

function Dashboard2() {
  const [selectedOptionShow, setSelectedOptionShow] = useState();
  const [showData, setShowData] = useState(false);
  const [positiveAttributes, setPositiveAttributes] = useState([]);
  const [negativeAttributes, setNegativeAttributes] = useState([]);
  const [isDone, setIsDone] = useState(true);
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedFrequencyItems, setSelectedFrequencyItems] = useState([
    "Daily",
  ]);
  const [brandItems, setBrandItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [selectedBrandItems, setSelectedBrandItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [additionalDashboradInfo, setAdditionalDashboradInfo] = useState({});
  const [brandIndexDataSet, setBrandIndexDataSet] = useState([]);
  const [brandFavDataSet, setBrandFavDataSet] = useState([]);
  const [brandReachDataSet, setBrandReachDataSet] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);
  const [coreAttributesData, setCoreAttributesData] = useState([]);
  const [indexScores, setIndexScores] = useState();
  const [isFirstTime, setIsFirstTime] = useState(true);

  const query = useQuery();
  const selectedBrandId = query.get("brandId") || 1;
  const productId = query.get("brandProductId");

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const brands = useSelector((state) => state.brands);

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id;

  useEffect(() => {
    if (selectedOptionShow === "Product") {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0
      ) {
        const activeData = brandProducts?.data?.data.filter(
          (obj) => obj.is_active
        );

        const selectedData = brandProducts?.data?.data.filter(
          (obj) => obj.id == productId
        );

        setSelectedProductItems([selectedData[0]?.brand_product?.name]);
        setSelectedPersona([
          selectedData[0]?.customer_brand_product_personas[0]?.persona?.name,
        ]);
        setSelectedLlmItems(["gpt-4o"]);

        setProductItems(activeData);

        setPersonas(selectedData[0]?.customer_brand_product_personas);
        const brandProductId = selectedData[0]?.id || null;

        const llmId = dbLlms.filter((item) => item?.llm?.name === "gpt-4o")[0]
          ?.llm_id;
        const personaId =
          selectedData[0]?.customer_brand_product_personas[0]?.persona?.id;
        const productName = selectedData[0]?.brand_product?.name;
        handleDashboardInitialData(
          undefined,
          brandProductId,
          llmId,
          personaId,
          undefined,
          productName
        );
      }
    }
  }, [brandProducts, dbLlms]);

  useEffect(() => {
    if (selectedOptionShow === "Company/Brand") {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0
      ) {
        const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        const selectedData = brands?.data?.data.filter(
          (obj) => obj.id == selectedBrandId
        );
        setSelectedBrandItems([selectedData[0]?.brand?.name]);
        setSelectedPersona([selectedData[0]?.persona[0]?.persona?.name]);
        setSelectedLlmItems(["gpt-4o"]);
        // setProductItems(brandProducts?.data?.data);
        setBrandItems(activeData);

        setPersonas(selectedData[0]?.persona);
        const brandId = selectedData[0]?.id || null;

        const llmId = dbLlms.filter((item) => item?.llm?.name === "gpt-4o")[0]
          ?.llm_id;
        const personaId = selectedData[0]?.persona[0]?.persona?.id;
        const brandName = selectedData[0]?.brand?.name;
        handleDashboardInitialData(
          brandId,
          undefined,
          llmId,
          personaId,
          brandName,
          undefined
        );
      }
    }
  }, [brands, dbLlms]);

  useEffect(() => {
    const currentDate = new Date();
    const oneWeekBefore = new Date();
    const oneDayBefore = new Date();
    oneWeekBefore.setDate(currentDate.getDate() - 7);
    oneDayBefore.setDate(currentDate.getDate() - 1);
    currentDate.setDate(currentDate.getDate());

    setEndDate(currentDate.toISOString().split("T")[0]); // ISO format: YYYY-MM-DD
    setStartDate(oneWeekBefore.toISOString().split("T")[0]);

    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }

    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }

    async function fetchData() {
      const llms = await fetchCustomerLlms(customerId);
      setDbLlms(llms?.data);
    }

    if (query.get("brandId") || query.get("brandProductId")) {
      if (query.get("brandId")) {
        setSelectedOptionShow("Company/Brand");
      } else if (query.get("brandProductId")) {
        setSelectedOptionShow("Product");
      }
    } else {
      setSelectedOptionShow("Company/Brand");
    }

    fetchData();
  }, []);

  const frequencyOptions = ["Daily", "Weekly", "Monthly", "Yearly"];

  const getDayWeekMonthYear = (date) => {
    if (!date) return { day: "", month: "", year: "" };
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Calculate the week number
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const week = getWeekNumber(dateObj);
    return { day, month, year, week };
  };

  const handleStartDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
  };

  const handleEndDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setEndDate(selectedDate);
  };

  const handleCheckChange = (key) => {
    if (selectedLlmItems.includes(key)) {
      setSelectedLlmItems(selectedLlmItems.filter((item) => item !== key));
    } else {
      setSelectedLlmItems([key]);
    }
  };

  const handleFrequencyCheckChange = (key) => {
    if (selectedLlmItems.includes(key)) {
      setSelectedFrequencyItems(
        selectedFrequencyItems.filter((item) => item !== key)
      );
    } else {
      setSelectedFrequencyItems([key]);
    }
  };

  const handleBrandCheckChange = (key) => {
    if (selectedBrandItems.includes(key)) {
      setSelectedBrandItems(selectedBrandItems.filter((item) => item !== key));
    } else {
      setSelectedBrandItems([key]);
      setSelectedPersona([]);
      const selectedBrand = brands?.data?.data.filter(
        (data) => data?.brand?.name === key
      );
      const persona = selectedBrand[0]?.persona;
      setPersonas(persona);
    }
  };

  const handlePersonaCheckChange = (key) => {
    if (selectedPersona.includes(key)) {
      setSelectedPersona(selectedPersona.filter((item) => item !== key));
    } else {
      setSelectedPersona([key]);
    }
  };

  const handleProductCheckChange = (key) => {
    if (selectedProductItems.includes(key)) {
      setSelectedProductItems(
        selectedProductItems.filter((item) => item !== key)
      );
    } else {
      setSelectedProductItems([key]);
      setSelectedPersona([]);
      const selectedProduct = brandProducts?.data?.data.filter(
        (data) => data?.brand_product?.name === key
      );

      const persona = selectedProduct[0]?.customer_brand_product_personas;
      setPersonas(persona);
    }
  };

  const handleDashboardInitialData = async (
    brandId,
    productId,
    llmId,
    personaId,
    brandName,
    productName
  ) => {
    await getDashboardDataFromDB(
      brandId,
      productId,
      llmId,
      personaId,
      true,
      brandName,
      productName
    );
  };

  const handleClickShow = async () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    const brandId =
      brandItems.filter(
        (item) => item?.brand?.name === selectedBrandItems[0]
      )[0]?.id || null;

    const productId =
      productItems.filter(
        (item) => item?.brand_product?.name === selectedProductItems[0]
      )[0]?.id || null;
    const llmId = dbLlms.filter(
      (item) => item?.llm?.name === selectedLlmItems[0]
    )[0]?.llm_id;
    const personaId = personas.filter(
      (item) => item?.persona?.name === selectedPersona[0]
    )[0]?.persona_id;

    await getDashboardDataFromDB(brandId, productId, llmId, personaId, false);
  };

  const calculateAverages = (data) => {
    const result = {
      id: "average",
      llm_id: 1,
      brand_product_id: 2,
      date_added: new Date().toISOString(),
      llm_efr_id: "average",
      sentiment_score: 0,
      brand_index_score: 0,
      brand_favorability_score: 0,
      brand_reach_score: 0,
      competitor_json: {},
    };

    const competitorData = {};

    data.forEach((item) => {
      result.brand_index_score += item.brand_index_score;
      result.brand_favorability_score += item.brand_favorability_score;
      result.brand_reach_score += item.brand_reach_score;

      item.competitor_json.forEach((comp) => {
        if (!competitorData[comp.name]) {
          competitorData[comp.name] = {
            rank: 0,
            total: 0,
            favorability: 0,
            count: 0,
          };
        }
        competitorData[comp.name].rank += comp.rank;
        competitorData[comp.name].total += comp.total;
        competitorData[comp.name].favorability += comp.favorability;
        competitorData[comp.name].count += 1;
      });
    });

    const totalItems = data.length;
    result.brand_index_score /= totalItems;
    result.brand_favorability_score /= totalItems;
    result.brand_reach_score /= totalItems;

    result.competitor_json = Object.keys(competitorData).map((name) => {
      const avgData = competitorData[name];
      const count = data.length;
      return {
        name,
        rank: avgData.rank / count,
        total: avgData.total / count,
        favorability: avgData.favorability / count,
      };
    });

    return result;
  };

  const handleRadioSectionShow = (option) => {
    setSelectedOptionShow(option);
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedPersona([]);
    if (option === "Company/Brand") {
      if (brands?.data?.data && brands?.data?.data.length !== 0) {
        const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        setBrandItems(activeData);
      }
    }

    if (option === "Product") {
      if (brandProducts?.data?.data && brandProducts?.data?.data.length !== 0) {
        const activeData = brandProducts?.data?.data.filter(
          (obj) => obj.is_active
        );
        setProductItems(activeData);
      }
    }
  };

  const getAttributesSignalStrengthData = (result, isInitial) => {
    let positiveData1;
    let positiveData2;
    let negativeData1;
    let negativeData2;
    let positiveDataset1 = {};
    let positiveDataset2 = {};
    let negativeDataset1 = {};
    let negativeDataset2 = {};
    if (result?.date1 && result?.date1.length !== 0) {
      const positiveAttributesData = result?.date1.filter(
        (data) => data?.attribute?.is_positive === 1
      );
      positiveData1 = positiveAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(positiveData1)
        .sort((a, b) => positiveData1[b] - positiveData1[a])
        .forEach((key) => {
          positiveDataset1[key] = positiveData1[key];
        });

      const negativeAttributesData = result?.date1.filter(
        (data) => data?.attribute?.is_positive === 0
      );
      negativeData1 = negativeAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(negativeData1)
        .sort((a, b) => negativeData1[b] - negativeData1[a])
        .forEach((key) => {
          negativeDataset1[key] = negativeData1[key];
        });
    }

    if (result?.date2 && result?.date2.length !== 0) {
      const positiveAttributesData = result?.date2.filter(
        (data) => data?.attribute?.is_positive === 1
      );
      positiveData2 = positiveAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(positiveData2)
        .sort((a, b) => positiveData2[b] - positiveData2[a])
        .forEach((key) => {
          positiveDataset2[key] = positiveData2[key];
        });

      const negativeAttributesData = result?.date2.filter(
        (data) => data?.attribute?.is_positive === 0
      );
      negativeData2 = negativeAttributesData.reduce((acc, obj) => {
        acc[obj?.attribute?.name] = obj.signal_strength;
        return acc;
      }, {});

      Object.keys(negativeData2)
        .sort((a, b) => negativeData2[b] - negativeData2[a])
        .forEach((key) => {
          negativeDataset2[key] = negativeData2[key];
        });
    }

    let positiveAttributesToDisplay = [positiveDataset1, positiveDataset2];
    if (isInitial) {
      positiveAttributesToDisplay = [positiveDataset2];
    }
    positiveAttributesToDisplay = positiveAttributesToDisplay.filter(
      (data) => data !== undefined
    );

    let negativeAttributesToDisplay = [negativeDataset1, negativeDataset2];
    if (isInitial) {
      negativeAttributesToDisplay = [negativeDataset2];
    }
    negativeAttributesToDisplay = negativeAttributesToDisplay.filter(
      (data) => data !== undefined
    );
    setPositiveAttributes(positiveAttributesToDisplay);
    setNegativeAttributes(negativeAttributesToDisplay);
  };

  const getIndexScores = (result, isInitial) => {
    let date1ScoresData;
    let date2ScoresData;
    if (
      result?.date1?.main_brand_product_score &&
      result?.date1?.main_brand_product_score.length !== 0
    ) {
      date1ScoresData = result?.date1?.main_brand_product_score[0];
    } else if (
      result?.date1?.main_brand_score &&
      result?.date1?.main_brand_score.length !== 0
    ) {
      date1ScoresData = result?.date1?.main_brand_score[0];
    }

    if (
      result?.date2?.main_brand_product_score &&
      result?.date2?.main_brand_product_score.length !== 0
    ) {
      date2ScoresData = result?.date2?.main_brand_product_score[0];
    } else if (
      result?.date2?.main_brand_score &&
      result?.date2?.main_brand_score.length !== 0
    ) {
      date2ScoresData = result?.date2?.main_brand_score[0];
    }

    if (isInitial) {
      setIndexScores({
        date1: date1ScoresData,
        date2: date2ScoresData,
        isInitial: isInitial,
      });
    } else {
      setIndexScores({ date1: date1ScoresData, date2: date2ScoresData });
    }
  };

  const getCompetitorIndexScores = (result) => {
    if (result?.cumulativeData) {
      const brandIndexArray = transformData(
        result?.cumulativeData,
        "avg_brand_index_score"
      );
      const brandFavArray = transformData(
        result?.cumulativeData,
        "avg_brand_favorability_score"
      );
      const brandReachArray = transformData(
        result?.cumulativeData,
        "avg_brand_reach_score"
      );
      setBrandIndexDataSet(brandIndexArray);
      setBrandFavDataSet(brandFavArray);
      setBrandReachDataSet(brandReachArray);
    }
  };

  const getCoreAttributesScoreData = (result, isInitial) => {
    let coreAttributeData1;
    let coreAttributeData2;

    let coreAttributeDataset1 = {};
    let coreAttributeDataset2 = {};

    if (result?.date1 && result?.date1.length !== 0) {
      const coreAttributesData = result?.date1;
      coreAttributeData1 = coreAttributesData.reduce((acc, obj) => {
        acc[obj?.core_attribute?.name] = obj?.avg_score;
        return acc;
      }, {});

      Object.keys(coreAttributeData1)
        .sort((a, b) => coreAttributeData1[b] - coreAttributeData1[a])
        .forEach((key) => {
          coreAttributeDataset1[key] = coreAttributeData1[key];
        });
    }

    if (result?.date2 && result?.date2.length !== 0) {
      const coreAttributesData = result?.date2;

      coreAttributeData2 = coreAttributesData.reduce((acc, obj) => {
        acc[obj?.core_attribute?.name] = obj?.avg_score;
        return acc;
      }, {});

      Object.keys(coreAttributeData2)
        .sort((a, b) => coreAttributeData2[b] - coreAttributeData2[a])
        .forEach((key) => {
          coreAttributeDataset2[key] = coreAttributeData2[key];
        });
    }

    let coreAttributesToDisplay = [
      coreAttributeDataset1,
      coreAttributeDataset2,
    ];
    if (isInitial) {
      coreAttributesToDisplay = [coreAttributeDataset2];
    }
    coreAttributesToDisplay = coreAttributesToDisplay.filter(
      (data) => data !== undefined
    );

    setCoreAttributesData(coreAttributesToDisplay);
  };

  const getCompetitorComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);
    let result;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        personaId: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getIndexScoreForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        personaId: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getIndexScoreForProduct(payload);
    }

    getCompetitorIndexScores(result, isInitial);
    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0],
      product: selectedProductItems[0],
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
    });
  };

  const getAttributesData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial,
    brandName,
    productName
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);
    let result;
    let result2;
    let result3;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        personaId: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };
      result = await getBrandAttributes(payload);
      result2 = await getBrandCoreAttributes(payload);
      result3 = await getIndexScoreForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        personaId: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };
      result = await getProductAttributes(payload);
      result2 = await getProductCoreAttributes(payload);
      result3 = await getIndexScoreForProduct(payload);
    }

    getAttributesSignalStrengthData(result, isInitial);
    getCoreAttributesScoreData(result2, isInitial);
    getIndexScores(result3, isInitial);
    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0] || brandName,
      product: selectedProductItems[0] || productName,
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
    });
  };

  // const groupByDate = (array) => {
  //   const grouped = array.reduce((acc, obj) => {
  //     const date = obj.date_added.split("T")[0]; // Extract the date part
  //     if (!acc[date]) {
  //       acc[date] = [];
  //     }
  //     acc[date].push(obj);
  //     return acc;
  //   }, {});

  //   return Object.values(grouped); // Return the grouped arrays as an array
  // };

  const transformData = (data, index) => {
    const brandData = data?.brand;
    const compBrandsData = data?.compBrands;

    const groupByDay = (arr, keyField, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const key = keyField === "mainbrand" ? keyField : obj.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const compBrandsGrouped = compBrandsData.length
      ? groupByDay(compBrandsData, "name", index)
      : {};
    const brandGrouped = brandData.length
      ? groupByDay(brandData, "mainbrand", index)
      : {};

    const allDays = new Set([
      ...Object.keys(compBrandsGrouped),
      ...Object.keys(brandGrouped),
    ]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(compBrandsGrouped[day] || {}),
        ...(brandGrouped[day] || {}),
      };
    });

    return finalArray;
  };

  const getBrandIndexData = (
    inputData,
    selectedIndex,
    selectedSimilarIndex
  ) => {
    // const mainArray = groupByDate(inputData);
    // let firstChildArray = mainArray.shift();
    // mainArray[0].push(...firstChildArray);

    const brandIndexArray = inputData.map((data, index) => {
      let brandIndexScoreDate1 = data.filter((data) => {
        return data.competitor_json !== null;
      });

      let brandIndexDate1 = calculateAverages(brandIndexScoreDate1);
      const productName = selectedProductItems[0];
      const productIndexValue = brandIndexDate1[selectedIndex];

      let productIndexScore = { [productName]: productIndexValue };
      let productCompetitorsIndexScores = brandIndexDate1["competitor_json"];
      const outputObject = {};

      productCompetitorsIndexScores.forEach((item) => {
        outputObject[item.name] = item[selectedSimilarIndex];
      });

      const finalBrandIndex = { ...productIndexScore, ...outputObject };

      return finalBrandIndex;
    });

    return brandIndexArray;
  };

  // const getBrandIndexScoreData = async (
  //   brandId,
  //   productId,
  //   llmId,
  //   startDate,
  //   endDate,
  //   selectedIndex,
  //   selectedSimilarIndex
  // ) => {
  //   const payloadForBrandIndexScore = {
  //     brandId: brandId,
  //     productId: productId,
  //     llmId: llmId,
  //     startDate: startDate,
  //     endDate: endDate,
  //   };

  //   const brandIndexScoreData = await getBrandIndexScores(
  //     payloadForBrandIndexScore
  //   );

  //   let brandIndexScoreDate1 = brandIndexScoreData.date1.filter((data) => {
  //     return data.competitor_json !== null;
  //   });

  //   let brandIndexDate1 = calculateAverages(brandIndexScoreDate1);

  //   const productName = selectedProductItems[0];
  //   const productIndexValue = brandIndexDate1[selectedIndex];

  //   let productIndexScore = { [productName]: productIndexValue };
  //   let productCompetitorsIndexScores = brandIndexDate1["competitor_json"];
  //   const outputObject = {};

  //   productCompetitorsIndexScores.forEach((item) => {
  //     outputObject[item.name] = item[selectedSimilarIndex];
  //   });

  //   const brandIndexScoreDate2 = brandIndexScoreData.date2.filter((data) => {
  //     return data.competitor_json !== null;
  //   });

  //   let brandIndexDate2 = calculateAverages(brandIndexScoreDate2);

  //   const productName2 = selectedProductItems[0];
  //   const productIndexValue2 = brandIndexDate2[selectedIndex];
  //   let productIndexScore2 = { [productName2]: productIndexValue2 };

  //   let productCompetitorsIndexScores2 = brandIndexDate2["competitor_json"];
  //   const outputObject2 = {};

  //   productCompetitorsIndexScores2.forEach((item) => {
  //     outputObject2[item.name] = item[selectedSimilarIndex];
  //   });

  //   const finalBrandIndex = { ...productIndexScore, ...outputObject };
  //   const finalBrandIndex2 = { ...productIndexScore2, ...outputObject2 };
  //   return { finalBrandIndex, finalBrandIndex2 };
  // };

  const getDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      // const brandId =
      //   brandItems.filter(
      //     (item) => item?.brand?.name === selectedBrandItems[0]
      //   )[0]?.id || null;

      // const productId =
      //   productItems.filter(
      //     (item) => item?.brand_product?.name === selectedProductItems[0]
      //   )[0]?.id || null;
      // const llmId = dbLlms.filter(
      //   (item) => item?.llm?.name === selectedLlmItems[0]
      // )[0]?.llm_id;
      // const personaId = personas.filter(
      //   (item) => item?.persona?.name === selectedPersona[0]
      // )[0]?.persona_id;

      // const payloadForBrandIndexScore = {
      //   brandId: brandId,
      //   productId: productId,
      //   llmId: llmId,
      //   startDate: startDate,
      //   endDate: endDate,
      // };

      // const brandIndexScoreData = await getBrandIndexScores(
      //   payloadForBrandIndexScore
      // );

      // const brandIndex = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_index_score",
      //   "total"
      // );

      // const brandFav = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_favorability_score",
      //   "favorability"
      // );

      // const brandReach = getBrandIndexData(
      //   brandIndexScoreData,
      //   "brand_reach_score",
      //   "rank"
      // );

      // setBrandIndexDataSet(brandIndex);
      // setBrandFavDataSet(brandFav);
      // setBrandReachDataSet(brandReach);

      await getAttributesData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial,
        brandName,
        productName
      );

      // await getCompetitorComparisonData(
      //   brandId,
      //   productId,
      //   personaId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   isInitial
      // );

      // await getAttributesData();

      // const brandIndex = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_index_score",
      //   "total"
      // );

      // const brandFav = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_favorability_score",
      //   "favorability"
      // );

      // const brandReach = await getBrandIndexScoreData(
      //   brandId,
      //   productId,
      //   llmId,
      //   startDate,
      //   endDate,
      //   "brand_reach_score",
      //   "rank"
      // );

      // setBrandIndexSet1(brandIndex.finalBrandIndex);
      // setBrandIndexSet2(brandIndex.finalBrandIndex2);
      // setBrandFavSet1(brandFav.finalBrandIndex);
      // setBrandFavSet2(brandFav.finalBrandIndex2);
      // setBrandReachSet1(brandReach.finalBrandIndex);
      // setBrandReachSet2(brandReach.finalBrandIndex2);

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      // setShowData(true);
      setIsDone(true);
    }
  };

  const handleClickReset = () => {
    setSelectedLlmItems([]);
    setShowData(false);
    setIsDone(true);
    setStartDate("");
    setEndDate("");
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedFrequencyItems([]);
    setSelectedPersona([]);
  };

  function calculatePercentageDifference(score1 = 0, score2 = 0) {
    // Calculate the difference between score2 and score1
    const difference = score2 - score1;

    // Calculate the percentage difference based on score1
    const percentageDifference = (difference / score1) * 100;

    const formattedPercentageDifference =
      (percentageDifference >= 0 ? "+" : "") + percentageDifference.toFixed(2);

    // Return the formatted percentage difference
    return formattedPercentageDifference;
  }

  const handleInputValidation = () => {
    let errorMessage = "";
    if (
      selectedOptionShow === "Company/Brand" &&
      selectedBrandItems &&
      selectedBrandItems.length === 0
    ) {
      errorMessage = "Please Select a Brand !";
    } else if (
      selectedOptionShow === "Product" &&
      selectedProductItems &&
      selectedProductItems.length === 0
    ) {
      errorMessage = "Please Select a Product !";
    } else if (selectedPersona && selectedPersona.length == 0) {
      errorMessage = "Please select a Persona !";
    } else if (selectedFrequencyItems && selectedFrequencyItems.length == 0) {
      errorMessage = "Please select Frequency !";
    } else if (!startDate || !endDate) {
      errorMessage = "Date is Missing, Please Select!";
    } else if (selectedLlmItems && selectedLlmItems.length == 0) {
      errorMessage = "Please select LLM !";
    }

    return errorMessage;
  };

  // if (!isDone) {
  //   return <p> Please wait, we are preparing inital dashboad !!</p>;
  // }

  return (
    <div className="">
      <Container fluid>
        <Container className="border border-secondary-subtle borderSet mt">
          <h4 className="float-start text1">Dashboard2</h4>

          <div className="p-3">
            <Container className="back">
              <Form className="form-inline form-5yquicksearch mx-auto mt-5 p-3">
                <Row className="mb-5">
                  <Form.Group as={Col} md="12">
                    <Row>
                      <ul className="nav brand-tabs">
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Company/Brand"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() =>
                                handleRadioSectionShow("Company/Brand")
                              }
                            >
                              <span></span> Company/Brand
                            </a>
                          </li>
                        </Col>
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Product"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() => handleRadioSectionShow("Product")}
                            >
                              <span> </span> Product
                            </a>
                          </li>
                        </Col>
                        {selectedOptionShow === "Company/Brand" && (
                          <>
                            <Col md="3">
                              <DashboardBrandProductComponent
                                options={brandItems}
                                handleCheckChange={handleBrandCheckChange}
                                selectedBrandProductItems={selectedBrandItems}
                                isBrand={true}
                              />
                            </Col>
                            <Col md="3">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={true}
                              />
                            </Col>
                          </>
                        )}
                        {selectedOptionShow === "Product" && (
                          <>
                            <Col md="3">
                              <DashboardBrandProductComponent
                                options={productItems}
                                handleCheckChange={handleProductCheckChange}
                                selectedBrandProductItems={selectedProductItems}
                              />
                            </Col>
                            <Col md="3">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={false}
                              />
                            </Col>
                          </>
                        )}

                        <Col style={{ marginLeft: "10px" }} md="2">
                          <DashboardFrequencyComponent
                            options={frequencyOptions}
                            handleCheckChange={handleFrequencyCheckChange}
                            selectedFrequencyItems={selectedFrequencyItems}
                          />
                        </Col>
                      </ul>
                    </Row>

                    <Row
                      style={{ marginBottom: "-25px" }}
                      // className="justify-content-center"
                    >
                      <Col style={{ marginRight: "55px" }} md="2">
                        <Form.Group controlId="exampleForm.DateInput">
                          <Form.Control
                            style={{ height: "51px", width: "140%" }}
                            type="date"
                            value={startDate}
                            onChange={handleStartDateCheckChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col style={{ marginRight: "55px" }} md="2">
                        <Form.Group controlId="exampleForm.DateInput">
                          <Form.Control
                            style={{ height: "51px", width: "140%" }}
                            type="date"
                            value={endDate}
                            onChange={handleEndDateCheckChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col style={{ marginRight: "65px" }} md="2">
                        <DashboardLLMsComponent
                          options={dbLlms}
                          handleCheckChange={handleCheckChange}
                          selectedLlmItems={selectedLlmItems}
                        />
                      </Col>
                      <Col md="2">
                        <Button
                          type="button"
                          name="firstName"
                          placeholder="Your Brand/Product"
                          className="height2 "
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#3dc863",
                            color: "white",
                          }}
                          onClick={handleClickShow}
                          disabled={!isDone}
                        >
                          {!isDone && !isFirstTime ? (
                            <div style={{ fontSize: "19px" }}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Please Wait...
                            </div>
                          ) : (
                            <div>LAUNCH</div>
                          )}
                        </Button>
                      </Col>
                      <Col md="2">
                        <Button
                          type="button"
                          name="firstName"
                          placeholder="Your Brand/Product"
                          className="height2"
                          style={{
                            width: "-webkit-fill-available",
                            backgroundColor: "#3dc863",
                            color: "white",
                          }}
                          onClick={handleClickReset}
                        >
                          RESET
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </Form>
            </Container>
            {/*-------------------- Data Display Section --------------------*/}

            {showData && isDone ? (
              <Card>
                <Card.Body>
                  {indexScores?.date1 || indexScores?.date2 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 15px 0px 15px",
                        }}
                      >
                        <div>
                          <h5> Index score </h5>
                          {indexScores?.isInitial ? (
                            <p>
                              Date ({additionalDashboradInfo?.endDate}) &nbsp;
                              {Number(
                                indexScores?.date2?.avg_brand_index_score
                              ).toFixed(2)}
                            </p>
                          ) : (
                            <>
                              <p>
                                Date1 ({additionalDashboradInfo?.startDate})
                                &nbsp;
                                {indexScores?.date1?.avg_brand_index_score
                                  ? Number(
                                      indexScores?.date1?.avg_brand_index_score
                                    ).toFixed(2)
                                  : "Not Available"}
                              </p>
                              <p>
                                Date2 ({additionalDashboradInfo?.endDate})
                                &nbsp;
                                {indexScores?.date2?.avg_brand_index_score
                                  ? `${Number(
                                      indexScores?.date2?.avg_brand_index_score
                                    ).toFixed(2)}
                               
                                  ${
                                    indexScores?.date1?.avg_brand_index_score &&
                                    indexScores?.date2?.avg_brand_index_score
                                      ? `(${calculatePercentageDifference(
                                          indexScores?.date1
                                            ?.avg_brand_index_score,
                                          indexScores?.date2
                                            ?.avg_brand_index_score
                                        )}%)`
                                      : ""
                                  }`
                                  : "Not Available"}
                              </p>
                            </>
                          )}
                        </div>

                        <div>
                          <h5> Favorability score </h5>
                          {indexScores?.isInitial ? (
                            <p>
                              Date ({additionalDashboradInfo?.endDate}) &nbsp;
                              {Number(
                                indexScores?.date2?.avg_brand_favorability_score
                              ).toFixed(2)}
                            </p>
                          ) : (
                            <>
                              <p>
                                Date1 ({additionalDashboradInfo?.startDate})
                                &nbsp;
                                {indexScores?.date1
                                  ?.avg_brand_favorability_score
                                  ? Number(
                                      indexScores?.date1
                                        ?.avg_brand_favorability_score
                                    ).toFixed(2)
                                  : "Not Available"}
                              </p>
                              <p>
                                Date2 ({additionalDashboradInfo?.endDate})
                                &nbsp;
                                {indexScores?.date2
                                  ?.avg_brand_favorability_score
                                  ? `${Number(
                                      indexScores?.date2
                                        ?.avg_brand_favorability_score
                                    ).toFixed(2)}
                               
                                ${
                                  indexScores?.date1
                                    ?.avg_brand_favorability_score &&
                                  indexScores?.date2
                                    ?.avg_brand_favorability_score
                                    ? `(${calculatePercentageDifference(
                                        indexScores?.date1
                                          ?.avg_brand_favorability_score,
                                        indexScores?.date2
                                          ?.avg_brand_favorability_score
                                      )}%)`
                                    : ""
                                }`
                                  : "Not Available"}
                              </p>
                            </>
                          )}
                        </div>

                        <div>
                          <h5> Reach score </h5>
                          {indexScores?.isInitial ? (
                            <p>
                              Date ({additionalDashboradInfo?.endDate}) &nbsp;
                              {Number(
                                indexScores?.date2?.avg_brand_reach_score
                              ).toFixed(2)}
                            </p>
                          ) : (
                            <>
                              <p>
                                Date1 ({additionalDashboradInfo?.startDate})
                                &nbsp;
                                {indexScores?.date1?.avg_brand_reach_score
                                  ? Number(
                                      indexScores?.date1?.avg_brand_reach_score
                                    ).toFixed(2)
                                  : "Not Available"}
                              </p>
                              <p>
                                Date2 ({additionalDashboradInfo?.endDate})
                                &nbsp;
                                {indexScores?.date2?.avg_brand_reach_score
                                  ? `${Number(
                                      indexScores?.date2?.avg_brand_reach_score
                                    ).toFixed(2)}
                               
                               ${
                                 indexScores?.date1?.avg_brand_reach_score &&
                                 indexScores?.date2?.avg_brand_reach_score
                                   ? `(${calculatePercentageDifference(
                                       indexScores?.date1
                                         ?.avg_brand_reach_score,
                                       indexScores?.date2?.avg_brand_reach_score
                                     )}%)`
                                   : ""
                               }`
                                  : "Not Available"}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <hr />
                      <p style={{ textAlign: "center" }}>
                        No Brand Index Score Data available !
                      </p>
                    </>
                  )}
                  <hr />
                  {positiveAttributes &&
                  positiveAttributes.some(
                    (obj) => Object.keys(obj).length > 0
                  ) ? (
                    <>
                      <DashboardMultiBarChart
                        additionalDashboradInfo={additionalDashboradInfo}
                        focusAttribute={"Positive Attributes"}
                        dashboardData={positiveAttributes}
                        selectedFrequency={selectedFrequencyItems[0]}
                      />
                    </>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      No positive attributes Data available !
                    </p>
                  )}

                  <hr />

                  {negativeAttributes &&
                  negativeAttributes.some(
                    (obj) => Object.keys(obj).length > 0
                  ) ? (
                    <DashboardMultiBarChart
                      additionalDashboradInfo={additionalDashboradInfo}
                      focusAttribute={"Negative Attributes"}
                      dashboardData={negativeAttributes}
                      selectedFrequency={selectedFrequencyItems[0]}
                    />
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      No negative attributes Data available !
                    </p>
                  )}

                  <hr />

                  {coreAttributesData &&
                  coreAttributesData.some(
                    (obj) => Object.keys(obj).length > 0
                  ) ? (
                    <DashboardMultiBarChart
                      additionalDashboradInfo={additionalDashboradInfo}
                      focusAttribute={"Core Attributes"}
                      dashboardData={coreAttributesData}
                      selectedFrequency={selectedFrequencyItems[0]}
                    />
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      No core attributes Data available !
                    </p>
                  )}

                  {/* <hr />

                  <LineChart
                    additionalDashboradInfo={additionalDashboradInfo}
                    brandIndexData={brandIndexDataSet}
                    label="Brand Index Score"
                  />

                  <hr />

                  <LineChart
                    additionalDashboradInfo={additionalDashboradInfo}
                    brandIndexData={brandFavDataSet}
                    label="Brand favorability Score"
                  />

                  <hr />

                  <LineChart
                    additionalDashboradInfo={additionalDashboradInfo}
                    brandIndexData={brandReachDataSet}
                    label="Brand Reach Score"
                  /> */}
                </Card.Body>
              </Card>
            ) : isFirstTime ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "22px",
                  alignItems: "center",
                  padding: "20px",
                  marginTop: "30px",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Dashboard is being prepared Please Wait...
              </div>
            ) : null}
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </div>
  );
}
export default layout(Dashboard2, false);
