import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import {
  fetchDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";

function ReportPrompt({ LLMs }) {
  const [prompt, setPrompt] = useState("");
  const [endPrompt, setEndPrompt] = useState("");
  const [llm, setLLM] = useState();

  useEffect(() => {
  //   fetchDataFromFirebase((data) => {
  //     if(data !== null) {
  //     const [{ LLM, prompt, endPrompt }] = data;
  //     setLLM(LLM);
  //     setEndPrompt(endPrompt)
  //     setPrompt(prompt);
  // }}, "reportPrompt");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!prompt === "" || llm === "" || endPrompt === "") return;

    updateDataInFirebase('reportPrompt', "0", {
        LLM: llm,
        prompt: prompt,
        endPrompt
    })
    toast.success("Prompts Updated successfully");
  };

  return (
    <div>
      <h5>Report Prompt</h5>
      <Form className="w-50" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Start Prompt with</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={prompt}
            type="text"
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>End Prompt with</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={endPrompt}
            type="text"
            onChange={(e) => setEndPrompt(e.target.value)}
            placeholder="Enter your prompt"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Select LLM</Form.Label>
          <Form.Select onChange={(e) => setLLM(e.target.value)} value={llm}>
            {LLMs.map((llm) => (
              <option key={llm.value} value={llm.value}>
                {llm.name}
              </option>
            ))}
          </Form.Select>
          <Button
            style={{
              width: "10%",
              backgroundColor: "#3dc863",
              color: "white",
              marginTop: 10,
            }}
            type="submit"
          >
            Save
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ReportPrompt;
