import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import "react-toastify/dist/ReactToastify.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { layout } from "../LayoutComponent/LayoutComponent";
import { fetchDataFromFirebase } from "../../DatabaseFirebase/firebaseService";
import { Spinner, Form } from "react-bootstrap";
import { getCurrentDate } from "../../utils/helpers";

function LlmUsageRecord() {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [functionalityUsage, setFunctionalityUsage] = useState();
  const [selectedLlm, setSelectedLlm] = useState();

  const llmOptions = [
    { name: "GPT-4 (OpenAI/Microsoft)", value: "gpt-4" },
    { name: "GPT-4o (OpenAI/Microsoft)", value: "gpt-4o" },
    { name: "GPT-3.5 (OpenAI/Microsoft)", value: "gpt-3.5-turbo" },
    { name: "Gemini (Google)", value: "Gemini" },
    { name: "Perplexity", value: "Perplexity" },
    { name: "Llama2 (Meta)", value: "Llama2" },
    { name: "Llama3 (Meta)", value: "Llama3" },
    { name: "Claude3 (Anthropic)", value: "Claude3" },
    // { name: "GPT-4 Web", value: "GPT-4_Web" },
    // { name: "Google SGE", value: "Google-SGE" },
  ];

  useEffect(() => {
    // fetchDataFromFirebase(
    //   (data) => {
    //     if (data !== null) {
    //       const arrayOfObjects = Object.entries(data).map(([key, value]) => ({
    //         key,
    //         ...value,
    //       }));
    //       setRows(arrayOfObjects);
    //       setInitialRows(arrayOfObjects);
    //     } else {
    //       setRows(null);
    //       setInitialRows([]);
    //     }
    //   },
    //   "llmUsage",
    //   undefined,
    //   selectedDate
    // );
  }, [selectedDate]);

  const handleChangeLlm = (e) => {
    setSelectedLlm(e.target.value);
    const currentRows = [...initialRows];
    if (e.target.value) {
      let filteredRows = [];
      if (functionalityUsage) {
        filteredRows = currentRows.filter(
          (data) =>
            data.llm === e.target.value &&
            data.functionalityUsage === functionalityUsage
        );
      } else {
        filteredRows = currentRows.filter(
          (data) => data.llm === e.target.value
        );
      }
      if (filteredRows.length !== 0) {
        setRows(filteredRows);
      } else {
        setRows(null);
      }
    } else {
      const filteredRows = currentRows.filter((data) => data.llm !== "");
      setRows(filteredRows);
    }
  };

  const handleChangeFunctionalityUsage = (e) => {
    setFunctionalityUsage(e.target.value);
    const currentRows = [...initialRows];
    if (e.target.value) {
      let filteredRows = [];
      if (selectedLlm) {
        filteredRows = currentRows.filter(
          (data) =>
            data.functionalityUsage === e.target.value &&
            data.llm === selectedLlm
        );
      } else {
        filteredRows = currentRows.filter(
          (data) => data.functionalityUsage === e.target.value
        );
      }
      if (filteredRows.length !== 0) {
        setRows(filteredRows);
      } else {
        setRows(null);
      }
    } else {
      const filteredRows = currentRows.filter(
        (data) => data.functionalityUsage !== ""
      );
      setRows(filteredRows);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <h3> LLM Usage Record</h3>
        <div style={{ display: "flex" }}>
          <Form.Group controlId="exampleForm.DateInput">
            <Form.Control
              as="select"
              value={functionalityUsage}
              onChange={(e) => {
                handleChangeFunctionalityUsage(e);
              }}
              style={{ height: "51px" }}
            >
              <option value="">Filter by Functionality</option>
              <option value="Discovery">Discovery</option>
              <option value="Reporting">Reporting</option>
              <option value="Frontend Monitoring">Frontend Monitoring</option>
              <option value="Backend Monitoring">Backend Monitoring</option>
              <option value="Dashboard">Dashboard</option>
            </Form.Control>
          </Form.Group>
          &nbsp;
          <Form.Group controlId="exampleForm.DateInput">
            <Form.Control
              as="select"
              value={selectedLlm}
              onChange={(e) => {
                handleChangeLlm(e);
              }}
              style={{ height: "51px" }}
            >
              <option value="">Filter by LLM</option>
              {llmOptions.map((option) => (
                <option value={option.value}>{option.value}</option>
              ))}
            </Form.Control>
          </Form.Group>{" "}
          &nbsp;
          <Form.Group controlId="exampleForm.DateInput">
            <Form.Control
              style={{ height: "51px", marginRight: "40px" }}
              type="date"
              value={selectedDate}
              onChange={(e) => {
                setSelectedDate(e.target.value);
                setFunctionalityUsage("");
                setSelectedLlm("");
              }}
            />
          </Form.Group>{" "}
          &nbsp;
        </div>
      </div>
      <h4 style={{paddingLeft: "5%",}}> Total Count: {rows && rows.length} </h4>
      
      {rows && rows.length == 0 ? (
        <div className="container d-flex flex-column align-items-center mt-5">
          <Spinner />
          <span className="mt-3">Loading LLM Usage Data...</span>
        </div>
      ) : rows !== null ? (
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "90%",
            margin: "auto",
            overflow: "auto",
            marginBottom: "20px",
          }}
          md={{ maxWidth: "80%", margin: "auto", overflow: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: "12%" }}>
                  <b>MODEL USED</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "12%" }}>
                  <b>FUNCTIONALITY USAGE</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "40%" }}>
                  <b>STATUS</b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    key={row?.llm}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row?.llm}</TableCell>
                    <TableCell align="center">
                      {row?.functionalityUsage || "--"}
                    </TableCell>

                    <TableCell align="center" sx={{ width: "25%" }}>
                      {row.status === "failed" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <HourglassTopIcon
                            style={{ color: "#FFA500", marginRight: 4 }}
                          />
                          <Typography style={{ color: "#FFA500" }}>
                            FAILED
                          </Typography>
                        </Box>
                      ) : row.status === "processed" ? (
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CheckCircleIcon
                              style={{ color: "#4CAF50", marginRight: 4 }}
                            />
                            <Typography style={{ color: "#4CAF50" }}>
                              PROCESSED
                            </Typography>
                          </Box>

                          {/* Display current date below the CheckCircleIcon and Typography */}
                          <Typography
                            sx={{
                              fontStyle: "italic",
                              fontSize: "11px",
                              color: "#757575",
                            }}
                          >
                            {row?.date ? `triggered on ${row?.date}` : ""}
                          </Typography>
                        </div>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="container d-flex flex-column align-items-center mt-5">
          <span className="mt-3">No Data Available !</span>
        </div>
      )}
    </>
  );
}

export default layout(LlmUsageRecord, false);
