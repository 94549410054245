// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MonitorMenu_actionBtn__kSR7M {
    padding: 7px 12px !important;
    font-size: 18px !important;
    color: rgb(51, 51, 51) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/Component/MonitoringComponent/MonitorMenu.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,0BAA0B;IAC1B,iCAAiC;IACjC,wBAAwB;IACxB,kCAAkC;IAClC,8BAA8B;EAChC","sourcesContent":[".actionBtn {\n    padding: 7px 12px !important;\n    font-size: 18px !important;\n    color: rgb(51, 51, 51) !important;\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBtn": `MonitorMenu_actionBtn__kSR7M`
};
export default ___CSS_LOADER_EXPORT___;
