// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsToggle_error__LYM-o {
  padding: 16px 0;
}
.SettingsToggle_card_content__5uNsJ {
  padding: 0px 16px !important;
}
.SettingsToggle_list__bULst {
  width: 100%;
  bgcolor: "background.paper";
}
`, "",{"version":3,"sources":["webpack://./src/BrandOnBoarding/Settings/Component/SettingsToggle.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,WAAW;EACX,2BAA2B;AAC7B","sourcesContent":[".error {\n  padding: 16px 0;\n}\n.card_content {\n  padding: 0px 16px !important;\n}\n.list {\n  width: 100%;\n  bgcolor: \"background.paper\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `SettingsToggle_error__LYM-o`,
	"card_content": `SettingsToggle_card_content__5uNsJ`,
	"list": `SettingsToggle_list__bULst`
};
export default ___CSS_LOADER_EXPORT___;
