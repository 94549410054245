// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LLMResultOutput_container__kIjKO {
  background-color: #000;
    border-radius: 5px;
    overflow: scroll;
}
.LLMResultOutput_results__ejYcd {
  background-color: #000000cf;
  color: white;
}
.LLMResultOutput_md__dz8oU {
  border-radius: 10px;
  padding: 12px 25px;
}
.LLMResultOutput_badge_wrapper__K48Kk {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.LLMResultOutput_badge__OUhzQ {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -30px;
}
.LLMResultOutput_actions__Ik-Fj {
  display: flex;
  padding-bottom: 2px;
  justify-content: flex-end;
}
.LLMResultOutput_btn__wzmdF {
  color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/Component/BasicCardComponent/Reporting/LLMResultOutput.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;IACpB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;EACE,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  background-color: #000;\n    border-radius: 5px;\n    overflow: scroll;\n}\n.results {\n  background-color: #000000cf;\n  color: white;\n}\n.md {\n  border-radius: 10px;\n  padding: 12px 25px;\n}\n.badge_wrapper {\n  padding-top: 30px;\n  margin-bottom: 15px;\n  position: relative;\n}\n.badge {\n  background: #37ad52;\n  color: white;\n  padding: 5px;\n  border-radius: 2px;\n  position: relative;\n  left: -30px;\n}\n.actions {\n  display: flex;\n  padding-bottom: 2px;\n  justify-content: flex-end;\n}\n.btn {\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LLMResultOutput_container__kIjKO`,
	"results": `LLMResultOutput_results__ejYcd`,
	"md": `LLMResultOutput_md__dz8oU`,
	"badge_wrapper": `LLMResultOutput_badge_wrapper__K48Kk`,
	"badge": `LLMResultOutput_badge__OUhzQ`,
	"actions": `LLMResultOutput_actions__Ik-Fj`,
	"btn": `LLMResultOutput_btn__wzmdF`
};
export default ___CSS_LOADER_EXPORT___;
