import { displayDateTime } from "../utils/helpers";
const baseURL =
  window.location.hostname === "www.brandluminaire.ai"
    ? "https://www.brandluminaire.ai/api"
    : "https://us-central1-brand-luminaire.cloudfunctions.net";

const dbBaseURL = "https://www.brandluminaire.ai/api";
const devDbBaseURL = "https://llmapi.buzzybrains.net/api";
// const devDbBaseURL  = 'http://localhost:8080/api'

const handleLlmUsageMeasure = async (model, functionalityUsage, status) => {
  if (window.location.hostname !== "www.brandluminaire.ai") {
    const apiMeasurePayload = {
      llm: model,
      date: displayDateTime(),
      functionalityUsage: functionalityUsage,
      status: status,
    };
    const url = `https://us-central1-brand-luminaire.cloudfunctions.net/addLlmUsageMeasure`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiMeasurePayload),
    };

    await fetch(url, API_CONFIG);
  }
};

export async function fetchGooeyAPI(
  inputPrompt,
  selectedOption,
  abortController
) {
  const payload = {
    input_prompt: inputPrompt,
    selected_models: [selectedOption], // Modify to handle one selected item at a time
    avoid_repetition: false,
    num_outputs: 1,
    quality: 1,
    max_tokens: 800,
    sampling_temperature: 0.7,
    variables: null,
  };

  try {
    const url = `${baseURL}/fetchGooeyAPI`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchGpt4API(
  inputPrompt,
  abortController,
  max_tokens = 800,
  temperature = 1,
  model = "gpt-4",
  response_format = {},
  functionalityUsage = "Reporting"
) {
  const payload = {
    messages: [
      {
        role: "user",
        content: inputPrompt,
      },
    ],
    temperature,
    max_tokens: max_tokens,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    model: model,
  };

  if (Object.keys(response_format).length > 0) {
    payload.response_format = response_format;
  }

  try {
    const url = `${baseURL}/fetchGptAPI`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure(model, functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure(model, functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function fetchLlama2API(
  inputPrompt,
  abortController,
  temperature = 0.9,
  functionalityUsage = "Reporting"
) {
  const payload = {
    messages: [{ role: "user", content: inputPrompt }],
    temperature,
  };
  try {
    const url = `${baseURL}/fetchLlama2API`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure("Llama2", functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure("Llama2", functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function fetchLlama3API(
  inputPrompt,
  abortController,
  temperature = 0.1,
  functionalityUsage = "Reporting"
) {
  const payload = {
    messages: [{ role: "user", content: inputPrompt }],
    temperature,
  };
  try {
    const url = `${baseURL}/fetchLlama3API`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure("Llama3", functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure("Llama3", functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function fetchSlackAPI(payload, abortController) {
  try {
    const baseUrl2 = "https://www.brandluminaire.ai/api";
    const url = `${baseUrl2}/fetchSlackAPI`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchClaude3API(
  inputPrompt,
  abortController,
  temperature = 1.0,
  max_tokens = 1024,
  functionalityUsage = "Reporting"
) {
  const payload = {
    model: "claude-3-opus-20240229",
    max_tokens,
    temperature,
    messages: [{ role: "user", content: inputPrompt }],
  };
  try {
    const url = `${baseURL}/fetchClaude3API`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure("Claude3", functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure("Claude3", functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function fetchPerplexityAPI(
  inputPrompt,
  abortController,
  temperature = 1.0,
  max_tokens = 1024,
  model = "mistral-7b-instruct",
  functionalityUsage = "Reporting"
) {
  const payload = {
    model,
    messages: [
      {
        role: "user",
        content: inputPrompt,
      },
    ],
    max_tokens,
    temperature,
  };
  try {
    const url = `${baseURL}/fetchPerplexityAPI`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure("Perplexity", functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure("Perplexity", functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function fetchGeminiAPI(
  inputPrompt,
  abortController,
  temperature = 0.9,
  functionalityUsage = "Reporting"
) {
  const payload = { prompt: inputPrompt, temperature };
  try {
    const url = `${baseURL}/fetchGeminiAPI`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };

    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    await handleLlmUsageMeasure("Gemini", functionalityUsage, "processed");
    return response.json();
  } catch (err) {
    await handleLlmUsageMeasure("Gemini", functionalityUsage, "failed");
    throw new Error(err);
  }
}

export async function checkWebLink(link, abortController) {
  const payload = { link };
  const url = `${baseURL}/checkWebLink`;
  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    signal: abortController.signal,
  };

  const response = await fetch(url, API_CONFIG);
  return response.json();
}

export async function fetchDataFromChatGPTWeb(payload, abortController) {
  try {
    const url = `${baseURL}/fetchDataFromChatGPTWeb`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };
    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchFromGoogleSGE(inputPrompt, abortController) {
  const id = crypto.randomUUID();
  const payload = { prompt: inputPrompt, id: id };
  try {
    const url = `${baseURL}/fetchFromGoogleSGE`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };
    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function addReportingJob(payload, abortController) {
  try {
    const url = `${baseURL}/addReportingJob`;
    const API_CONFIG = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      signal: abortController?.signal,
    };
    const response = await fetch(url, API_CONFIG);
    if (response.status !== 200) {
      throw new Error();
    }
    return response.json();
  } catch (err) {
    throw new Error(err);
  }
}

export async function fetchBrandNames() {
  const url = `${dbBaseURL}/getbrands`;
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function fetchProductNames() {
  const url = `${dbBaseURL}/getproducts`;
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function fetchLlms() {
  const url = `${dbBaseURL}/getllms`;
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getLlms() {
  const url = `${devDbBaseURL}/llms`;
  const token = localStorage.getItem("token");
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getMarkets() {
  const url = `${devDbBaseURL}/markets`;
  const token = localStorage.getItem("token");
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function fetchCustomerLlms(id) {
  // const url = `${dbBaseURL}/getllms`;
  const url = `${devDbBaseURL}/customers/${id}/llms`;
  const token = localStorage.getItem("token");
  const API_CONFIG = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getAttributes(payload) {
  const url = `${dbBaseURL}/getattributes`;
  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getProductAttributes(payload) {
  // const url = `http://localhost:8080/api/product-attributes`;
  const url = `${devDbBaseURL}/product-attributes`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getBrandAttributes(payload) {
  const url = `${devDbBaseURL}/brand-attributes`;
  // const url = `http://localhost:8080/api/brand-attributes`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",

      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getProductCoreAttributes(payload) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/product-core-attributes`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getBrandCoreAttributes(payload) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/brand-core-attributes`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getBrandIndexScore(payload, abortController) {
  const url = `${baseURL}/brandIndexScore`;
  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    signal: abortController.signal,
  };

  const response = await fetch(url, API_CONFIG);
  return response.json();
}

export async function getBrandIndexScores(payload, abortController) {
  const url = `${dbBaseURL}/getbrandindexscores`;

  // const url = 'http://localhost:9090/api/getbrandindexscores'
  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getIndexScoreForBrand(payload) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/brand-index-score`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getIndexScoreForProduct(payload) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/product-index-score`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function generateBrandMetrics(llmId, brandPersonaId) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/generate-brand-metrics`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ llmId, brandPersonaId }),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function generateProductMetrics(llmId, brandProductPersonaId) {
  // const url = `${dbBaseURL}/getattributes`;
  const url = `${devDbBaseURL}/generate-product-metrics`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ llmId, brandProductPersonaId }),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getCompMetricsForProduct(payload) {
  const url = `${devDbBaseURL}/product-comp-metrics`;
  // const url = ` http://localhost:8080/api/product-comp-metrics`;

  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getCompMetricsForBrand(payload) {
  const url = `${devDbBaseURL}/brand-comp-metrics`;
  // const url = `http://localhost:8080/api/brand-comp-metrics`;
  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}

export async function getSummaryDataForBrandProduct(llmId) {
  const url = `${devDbBaseURL}/customer-brand-product-summary`;
  // const url = `http://localhost:8080/api/customer-brand-product-summary`;

  const token = localStorage.getItem("token");

  const API_CONFIG = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ llmId }),
  };
  const result = await fetch(url, API_CONFIG);
  return result.json();
}
