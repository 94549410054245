import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JSON5 from "json5";
import { useNavigate, useParams } from "react-router-dom";
import OverviewComponent from "../BasicCardComponent/DiscoverComponent/BrandComponent/OverviewComponent";
import FavorabilityComponent from "../BasicCardComponent/DiscoverComponent/BrandComponent/FavorabilityComponent";
import CategoryDimensions from "../BasicCardComponent/DiscoverComponent/BrandComponent/CategoryDimensions";
import IndexScore from "../BasicCardComponent/DiscoverComponent/BrandComponent/IndexScore";
import ReachComponent from "../BasicCardComponent/DiscoverComponent/BrandComponent/ReachComponent";
import RepresentationComponent from "../BasicCardComponent/DiscoverComponent/ProductComponent/RepresentationComponent";
import PFavorabilityComponent from "../BasicCardComponent/DiscoverComponent/ProductComponent/PFavorabilityComponent";
import PReachComponent from "../BasicCardComponent/DiscoverComponent/ProductComponent/PReachComponent";
import BrandCompetition from "../BasicCardComponent/DiscoverComponent/BrandComponent/BrandCompetition";
import ProductCompetition from "../BasicCardComponent/DiscoverComponent/ProductComponent/ProductCompetition";
import ReportingComponent from "./../BasicCardComponent/Reporting/ReportingComponent";
import POverviewComponent from "../BasicCardComponent/DiscoverComponent/ProductComponent/POverviewComponent";
import LLMDataDisplayComponent from "./LLMDataDisplayComponent";
import LLMSelectionComponent from "./LLMSelectionComponent ";
import "../../App.css";
import { Container, Form, Row, Col, Card } from "react-bootstrap";
import ChatComponent from "./ChatComponent";
import BrandRadioOptions from "./BrandRadioOptions";
import ProductRadioOptions from "./ProductRadioOptions";
import MOverviewComponent from "../BasicCardComponent/MonitoringComponent/BrandComponent/MOverviewComponent";
import MFavorabilityComponent from "../BasicCardComponent/MonitoringComponent/BrandComponent/MFavorabilityComponent";
import MCategoryDimensions from "../BasicCardComponent/MonitoringComponent/BrandComponent/MCategoryDimensions";
import MBrandCompetition from "../BasicCardComponent/MonitoringComponent/BrandComponent/MBrandCompetition";
import MReachComponent from "../BasicCardComponent/MonitoringComponent/BrandComponent/MReachComponent";
import MBrandRadioOptions from "./MBrandRadioOptions";
import MProductRadioOptions from "./MProductRadioOptions";
import {
  addDataToFirebase,
  fetchDataFromFirebase,
  updateDataInFirebase,
  deleteDataFromFirebase,
} from "../../DatabaseFirebase/firebaseService";
import { AuthContext } from "../../context/auth-context";
import {
  fetchGooeyAPI,
  fetchGpt4API,
  fetchGeminiAPI,
  fetchLlama2API,
  fetchLlama3API,
  fetchClaude3API,
  fetchFromGoogleSGE,
  fetchPerplexityAPI,
  getBrandIndexScore,
} from "../../services/apiService";
import { layout } from "../LayoutComponent/LayoutComponent";
import ConfirmToProceedModal from "../MonitoringComponent/ConfirmToProceedModal";

import {
  monitoringPrompt,
  generateRankingObject,
  calculateStrength,
  displayDateTime,
  createMarkdownTable,
} from "../../utils/helpers";
import UserQueue from "../UserQueue";
import {
  SOURCES_CONSOLIDATION_PROMPT,
  SOURCES_PROMPT,
  ATTRIBUTES_OPTIMIZATION_PROMPT,
} from "../../utils/constant";
import { BrandIndexContext } from "../../context/brand-index-context";

let unsubscribeFBDocEventsFunc = null;

function MainPage() {
  const { focusingArea } = useParams();
  const selectedFocusingArea =
    focusingArea !== undefined ? focusingArea : "Discover";
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedMonitoringOption, setSelectedMonitoringOption] = useState([]);
  const [selectedOptionShow, setSelectedOptionShow] = useState("Company/Brand");
  const [selectedOptionFirstShow, setSelectedOptionFirstShow] =
    useState(selectedFocusingArea);
  const [selectedOptionSecondShow, setSelectedOptionSecondShow] = useState("");
  const [showData, setShowData] = useState(false);
  const [showGetData, setShowGetData] = useState(false);
  const [showCheckBoxData, setShowCheckBoxData] = useState(false);
  const [dataItem, setDataItem] = useState({});
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const [promptBrandReach, setPromptBrandReach] = useState("");
  const [promptBrandReach1, setPromptBrandReach1] = useState("");
  //----------------------------------Dynamic  Data for API-------------------------------//
  const [promptData, setPromptData] = useState("");
  const [promptBrandKey, setPromptBrandKey] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedItemsReport, setCheckedItemsReport] = useState([]);
  const [editedData, setEditedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [daviationMessage, setDaviationMessage] = useState("");
  const [apiResponses, setApiResponses] = useState([]);
  const [apiCount, setApiCount] = useState(0);
  const [attributePrompts, setAttributePrompts] = useState({});
  const [monitoringCheckedItems, setMonitoringCheckedItems] = useState([]);
  const [baselineAPIDataSet, setBaselineAPIDataSet] = useState([]);
  const [userPositionInQueue, setUserPositionInQueue] = useState(null);
  const [brandIndexProductCount, setBrandIndexProductCount] = useState(5);
  const [brandIndexStep, setBrandIndexStep] = useState(0);
  const [brandIndexIndustry, setBrandIndexIndustry] = useState("");

  const navigate = useNavigate();

  const options = [
    { name: "GPT-4 (OpenAI/Microsoft)", value: "gpt-4" },
    { name: "GPT-4o (OpenAI/Microsoft)", value: "gpt-4o" },
    { name: "GPT-3.5 (OpenAI/Microsoft)", value: "gpt-3.5-turbo" },
    { name: "Gemini (Google)", value: "Gemini" },
    { name: "Perplexity", value: "Perplexity" },
    { name: "Llama2 (Meta)", value: "llama2_70b_chat" },
    { name: "Llama3 (Meta)", value: "llama3" },
    { name: "Claude3 (Anthropic)", value: "Claude" },
    { name: "GPT-4 Web", value: "GPT-4_Web" },
    { name: "Google SGE", value: "Google-SGE" },
  ];

  const monitoringLLMOptions = [
    { name: "GPT-4 (OpenAI/Microsoft)", value: "gpt-4" },
    { name: "Gemini (Google)", value: "Gemini" },
  ];

  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState({});
  const [againSelectedItems, setAgainSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItemStore, setCheckedItemStore] = useState([]);
  const [gpt4TurboPrompts, setGpt4TurboPrompts] = useState({});
  const [llama2Prompts, setLlama2Prompts] = useState({});
  const [llama3Prompts, setLlama3Prompts] = useState({});
  const [palm2Prompts, setPalm2Prompts] = useState({});
  const [gpt4Prompts, setGpt4Prompts] = useState({});
  const [geminiPrompts, setGeminiPrompts] = useState({});
  const [gpt4WebPrompts, setGpt4WebPrompts] = useState({});
  const [monitoringPrompts, setMonitoringPrompts] = useState({});
  const [claudePrompts, setClaudePrompts] = useState({});

  const [abortControllers, setAbortControllers] = useState([]);
  const [promptRunApproach, setPromptRunApproach] = useState("Independant");
  const [brandIndexScoreDoc, setBrandIndexScoreDoc] = useState(null);

  const { authUserEmail, isCurrentUserAdmin } = useContext(AuthContext);
  const { setShowHistory } = useContext(BrandIndexContext);

  const [apiCalls, setApiCalls] = useState(0);
  const [remainingCalls, setRemainingCalls] = useState(0);
  const [limitReached, setLimitReached] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const ATTRIBUTES_ANALYSIS_PROMPT = {
    "Top 5 Positive Attributes": monitoringPrompts?.positiveAttributesAnalysis,
    "Top 5 Negative Attributes": monitoringPrompts?.negativeAttributesAnalysis,
    "Top 5 Positive Statements": monitoringPrompts?.positiveFactsAnalysis,
    "Top 5 Negative Statements": monitoringPrompts?.negativeFactsAnalysis,
    "Top 5 Brands in Category": monitoringPrompts?.topBrandsAnalysis,
    "Top 5 Products in Category": monitoringPrompts?.topProductsAnalysis,
    "Frequently recommended buying criteria/considerations":
      monitoringPrompts?.buyingAnalysis,
  };

  const apiCallLimitToCurrentUser = isCurrentUserAdmin ? 100 : 25;

  useEffect(() => {
    setSelectedOptionFirstShow(selectedFocusingArea);
  }, [selectedFocusingArea]);

  const createAbortController = () => {
    const abortController = new AbortController();
    setAbortControllers((prevControllers) => [
      ...prevControllers,
      abortController,
    ]);
    return abortController;
  };

  const resetAbortControllers = () => {
    try {
      abortControllers.forEach((controller) => controller.abort());
      setAbortControllers([]);
    } catch (err) {
      throw new Error();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard", {
          autoClose: 500,
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCheckChange = (label, isChecked) => {
    let updatedSelectedItems;
    if (selectedOptionFirstShow === "Monitoring") {
      updatedSelectedItems = { [label]: isChecked };
    } else if (selectedOption === "Brand Index Score v2") {
      updatedSelectedItems = { [label]: isChecked };
    } else {
      updatedSelectedItems = { ...selectedItems, [label]: isChecked };
    }

    setSelectedItems(updatedSelectedItems);
    const newSelectedCount =
      Object.values(updatedSelectedItems).filter(Boolean).length;
    setSelectedCount(newSelectedCount);

    const selectedKeysArray = Object.keys(updatedSelectedItems).filter(
      (key) => updatedSelectedItems[key]
    );
    setAgainSelectedItems(selectedKeysArray);
  };

  const handleEditData = (data, wholeData) => {
    setEditedData(JSON.parse(data));
    setShowGetData(true);
  };

  const handleSelectAllChange = (event) => {
    if (event) {
      const isChecked = event.target.checked;
      setSelectAll(isChecked);
      let llmOptions =
        selectedOptionFirstShow === "Monitoring"
          ? monitoringLLMOptions
          : options;
      llmOptions = llmOptions.filter(
        (obj) => obj.value !== "GPT-4_Web" && obj.value != "Google-SGE"
      );

      const updatedSelectedItems = [];
      llmOptions.forEach((option) => {
        updatedSelectedItems[option.value] = isChecked;
      });
      setSelectedItems(updatedSelectedItems);
      const selectedKeysArray = Object.keys(updatedSelectedItems).filter(
        (key) => updatedSelectedItems[key]
      );
      setAgainSelectedItems(selectedKeysArray);

      setSelectedCount(isChecked ? llmOptions.length : 0);
    }
  };

  const handleMonitoringSetupCheckboxChange = (key) => {
    if (monitoringCheckedItems.includes(key)) {
      setMonitoringCheckedItems(
        monitoringCheckedItems.filter((item) => item !== key)
      );
      let currentApiData = [...baselineAPIDataSet];
      currentApiData = currentApiData.filter(
        (subData) => subData[0].title !== key
      );
      setBaselineAPIDataSet(currentApiData);
    } else {
      setMonitoringCheckedItems([...monitoringCheckedItems, key]);
      let currentApiData = [...apiResponses];
      currentApiData = currentApiData.filter(
        (subData, index) => subData[0].title == key
      );
      setBaselineAPIDataSet((prevData) => [...prevData, ...currentApiData]);
    }
  };

  const handleCheckBoxChange = (section, key) => {
    if (checkedItems.includes(key)) {
      setCheckedItems(checkedItems.filter((item) => item !== key));
      if (selectedOptionFirstShow === "Monitoring") {
        const currentPromptObj = { ...attributePrompts };
        if (currentPromptObj.hasOwnProperty(key)) {
          delete currentPromptObj[key];
        }
        setAttributePrompts(currentPromptObj);
      }
    } else {
      if (selectedOptionFirstShow === "Monitoring") {
        setCheckedItems([...checkedItems, key]);
        const prompt = monitoringPrompt(
          monitoringPrompts,
          selectedOption,
          [key],
          promptBrandKey,
          promptBrandReach,
          promptData
        );
        setAttributePrompts({ ...attributePrompts, [key]: prompt });
      } else {
        setCheckedItems([...checkedItems, key]);
      }
    }
  };

  const handleCheckBoxChangeReport = (section, key) => {
    if (checkedItemsReport.includes(key)) {
      setCheckedItemsReport(checkedItemsReport.filter((item) => item !== key));
    } else {
      setCheckedItemsReport([...checkedItemsReport, key]);
    }
  };

  const llms = [
    "GPT-4_Prompts",
    "Palm2_Prompts",
    "Llama2_Prompts",
    "GPT4_OpenAI_Prompts",
    "Gemini_Prompts",
    "GPT4_Web_Prompts",
    "MonitoringPrompts",
    "Claude_Prompts",
  ];

  useEffect(() => {
    const fromReporting = localStorage.getItem("fromReporting");
    if (fromReporting === "true") {
      setSelectedOption("Dashboard and Reporting");
      localStorage.removeItem("fromReporting");
    }

    // for (const llm of llms) {
    //   fetchDataFromFirebase((data) => {
    //     if (data !== null) {
    //       const promptsData = Object.values(data);
    //       if (llm === "GPT-4_Prompts") {
    //         setGpt4TurboPrompts(promptsData[0]);
    //       }

    //       if (llm === "Palm2_Prompts") {
    //         setPalm2Prompts(promptsData[0]);
    //       }

    //       if (llm === "Llama2_Prompts") {
    //         setLlama2Prompts(promptsData[0]);
    //       }

    //       if (llm === "Llama3_Prompts") {
    //         setLlama3Prompts(promptsData[0]);
    //       }

    //       if (llm === "GPT4_OpenAI_Prompts") {
    //         setGpt4Prompts(promptsData[0]);
    //       }

    //       if (llm === "Gemini_Prompts") {
    //         setGeminiPrompts(promptsData[0]);
    //       }

    //       if (llm === "GPT4_Web_Prompts") {
    //         setGpt4WebPrompts(promptsData[0]);
    //       }
    //       if (llm === "MonitoringPrompts") {
    //         setMonitoringPrompts(promptsData[0]);
    //       }
    //       if (llm === "Claude_Prompts") {
    //         setClaudePrompts(promptsData[0]);
    //       }
    //     }
    //   }, llm);
    // }
  }, []);

  useEffect(() => {
    const currentDate = new Date().toDateString();
    let usersData;
    let ids;
    // fetchDataFromFirebase((data) => {
    //   if (data !== null) {
    //     const currentUser = Object.keys(data)
    //       .filter((key) => data[key].email === authUserEmail)
    //       .reduce((obj, key) => {
    //         obj[key] = data[key];
    //         return obj;
    //       }, {});

    //     usersData = Object.values(currentUser);
    //     ids = Object.keys(currentUser);
    //     setCurrentUser({ userData: usersData[0], id: ids[0] });
    //     if (
    //       usersData &&
    //       usersData.length !== 0 &&
    //       usersData[0]?.date === currentDate
    //     ) {
    //       setApiCalls(usersData[0]?.count);
    //       setRemainingCalls(apiCallLimitToCurrentUser - usersData[0]?.count);
    //       setLimitReached(usersData[0]?.count >= apiCallLimitToCurrentUser);
    //     } else {
    //       setApiCalls(0);
    //       setRemainingCalls(apiCallLimitToCurrentUser);
    //       updateDataInFirebase("Users", ids[0], {
    //         ...usersData[0],
    //         date: currentDate,
    //         count: 0,
    //       });
    //       setLimitReached(false);
    //     }
    //   }
    // }, "Users");
  }, []);

  const handleApiCall = () => {
    if (apiCalls < apiCallLimitToCurrentUser) {
      const updatedCount = apiCalls + 1;
      updateDataInFirebase("Users", currentUser?.id, {
        ...currentUser?.userData,
        count: updatedCount,
      });

      setApiCalls(updatedCount);
      setRemainingCalls(apiCallLimitToCurrentUser - updatedCount);
      setLimitReached(updatedCount >= apiCallLimitToCurrentUser);
    }
  };

  const handleKeyPromptChange = (e) => {
    setPromptBrandKey(e.target.value);
    const prompt = monitoringPrompt(monitoringPrompts, "", "", e.target.value);
    setAttributePrompts({ "key prompt": prompt });
  };

  const toggleChat = () => {
    setIsChatVisible(true);
  };

  const closeChat = () => {
    setIsChatVisible(false);
  };

  const handleRadioSelection = (option) => {
    const currentOPtions = [...selectedMonitoringOption];
    currentOPtions.push(option);
    setSelectedMonitoringOption(currentOPtions);
    setSelectedOption(option);
    setCheckedItems([]);
    setShowCheckBoxData(false);
    setCompetitors("");
    setPromptBrandReach("");
    setPromptBrandReach1("");
    setDataItem({});
    setBrandIndexScoreDoc(null);
    setUserPositionInQueue(null);
    setShowGetData(false);
    if (unsubscribeFBDocEventsFunc) unsubscribeFBDocEventsFunc();
  };

  const handleRadioSectionShow = (option) => {
    setSelectedOptionShow(option);
    setSelectedOption(null);
    setSelectedMonitoringOption([]);
    setPromptData("");
    setShowCheckBoxData(false);
    setCompetitors("");
    setPromptBrandReach("");
    setPromptBrandReach1("");
    setAgainSelectedItems([]);
    setSelectedItems({});
    setSelectAll(false);
    setSelectedCount(0);
    setPromptBrandKey("");
  };

  const handleRadioSectionSecondShow = (option) => {
    setSelectedOptionSecondShow(option);
  };

  async function generateBrandIndexScore(llm) {
    const payload = {
      llm: llm,
      category: promptBrandReach1,
      return_meta: true,
      count: brandIndexProductCount,
      industry: brandIndexIndustry,
    };

    try {
      const response = await getBrandIndexScore(
        payload,
        new AbortController().signal
      );

      if (response?.error) {
        toast.error(response.error);
        setShowGetData(false);
        throw new Error(response.error);
      }

      const products = response.data;

      if (products.length == 0) {
        toast.warn(
          "No products were found for the specified category, industry, or location."
        );
        setShowGetData(false);
        throw new Error("No products found");
      }

      const combinedArrayofProductsWithScore = products.map((item) => {
        return Object.values(item);
      });

      combinedArrayofProductsWithScore.unshift([
        "Brand/Product",
        "Rank",
        "Favourability",
        "Brand Index Score",
      ]);
      combinedArrayofProductsWithScore.sort(
        (a, b) => b[b.length - 1] - a[a.length - 1]
      );

      const md = createMarkdownTable(combinedArrayofProductsWithScore);
      let mapping = {
        "gpt-4": "GPT-4",
        "gpt-3.5-turbo": "GPT-4",
        "gpt-4o": "GPT-4",
        Gemini: "Gemini",
        Perplexity: "Perplexity",
        llama2_70b_chat: "Llama2",
        llama3: "Llama3",
        Claude: "Claude3",
        "GPT-4_Web": "GPT-4_Web",
      };
      let result = {
        [mapping[llm]]: [md],
      };

      const dataArr = [];

      combinedArrayofProductsWithScore.forEach((item, index) => {
        if (index === 0) return;
        const [brand, rank, favorability, BrandIndexScore] = item;
        dataArr.push({
          name: brand,
          favorability: favorability,
          rank: rank,
          score: BrandIndexScore,
        });
      });

      addDataToFirebase("brand-index", {
        brand_category: promptBrandReach1,
        date: Date.now(),
        email: authUserEmail,
        category: promptBrandReach1.toString().trim(),
        llm,
        data: dataArr,
        extras: response.extras,
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  const processSelectedItems = async () => {
    setDataItem({});
    let finalResults;
    try {
      const promises = againSelectedItems.map(async (selectedItem) => {
        let inputPrompt = "";

        if (selectedItem === "gpt_4_turbo") {
          inputPrompt = promptMessage(gpt4TurboPrompts);
        } else if (selectedItem === "palm2_text") {
          inputPrompt = promptMessage(palm2Prompts);
        } else if (selectedItem === "llama2_70b_chat") {
          inputPrompt = promptMessage(llama2Prompts);
        }

        let result;

        if (
          selectedItem === "gpt-4" ||
          selectedItem === "gpt-3.5-turbo" ||
          selectedItem === "gpt-4o"
        ) {
          const gpt4ApiController = createAbortController();
          try {
            inputPrompt = promptMessage(gpt4Prompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchGpt4API(
                inputPrompt,
                gpt4ApiController,
                3000,
                1,
                selectedItem,
                undefined,
                "Discovery"
              );
            }
            if (selectedItem === "gpt-3.5-turbo") {
              result = {
                "GPT-3.5": result["GPT-4"],
              };
            }
            if (selectedItem === "gpt-4o") {
              result = {
                "GPT-4o": [result["GPT-4"][0].replace(/```md|```/g, "")],
              };
            }
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "Gemini") {
          const geminiApiController = createAbortController();
          try {
            inputPrompt = promptMessage(geminiPrompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchGeminiAPI(
                inputPrompt,
                geminiApiController,
                undefined,
                "Discovery"
              );
            }
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw err;
          }
        } else if (selectedItem === "Perplexity") {
          const perplexityController = createAbortController();
          try {
            inputPrompt = promptMessage(geminiPrompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchPerplexityAPI(
                inputPrompt,
                perplexityController,
                undefined,
                undefined,
                undefined,
                "Discovery"
              );
            }
            result = {
              Perplexity: [
                result.Perplexity[0].replace(/```markdown|```/g, ""),
              ],
            };
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "llama2_70b_chat") {
          const llama2ApiController = createAbortController();
          try {
            inputPrompt = promptMessage(llama2Prompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchLlama2API(
                inputPrompt,
                llama2ApiController,
                undefined,
                "Discovery"
              );
            }
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "llama3") {
          const llama3ApiController = createAbortController();
          try {
            inputPrompt = promptMessage(llama2Prompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchLlama3API(
                inputPrompt,
                llama3ApiController,
                undefined,
                "Discovery"
              );
            }
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "Claude") {
          const claudeApiController = createAbortController();
          try {
            inputPrompt = promptMessage(claudePrompts);
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              result = await fetchClaude3API(
                inputPrompt,
                claudeApiController,
                undefined,
                undefined,
                "Discovery"
              );
            }

            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "GPT-4_Web") {
          const gptWebAbortCtrl = createAbortController();
          try {
            if (selectedOption === "Brand Index Score v2") {
              result = await generateBrandIndexScore(selectedItem);
            } else {
              inputPrompt = promptMessage(geminiPrompts);
              let type = "query";
              const docId = crypto.randomUUID();

              if (selectedOption === "Brand Index Score") {
                inputPrompt = promptBrandReach1;
                type = "brand-index";
              } else if (selectedOption === "Brand Index Score v2") {
                result = await generateBrandIndexScore(selectedItem);
              }

              const { key: id } = addDataToFirebase("queries", {
                prompt: inputPrompt,
                type,
                docId,
              });

              const unsubscribe = await fetchDataFromFirebase(
                updateQueryIndexProgress.bind(null, id),
                "queries",
                authUserEmail
              );
              unsubscribeFBDocEventsFunc = unsubscribe;
            }
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else if (selectedItem === "Google-SGE") {
          const googleSGECtrl = createAbortController();
          try {
            inputPrompt = promptMessage(geminiPrompts);
            result = await fetchFromGoogleSGE(promptBrandKey, googleSGECtrl);
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            throw new Error();
          }
        } else {
          const gooeyApiController = createAbortController();
          try {
            result = await fetchGooeyAPI(
              inputPrompt,
              selectedItem,
              gooeyApiController
            );
            finalResults = { ...finalResults, ...result };
          } catch (err) {
            console.log("error", err);
            throw new Error();
          }
        }
        try {
          setDataItem((prevData) => {
            localStorage.setItem(
              "reportData",
              JSON.stringify({
                checkedItems,
                data: {
                  ...prevData,
                  ...result,
                },
              })
            );
            return {
              ...prevData,
              ...result,
            };
          });
          if (result && Object.keys(result)[0]) {
            toast.success(`${Object.keys(result)[0]} LLM Launch Successfully`);
            setShowGetData(true);
            setCheckedItemStore(checkedItems);
            setEditedData(null);
          } else {
            setShowGetData(false);
          }
        } catch (error) {
          console.log("error occured", error);
          setShowGetData(false);
        }
      });

      //Wait for all promises to resolve
      await Promise.allSettled(promises);
      const currentDate = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate = currentDate.toLocaleDateString("en-US", options);
      const stringifiedDataItem = JSON.stringify(finalResults);
      if (
        stringifiedDataItem &&
        selectedOption &&
        !selectedOption.startsWith("Brand Index")
      )
        addDataToFirebase("FirebaseData", {
          item: selectedOption,
          date: formattedDate,
          data: stringifiedDataItem,
          email: authUserEmail,
          brandOrProduct: promptData,
          category: promptBrandReach || promptBrandReach1,
          customLabel: "",
        });
      if (stringifiedDataItem) setShowGetData(true);
      setShowData(false);
    } catch (err) {
      toast.error("something went wrong, please try again !", {
        autoClose: 700,
      });
      setShowData(false);
      setEditedData(null);
      setShowGetData(false);
    }
  };

  const updateQueryIndexProgress = (id, data) => {
    setShowData(true);
    setShowGetData(false);
    let documents = [];
    for (let key in data) {
      documents.push({
        ...data[key],
        id: key,
      });
    }
    const pendingDocForProcessing = documents.filter((doc) => !doc.response);
    const currentBrandIndexDoc = documents.find((doc) => doc.id === id);

    if (currentBrandIndexDoc) {
      const currentDocPosition = pendingDocForProcessing.findIndex(
        (doc) => doc.id == id
      );
      setBrandIndexScoreDoc(currentBrandIndexDoc);
      if (currentDocPosition > -1) {
        setUserPositionInQueue(currentDocPosition);
      }
    }
  };

  useEffect(() => {
    if (userPositionInQueue > 0) return;
    if (userPositionInQueue === 0 && brandIndexScoreDoc?.response) {
      let result = { "GPT-4_Web": [brandIndexScoreDoc?.response] };
      setShowData(false);
      setShowGetData(true);
      setDataItem((prevData) => {
        return {
          ...prevData,
          ...result,
        };
      });
      if (unsubscribeFBDocEventsFunc) unsubscribeFBDocEventsFunc();
    }
  }, [userPositionInQueue, brandIndexScoreDoc?.response]);

  const promptMessage = (prompts) => {
    let message = prompts?.globalStartPrompt || "";

    if (promptBrandKey.length !== 0) {
      message += `${promptBrandKey}, ${prompts.keyPromptPrefix || ""}`;
      message += ` ${prompts.keyPromptSuffix || ""}`;
      if (checkedItems.includes("Sources")) {
        message += `...${prompts.keyPromptSources || ""}. `;
      }
    } else {
      if (
        selectedOption === "Brand Overview" ||
        selectedOption === "Product Overview"
      ) {
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        message += ` ${prompts?.overviewPromptPrefix || ""}`;
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        if (checkedItems.includes("Brand Description")) {
          message += ` ${prompts?.overviewPrompt?.description || ""}`;
        }
        if (checkedItems.includes("Brand Attributes")) {
          message += ` ${prompts?.overviewPrompt?.attributes || ""}`;
        }
        if (checkedItems.includes("Product Description")) {
          message += ` ${prompts?.overviewPrompt?.productDescription || ""}`;
        }
        if (checkedItems.includes("Product Attributes")) {
          message += ` ${prompts?.overviewPrompt?.productAttributes || ""}`;
        }
        if (checkedItems.includes("Competitive Set")) {
          message += ` ${prompts?.overviewPrompt?.competetiveSet || ""}`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.overviewPrompt?.sources || ""}`;
        }
      }

      if (
        selectedOption === "Brand Favorability" ||
        selectedOption === "Product Favorability"
      ) {
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        message += ` ${prompts?.favorabilityPromptPrefix || ""}`;
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        if (checkedItems.includes("Top 5 Positive and Negative Attributes")) {
          message += ` ${prompts?.favorabilityPrompt?.topAttributes || ""}`;
        }
        if (checkedItems.includes("Competitor Comparison")) {
          message += ` ${
            prompts?.favorabilityPrompt?.competitorComparison || ""
          }`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.favorabilityPrompt?.sources || ""}`;
        }
        if (checkedItems.includes("Top 5 Positive and Negative Statements")) {
          message += ` ${prompts?.favorabilityPrompt?.topStatements || ""}`;
        }
      }

      if (selectedOption === "Category Leadership") {
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        message += ` ${prompts?.leadershipPromptPrefix || ""}`;
        if (checkedItems.includes("Top 5 Brands in Category (choose 1)")) {
          message += ` ${prompts?.leadershipPrompt?.topCategory || ""}`;
        }
        if (checkedItems.includes("Best Brands in Category (choose 1)")) {
          message += ` ${prompts?.leadershipPrompt?.bestCategory || ""}`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.leadershipPrompt?.sources || ""}`;
        }
      }

      if (selectedOption === "Brand Category Dimensions") {
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        message += ` ${prompts?.dimensionsPromptPrefix || ""}`;
        if (
          checkedItems.includes(
            "Frequently recommended buying criteria/considerations"
          )
        ) {
          message += ` ${prompts?.dimensionsPrompt?.recommendedBuying || ""}`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.dimensionsPrompt?.sources || ""}`;
        }
      }

      if (selectedOption === "Product Dimensions") {
        message += ` ${promptBrandReach1 ? promptBrandReach1 : promptData}.`;
        message += ` ${prompts?.productDimensionsPromptPrefix || ""}`;
        if (
          checkedItems.includes(
            "Frequently recommended buying criteria/considerations"
          )
        ) {
          message += ` ${
            prompts?.productDimensionsPrompt?.recommendedBuying || ""
          }`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.productDimensionsPrompt?.sources || ""}`;
        }
      }

      if (selectedOption === "Product Reach") {
        message += ` ${promptBrandReach ? promptBrandReach : promptData}.`;
        message += ` ${prompts?.reachPromptPrefix || ""}`;

        if (checkedItems.includes("Top 5 Products in category (choose 1)")) {
          message += ` ${prompts?.reachPrompt?.topCategory || ""}`;
        }
        if (checkedItems.includes("Best Products in category (choose 1)")) {
          message += ` ${prompts?.reachPrompt?.bestCategory || ""}`;
        }
        if (checkedItems.includes("Sources")) {
          message += ` ${prompts?.reachPrompt?.sources || ""}`;
        }
      }

      if (selectedOption === "Competition") {
        let promptMsg =
          competitors.length !== 0
            ? ` ${promptData} and ${competitors}.`
            : ` ${promptData}.`;
        let promptMsg2 =
          competitors.length !== 0
            ? ` ${prompts?.competitionPrompt?.competitors || ""}`
            : ` ${promptData} in the first column.`;
        message += promptMsg;
        message += ` ${prompts?.competitionPromptPrefix || ""}`;
        // message += promptMsg
        message += promptMsg2;

        if (checkedItems.includes("Brand Description")) {
          message += ` ${prompts?.competitionPrompt?.description || ""}`;
        }
        if (checkedItems.includes("Top 5 Positive and Negative Attributes")) {
          message += ` ${prompts?.competitionPrompt?.topAttributes || ""}`;
        }
        if (checkedItems.includes("Top Positive and Negative Facts")) {
          message += ` ${prompts?.competitionPrompt?.facts || ""}`;
        }
      }
    }

    message += ` ${prompts?.globalEndPrompt || ""}`;

    return message.replace(/\s+/g, " ").trim();
  };

  const synthesizedResponseResult = async (results) => {
    const resultsWithQuotes = results.map((result) => `'${result}'`);
    const inputPrompt = `${monitoringPrompts?.synthesizationPrompt}==> multiple API responses in markdown raw strings are: ${resultsWithQuotes}`;
    const geminiApiController = createAbortController();
    let result = await fetchGeminiAPI(
      inputPrompt,
      geminiApiController,
      undefined,
      "Discovery"
    );
    return result;
  };

  const processIncrementalAPIs = async (
    count,
    llm,
    initialPrompt,
    promptkey
  ) => {
    let apiResults = [];
    if (llm === "Gemini") {
      for (var i = 1; i <= count; i++) {
        if (i == 1) {
          let result = await fetchGeminiAPI(
            initialPrompt,
            undefined,
            undefined,
            "Discovery"
          );
          result = result["Gemini"][0];
          apiResults.push(result);
        } else {
          let newPrompt = `${initialPrompt}..I have below set of information with me about this brand/product: ${apiResults}...so, please provide unique information that is not present in the provided dataset.`;
          let result = await fetchGeminiAPI(
            newPrompt,
            undefined,
            undefined,
            "Discovery"
          );
          result = result["Gemini"][0];
          apiResults.push(result);
        }
      }
    }

    if (llm === "gpt-4") {
      for (var i = 1; i <= count; i++) {
        if (i == 1) {
          let result = await fetchGpt4API(
            initialPrompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Frontend Monitoring"
          );
          result = result["GPT-4"][0];
          apiResults.push(result);
        } else {
          let newPrompt = `${initialPrompt}..I have below set of information with me about this brand/product: ${apiResults}...so, please provide unique information that is not present in the provided dataset.`;
          let result = await fetchGpt4API(
            newPrompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Frontend Monitoring"
          );
          result = result["GPT-4"][0];
          apiResults.push(result);
        }
      }
    }

    const resultsWithQuotes = apiResults.map((result) => `'${result}'`);
    let lastAPIResult;

    if (llm == "gpt-4") {
      lastAPIResult = await fetchGpt4API(
        initialPrompt,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      lastAPIResult = lastAPIResult["GPT-4"][0];
    } else {
      lastAPIResult = await fetchGeminiAPI(
        initialPrompt,
        undefined,
        undefined,
        "Discovery"
      );
      lastAPIResult = lastAPIResult["Gemini"][0];
    }

    const daviationPrompt = `${monitoringPrompts?.dataDaviationPrompt} previous responses are ${resultsWithQuotes} and recent response is ${lastAPIResult}`;
    let deviationResult = await fetchGpt4API(
      daviationPrompt,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "Frontend Monitoring"
    );
    deviationResult = deviationResult["GPT-4"][0];

    let allResponses = [...apiResults, lastAPIResult];
    let testResponses = [
      {
        title: promptkey,
        data: allResponses,
        deviationResponse: deviationResult,
        query: initialPrompt,
      },
    ];
    setShowModal(true);
    setApiResponses((prevData) => [...prevData, testResponses]);
  };

  const signalStrengthAnalysis = async (results, attribute, newId) => {
    try {
      let result = await generateUniqueDataForAnalysis(
        results,
        attribute,
        newId
      );
      let signalStrengthObj;
      if (
        attribute === "Top 5 Brands in Category" ||
        attribute === "Top 5 Products in Category"
      ) {
        signalStrengthObj = generateRankingObject(result);
      } else {
        signalStrengthObj = calculateStrength(result);
      }

      return signalStrengthObj;
    } catch (err) {
      deleteDataFromFirebase(newId, "MonitoringData");
      toast.error("something went wrong in APIs!", {
        autoClose: 2000,
      });
    }
  };

  const signalStrengthAnalysisForSources = async (
    results,
    attribute,
    newId
  ) => {
    try {
      let result = await generateUniqueSourceDataForAnalysis(
        results,
        attribute,
        newId
      );
      let signalStrengthObj = calculateStrength(result);
      return signalStrengthObj;
    } catch (err) {
      deleteDataFromFirebase(newId, "MonitoringData");
      toast.error("something went wrong in APIs!", {
        autoClose: 2000,
      });
    }
  };

  const generateUniqueSourceDataForAnalysis = async (
    results,
    attribute,
    newId
  ) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${SOURCES_PROMPT}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Discovery"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${SOURCES_CONSOLIDATION_PROMPT}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      deleteDataFromFirebase(newId, "MonitoringData");
      throw new Error(err);
    }
  };

  const generateUniqueDataForAnalysis = async (results, attribute, newId) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${ATTRIBUTES_ANALYSIS_PROMPT[attribute]}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Discovery"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${ATTRIBUTES_OPTIMIZATION_PROMPT}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      deleteDataFromFirebase(newId, "MonitoringData");
      throw new Error(err);
    }
  };

  const processIndependantAPIs = async (prompt, count, llm, promptkey) => {
    let apiCountArray = [];

    for (var i = 1; i <= count; i++) {
      apiCountArray.push(i);
    }

    if (llm === "gpt-4") {
      apiCountArray = apiCountArray.map((number) => number + 10);
    }

    try {
      const promises = apiCountArray.map(async (count) => {
        let result;

        if (count <= 10) {
          const geminiApiController = createAbortController();

          result = await fetchGeminiAPI(
            prompt,
            geminiApiController,
            undefined,
            "Discovery"
          );
        } else {
          const gpt4ApiController = createAbortController();

          result = await fetchGpt4API(
            prompt,
            gpt4ApiController,
            undefined,
            undefined,
            undefined,
            undefined,
            "Frontend Monitoring"
          );
        }

        if (result.hasOwnProperty("Gemini")) {
          result = result["Gemini"][0];
        } else {
          result = result["GPT-4"][0];
        }
        return result;
      });

      // responses from multiple api calls
      const allResults = await Promise.all(promises);
      const resultsWithQuotes = allResults.map((result) => `'${result}'`);
      let lastAPIResult;

      if (llm == "gpt-4") {
        const gpt4ApiController = createAbortController();

        lastAPIResult = await fetchGpt4API(
          prompt,
          gpt4ApiController,
          undefined,
          undefined,
          undefined,
          undefined,
          "Frontend Monitoring"
        );
        lastAPIResult = lastAPIResult["GPT-4"][0];
      } else {
        const geminiApiController = createAbortController();

        lastAPIResult = await fetchGeminiAPI(
          prompt,
          geminiApiController,
          undefined,
          "Discovery"
        );
        lastAPIResult = lastAPIResult["Gemini"][0];
      }

      const daviationPrompt = `${monitoringPrompts?.dataDaviationPrompt} previous responses are ${resultsWithQuotes} and recent response is ${lastAPIResult}`;

      let deviationResult = await fetchGpt4API(
        daviationPrompt,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      deviationResult = deviationResult["GPT-4"][0];

      let allResponses = [...allResults, lastAPIResult];
      let testResponses = [
        {
          title: promptkey,
          data: allResponses,
          deviationResponse: deviationResult,
          query: prompt,
        },
      ];

      setShowModal(true);
      setApiResponses((prevData) => [...prevData, testResponses]);
    } catch (err) {}
  };

  const processDeviationAPIS = async (promptkey, promptValue, llm) => {
    try {
      setShowData(true);
      if (promptRunApproach === "Independant") {
        await processIndependantAPIs(promptValue, apiCount, llm, promptkey);
      } else {
        await processIncrementalAPIs(apiCount, llm, promptValue, promptkey);
      }
      setShowData(false);
    } catch (err) {
      setShowData(false);
    }
  };

  const processAllBaselineData = async (llm) => {
    try {
      const attributeMonitorings = baselineAPIDataSet.map(
        (attributesDataSet) => {
          processBaselineData(
            llm,
            attributesDataSet[0].data,
            attributesDataSet[0].title,
            attributesDataSet[0].query
          );
        }
      );
      await Promise.allSettled(attributeMonitorings);
    } catch (err) {
      toast.error("something went wrong !", {
        autoClose: 2000,
      });
    }
  };

  const processBaselineData = async (llm, dataSet, focusAttribute, query) => {
    const data = {
      prompt: query,
      status: "In-Progress",
      monitoringFrequency: selectedOptionSecondShow,
      monitoringData: null,
      email: authUserEmail,
      baseOfComparison: "Historical",
      brandOrProductToMonitor: promptData || promptBrandReach || promptBrandKey,
      llm: llm === "gpt-4" ? "GPT-4" : "Gemini",
      focussingArea: `${selectedOption || ""} - ${focusAttribute}` || "",
      apiCount: apiCount,
      focusAttribute: focusAttribute,
    };

    let newId;
    try {
      const newRef = await addDataToFirebase(
        "MonitoringData",
        data,
        selectedOptionSecondShow
      );
      newId = newRef.key;
      navigate("/monitors?tab=1");

      // let synthesizedResponse = await synthesizedResponseResult(dataSet);
      // synthesizedResponse = synthesizedResponse["Gemini"][0];

      // generate baseline data in tabular format and save into DB
      // const tablePrompt = `${monitoringPrompts?.baselineTablePrompt}.here is raw markdown string '${synthesizedResponse}'.`;
      // const geminiApiController = createAbortController();

      let scheduledUpdate;
      if (data?.monitoringFrequency === "Weekly") {
        scheduledUpdate = Date.now() + 7 * 24 * 60 * 60 * 1000;
      } else if (data?.monitoringFrequency === "Daily") {
        scheduledUpdate = Date.now() + 1 * 24 * 60 * 60 * 1000; // Date.now() + 5 * 60 * 1000;
      } else if (data?.monitoringFrequency === "Monthly") {
        scheduledUpdate = Date.now() + 30 * 24 * 60 * 60 * 1000;
      }
      try {
        // let result = await fetchGpt4API(tablePrompt, geminiApiController);
        let visualizationData = await signalStrengthAnalysis(
          dataSet,
          focusAttribute,
          newId
        );

        let visualizationDataForSources =
          await signalStrengthAnalysisForSources(
            dataSet,
            focusAttribute,
            newId
          );
        // result = result["GPT-4"][0];
        const dataToUpdate = data;
        dataToUpdate.status = "Running";
        // dataToUpdate.baselineData = result;
        dataToUpdate.lastMonitored = displayDateTime();
        // dataToUpdate.baselineSynthesized = synthesizedResponse;
        dataToUpdate.scheduledUpdate = scheduledUpdate;
        dataToUpdate.allApiResults = dataSet;
        dataToUpdate.visualizationData = JSON5.stringify([visualizationData]);
        dataToUpdate.visualizationDataForSources = JSON5.stringify([
          visualizationDataForSources,
        ]);
        updateDataInFirebase("MonitoringData", newId, dataToUpdate);
      } catch (err) {
        deleteDataFromFirebase(newId, "MonitoringData");
        throw new Error(err);
      }
    } catch (err) {
      deleteDataFromFirebase(newId, "MonitoringData");
      throw new Error(err);
    }
  };

  const handleInputValidation = () => {
    let errorMessage = "";

    if (selectedOptionShow === "Key Prompt") {
      if (!promptBrandKey) {
        errorMessage = "Please Enter a key Prompt !";
      } else if (againSelectedItems.length == 0) {
        errorMessage = "Please select at least one LLM";
      } else if (apiCount == 0) {
        errorMessage = "Prompt run count should be greater than 0";
      }
      toast.error(errorMessage, {
        autoClose: 2000,
      });
      return;
    }

    if (selectedOptionFirstShow === "Monitoring") {
      if (!promptData && !promptBrandReach) {
        errorMessage = "Please Enter Brand/Product/category";
      } else if (!selectedOption) {
        errorMessage = "Please select the area you want to focus on!";
      } else if (checkedItems.length == 0) {
        errorMessage = "Please select a valid Attribute to focus on!";
      } else if (!selectedOptionSecondShow) {
        errorMessage = "Please select a valid Frequency!";
      } else if (againSelectedItems.length == 0) {
        errorMessage = "Please select at least one LLM!";
      } else if (apiCount == 0) {
        errorMessage = "Prompt run count should be greater than 0";
      }
    } else if (
      selectedOptionFirstShow === "Discover" &&
      selectedOption !== "Brand Index Score" &&
      selectedOption !== "Brand Index Score v2"
    ) {
      if (!promptData) {
        errorMessage = "Please Enter Brand/Product";
      } else if (!selectedOption) {
        errorMessage = "Please select the area you want to focus on!";
      } else if (checkedItems.length == 0) {
        errorMessage = "Please select a valid Attribute to focus on!";
      } else if (againSelectedItems.length == 0) {
        errorMessage = "Please select at least one LLM!";
      } else if (competitors.length == 0) {
        errorMessage = "Please select at least one competitor!";
      }
    } else if (
      selectedOptionFirstShow === "Discover" &&
      (selectedOption === "Brand Index Score" ||
        selectedOption === "Brand Index Score v2")
    ) {
      if (!promptBrandReach1) {
        errorMessage = "Please enter Brand Category";
      } else if (againSelectedItems.length == 0) {
        errorMessage = "Please select at least one LLM!";
      }
    }
    toast.error(errorMessage, {
      autoClose: 2000,
    });
  };

  const handleClickShow = async () => {
    setEditedData(null);
    setShowHistory(false);

    let isBrandOrCategorySelection = false;
    let competitorValidation =
      selectedOption === "Competition" ? competitors.length !== 0 : true;
    if (
      (selectedOption === "Product Reach" ||
        selectedOption === "Category Leadership" ||
        selectedOption === "Brand Category Dimensions" ||
        selectedOption === "Product Dimensions" ||
        selectedOption === "Sources" ||
        selectedOption === "Category Dimensions") &&
      (promptBrandReach || promptBrandReach1)
    ) {
      isBrandOrCategorySelection = true;
    } else {
      isBrandOrCategorySelection = promptData.length != 0;
    }

    if (selectedOptionFirstShow == "Monitoring") {
      if (
        (isBrandOrCategorySelection &&
          checkedItems.length != 0 &&
          // promptData.length != 0 &&
          // selectedOption !== null &&
          againSelectedItems != 0 &&
          selectedOptionSecondShow != 0 &&
          // selectedOptionthirdShow &&
          apiCount !== 0) ||
        (promptBrandKey.length != 0 &&
          againSelectedItems != 0 &&
          selectedOptionSecondShow != 0 &&
          // selectedOptionthirdShow &&
          apiCount !== 0)
      ) {
        handleApiCall();
        const attributeMonitorings = Object.entries(attributePrompts).map(
          (attributes) => {
            processDeviationAPIS(
              attributes[0],
              attributes[1],
              againSelectedItems[0]
            );
          }
        );
        await Promise.allSettled(attributeMonitorings);
        setApiResponses([]);
      } else {
        handleInputValidation();
      }
    } else {
      if (
        (isBrandOrCategorySelection &&
          checkedItems.length != 0 &&
          againSelectedItems != 0 &&
          competitorValidation) ||
        (promptBrandKey.length != 0 && againSelectedItems != 0) ||
        ((selectedOption == "Brand Index Score" ||
          selectedOption == "Brand Index Score v2") &&
          promptBrandReach1 &&
          againSelectedItems != 0)
      ) {
        setShowData(true);
        setShowGetData(false);
        handleApiCall();

        processSelectedItems();
      } else {
        handleInputValidation();
      }
    }
  };

  const handleClickReset = () => {
    resetAbortControllers();
    setShowGetData(false);
    setPromptData("");
    setDataItem({});
    setEditedData(null);
    setSelectedItems({});
    setAgainSelectedItems([]);
    setSelectAll(false);
    setSelectedCount(0);
    setShowCheckBoxData(false);
    setCompetitors("");
    setPromptBrandKey("");
  };

  const handleCheckBoxData = (event) => {
    setShowCheckBoxData(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newMessage = inputValue.trim();

    if (newMessage) {
      setMessages([...messages, { text: newMessage, sender: "user" }]);
      setInputValue("");

      setTimeout(() => {
        setMessages([...messages, { text: "Sample response", sender: "bot" }]);
      }, 1000);
    }
  };

  const messageList = messages.map((message, index) => (
    <div key={index} className={`Message ${message.sender}`}>
      {message.text}
    </div>
  ));

  const handleCompetitorChange = (index, e) => {
    let updatedCompetitors = [...competitors];
    updatedCompetitors[index] = e.target.value;
    const allEmptyStrings = updatedCompetitors.every(
      (item) => item.trim() === ""
    );
    if (allEmptyStrings) {
      setCompetitors([]);
    } else {
      setCompetitors(updatedCompetitors);
    }
  };
  const handlePromptBrandReachChange = (e) => {
    setPromptBrandReach(e.target.value);
  };

  const handlePromptBrandReachChange1 = (e) => {
    setPromptBrandReach1(e.target.value);
  };

  return (
    <div>
      <Container fluid>
        <ConfirmToProceedModal
          allAPIResponses={apiResponses}
          daviationMessage={daviationMessage}
          show={showModal}
          handleClose={handleCloseModal}
          processBaselineData={processAllBaselineData}
          llm={againSelectedItems[0]}
          handleCheckBoxChange={handleMonitoringSetupCheckboxChange}
          monitoringCheckedItems={monitoringCheckedItems}
        />
        <Container className="border border-secondary-subtle borderSet mt">
          <h4 className="float-start text1">Find Your LLM Benchmark</h4>
          <div className="p-3  mt-5">
            <Container className="back">
              <Form className="form-inline form-5yquicksearch mx-auto mt-2 p-3">
                {selectedOptionFirstShow === "Discover" && (
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Row>
                        <ul className="nav brand-tabs">
                          <Col md="auto">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Company/Brand"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Company/Brand")
                                }
                              >
                                <span></span> Company/Brand
                              </a>
                            </li>
                          </Col>
                          <Col md="auto">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Company/Product"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Company/Product")
                                }
                              >
                                <span></span> Company/Product
                              </a>
                            </li>
                          </Col>
                          <Col md="2">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Key Prompt"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Key Prompt")
                                }
                              >
                                <span></span> Key Prompt
                              </a>
                            </li>
                          </Col>
                        </ul>
                      </Row>
                    </Form.Group>

                    <h6 className="float-start text mb-4 mt-4">
                      What Would You Like to Focus On?
                    </h6>
                    {selectedOptionShow === "Company/Brand" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            style={{ marginBottom: "16px" }}
                            as="textarea"
                            rows={1}
                            cols={2}
                            name="firstName"
                            placeholder="Company/Brand (input)"
                            className="big custom-placeholder mt-2"
                            value={promptData}
                            onChange={(e) => setPromptData(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )}

                    {selectedOptionShow === "Company/Product" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            as="textarea"
                            style={{ marginBottom: "16px" }}
                            rows={1}
                            cols={2}
                            type="text"
                            name="firstName"
                            placeholder="Product (input)"
                            className="big custom-placeholder mt-2"
                            value={promptData}
                            onChange={(e) => setPromptData(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )}
                    {selectedOptionShow === "Company/Brand" && (
                      <BrandRadioOptions
                        selectedOption={selectedOption}
                        handleRadioSelection={handleRadioSelection}
                      />
                    )}
                    {selectedOptionShow === "Company/Product" && (
                      <ProductRadioOptions
                        promptData={promptData}
                        setPromptData={setPromptData}
                        selectedOption={selectedOption}
                        handleRadioSelection={handleRadioSelection}
                      />
                    )}
                    {selectedOptionShow === "Key Prompt" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            cols={2}
                            name="firstName"
                            placeholder="Enter the Key Prompt"
                            className="big custom-placeholder mb-3"
                            value={promptBrandKey}
                            onChange={(e) => handleKeyPromptChange(e)}
                          />
                        </Form.Group>
                        <Container className="mb-3">
                          <Card as={Col} md="12" className="border-0 whi">
                            <Card.Body>
                              <Card.Title className="">
                                Key Prompt Sources
                              </Card.Title>
                              <Card.Text className="mt-4">
                                Do you want to know the sources for this
                                Information? (select to apply)
                              </Card.Text>
                              <ul className="focus-on">
                                {["Sources"].map((key) => (
                                  <li key={key}>
                                    <input
                                      type="checkbox"
                                      name=""
                                      value=""
                                      checked={checkedItems.includes(key)}
                                      onChange={() =>
                                        handleCheckBoxChange(
                                          "Brand Representation",
                                          key
                                        )
                                      }
                                    />{" "}
                                    {key}
                                  </li>
                                ))}
                              </ul>
                              <small></small>
                            </Card.Body>
                          </Card>
                        </Container>
                      </>
                    )}
                    {selectedOption === "Brand Overview" && (
                      <OverviewComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}

                    {selectedOption === "Brand Favorability" && (
                      <FavorabilityComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}

                    {selectedOption === "Brand Category Dimensions" &&
                      selectedOptionShow === "Company/Brand" && (
                        <CategoryDimensions
                          checkedItems={checkedItems}
                          handleCheckBoxChange={handleCheckBoxChange}
                          handlePromptBrandReachChange1={
                            handlePromptBrandReachChange1
                          }
                          promptBrandReach1={promptBrandReach1}
                        />
                      )}

                    {selectedOption === "Brand Index Score v2" &&
                      selectedOptionShow === "Company/Brand" && (
                        <IndexScore
                          checkedItems={checkedItems}
                          handleCheckBoxChange={handleCheckBoxChange}
                          handlePromptBrandReachChange1={
                            handlePromptBrandReachChange1
                          }
                          promptBrandReach1={promptBrandReach1}
                          onProductCountChange={(val) =>
                            setBrandIndexProductCount(val)
                          }
                          productCount={brandIndexProductCount}
                          industry={brandIndexIndustry}
                          onChangeIndustry={(val) => setBrandIndexIndustry(val)}
                        />
                      )}

                    {selectedOption === "Category Leadership" &&
                      selectedOptionFirstShow === "Discover" && (
                        <ReachComponent
                          promptBrandReach1={promptBrandReach1}
                          checkedItems={checkedItems}
                          handlePromptBrandReachChange1={
                            handlePromptBrandReachChange1
                          }
                          handleCheckBoxChange={handleCheckBoxChange}
                        />
                      )}

                    {selectedOption === "Product Overview" && (
                      <POverviewComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}

                    {selectedOption === "Competition" &&
                      selectedOptionShow === "Company/Brand" && (
                        <BrandCompetition
                          showCheckBoxData={showCheckBoxData}
                          competitors={competitors}
                          checkedItems={checkedItems}
                          handleCheckBoxData={handleCheckBoxData}
                          handleCompetitorChange={handleCompetitorChange}
                          handleCheckBoxChange={handleCheckBoxChange}
                        />
                      )}

                    {selectedOption === "Product Dimensions" && (
                      <RepresentationComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                        handlePromptBrandReachChange1={
                          handlePromptBrandReachChange1
                        }
                        promptBrandReach1={promptBrandReach1}
                      />
                    )}

                    {selectedOption === "Product Favorability" && (
                      <PFavorabilityComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}

                    {selectedOption === "Product Reach" && (
                      <PReachComponent
                        promptBrandReach={promptBrandReach}
                        checkedItems={checkedItems}
                        handlePromptBrandReachChange={
                          handlePromptBrandReachChange
                        }
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}

                    {selectedOption === "Competition" &&
                      selectedOptionShow === "Company/Product" && (
                        <ProductCompetition
                          showCheckBoxData={showCheckBoxData}
                          competitors={competitors}
                          checkedItems={checkedItems}
                          handleCheckBoxData={handleCheckBoxData}
                          handleCompetitorChange={handleCompetitorChange}
                          handleCheckBoxChange={handleCheckBoxChange}
                        />
                      )}

                    {selectedOption === "Dashboard and Reporting" && (
                      <ReportingComponent
                        checkedItemsReport={checkedItemsReport}
                        handleCheckBoxChangeReport={handleCheckBoxChangeReport}
                        showGetData={showGetData}
                        dataItem={dataItem}
                        againSelectedItems={againSelectedItems}
                        checkedItems={checkedItems}
                        checkedItemStore={checkedItemStore}
                        gpt4TurboPrompts={gpt4TurboPrompts}
                        llama2Prompts={llama2Prompts}
                        llama3Prompts={llama3Prompts}
                        palm2Prompts={palm2Prompts}
                        gpt4Prompts={gpt4Prompts}
                        geminiPrompts={geminiPrompts}
                        claudePrompts={claudePrompts}
                        promptData={promptData}
                      />
                    )}

                    {selectedOption != "Dashboard and Reporting" && (
                      <LLMSelectionComponent
                        limitReached={limitReached}
                        remainingCalls={remainingCalls}
                        selectedCount={selectedCount}
                        apiCallLimitToCurrentUser={apiCallLimitToCurrentUser}
                        selectedItems={selectedItems}
                        selectAll={selectAll}
                        options={options}
                        showData={showData}
                        handleSelectAllChange={handleSelectAllChange}
                        handleCheckChange={handleCheckChange}
                        handleClickShow={handleClickShow}
                        handleClickReset={handleClickReset}
                        selectedOption={selectedOption}
                        selectedOptionFirstShow={selectedOptionFirstShow}
                        isCurrentUserAdmin={isCurrentUserAdmin}
                      />
                    )}
                  </Row>
                )}
                {selectedOptionFirstShow === "Monitoring" && (
                  <Row className="mb-3">
                    <h5 className="float-start text-lg mt-4">
                      Monitoring Elements (using historical as basis)
                    </h5>
                    <Form.Group as={Col} md="12">
                      <Row>
                        <ul className="nav brand-tabs">
                          <Col md="auto">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Company/Brand"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Company/Brand")
                                }
                              >
                                <span></span> Company/Brand
                              </a>
                            </li>
                          </Col>
                          <Col md="auto">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Company/Product"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Company/Product")
                                }
                              >
                                <span></span> Company/Product
                              </a>
                            </li>
                          </Col>
                          <Col md="2">
                            <li style={{ cursor: "pointer" }}>
                              <a
                                className={`nav-link ${
                                  selectedOptionShow === "Key Prompt"
                                    ? "active cursor-pointer"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleRadioSectionShow("Key Prompt")
                                }
                              >
                                <span></span> Key Prompt
                              </a>
                            </li>
                          </Col>
                        </ul>
                      </Row>
                    </Form.Group>
                    {selectedOptionShow != "Key Prompt" && (
                      <h6 className="float-start text-lg mt-4">
                        What Would You Like to Monitor?
                      </h6>
                    )}
                    {selectedOptionShow === "Company/Brand" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            cols={2}
                            name="firstName"
                            placeholder="Company/Brand/Product (input)"
                            className="big custom-placeholder mt-2"
                            value={promptData}
                            onChange={(e) => setPromptData(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )}
                    {selectedOptionShow === "Company/Product" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            cols={2}
                            type="text"
                            name="firstName"
                            placeholder="Product (input)"
                            className="big custom-placeholder mt-2"
                            value={promptData}
                            onChange={(e) => setPromptData(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )}
                    {selectedOptionShow === "Key Prompt" && (
                      <>
                        <Form.Group as={Col} md="12">
                          <Form.Control
                            as="textarea"
                            rows={1}
                            cols={2}
                            name="firstName"
                            placeholder="Enter the Key Prompt"
                            className="big custom-placeholder"
                            value={promptBrandKey}
                            onChange={(e) => handleKeyPromptChange(e)}
                          />
                        </Form.Group>
                      </>
                    )}
                    <p> </p>
                    {selectedOptionShow === "Company/Brand" && (
                      <>
                        <MBrandRadioOptions
                          selectedOption={selectedOption}
                          selectedMonitoringOption={selectedMonitoringOption}
                          handleRadioSelection={handleRadioSelection}
                        />
                      </>
                    )}
                    {selectedOptionShow === "Company/Product" && (
                      <MProductRadioOptions
                        promptData={promptData}
                        setPromptData={setPromptData}
                        selectedOption={selectedOption}
                        selectedMonitoringOption={selectedMonitoringOption}
                        handleRadioSelection={handleRadioSelection}
                      />
                    )}
                    {(selectedOption === "Brand Overview and Favorability" ||
                      selectedOption ===
                        "Product Overview and Favorability") && (
                      <MOverviewComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                        isBrand={
                          selectedOption === "Brand Overview and Favorability"
                        }
                      />
                    )}

                    {selectedOption === "Sources" && (
                      <MFavorabilityComponent
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                        isBrand={selectedOptionShow === "Company/Brand"}
                        handlePromptBrandReachChange={
                          handlePromptBrandReachChange
                        }
                        promptBrandReach={promptBrandReach}
                      />
                    )}
                    {selectedOption === "Category Dimensions" && (
                      <MCategoryDimensions
                        checkedItems={checkedItems}
                        handleCheckBoxChange={handleCheckBoxChange}
                        handlePromptBrandReachChange={
                          handlePromptBrandReachChange
                        }
                        promptBrandReach={promptBrandReach}
                        isBrand={selectedOptionShow === "Company/Brand"}
                      />
                    )}

                    {selectedOption === "Category Leadership" &&
                      selectedOptionFirstShow === "Monitoring" && (
                        <MReachComponent
                          promptBrandReach={promptBrandReach}
                          checkedItems={checkedItems}
                          handlePromptBrandReachChange={
                            handlePromptBrandReachChange
                          }
                          handleCheckBoxChange={handleCheckBoxChange}
                          isBrand={selectedOptionShow === "Company/Brand"}
                        />
                      )}

                    {selectedOption === "Competition" && (
                      <MBrandCompetition
                        showCheckBoxData={showCheckBoxData}
                        competitors={competitors}
                        checkedItems={checkedItems}
                        handleCheckBoxData={handleCheckBoxData}
                        handleCompetitorChange={handleCompetitorChange}
                        handleCheckBoxChange={handleCheckBoxChange}
                      />
                    )}
                    {selectedOption === "Dashboard and Reporting" &&
                      selectedOptionShow === "Company/Product" && (
                        <ReportingComponent
                          checkedItemsReport={checkedItemsReport}
                          handleCheckBoxChangeReport={
                            handleCheckBoxChangeReport
                          }
                          showGetData={showGetData}
                          dataItem={dataItem}
                          againSelectedItems={againSelectedItems}
                          checkedItems={checkedItems}
                          checkedItemStore={checkedItemStore}
                        />
                      )}
                    <>
                      <h6 className="float-start mt-4">
                        What Frequency Would You Like to Monitor?
                      </h6>
                      <Form.Group as={Col} md="8" className="topData mb-3">
                        <Row>
                          <ul className="nav brand-tabs">
                            <Col md="2">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className={`nav-link ${
                                    selectedOptionSecondShow === "Daily"
                                      ? "active cursor-pointer"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleRadioSectionSecondShow("Daily")
                                  }
                                >
                                  <span></span> Daily
                                </a>
                              </li>
                            </Col>
                            <Col md="2">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className={`nav-link ${
                                    selectedOptionSecondShow === "Weekly"
                                      ? "active cursor-pointer"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleRadioSectionSecondShow("Weekly")
                                  }
                                >
                                  <span></span> Weekly
                                </a>
                              </li>
                            </Col>
                            <Col md="2">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className={`nav-link ${
                                    selectedOptionSecondShow === "Monthly"
                                      ? "active cursor-pointer"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleRadioSectionSecondShow("Monthly")
                                  }
                                >
                                  <span></span> Monthly
                                </a>
                              </li>
                            </Col>
                          </ul>
                        </Row>
                      </Form.Group>
                    </>
                    <>
                      <div style={{ marginTop: "-10px", marginBottom: "5px" }}>
                        <h6> How many times do you want to run the Prompt?</h6>
                        <Form.Group as={Col} md="4">
                          <Form.Control
                            as="input"
                            type="number"
                            name="firstName"
                            placeholder="Enter the number of prompt runs"
                            value={apiCount}
                            onChange={(e) => setApiCount(e.target.value)}
                          />
                        </Form.Group>
                      </div>

                      <h6 className="float-start mt-4">
                        How Would You Like to Run Prompts?
                      </h6>
                      <Form.Group as={Col} md="10" className="topData mb-3">
                        <Row>
                          <ul className="nav brand-tabs">
                            <Col md="2">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className={`nav-link ${
                                    promptRunApproach === "Independant"
                                      ? "active cursor-pointer"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    setPromptRunApproach("Independant")
                                  }
                                >
                                  <span></span> Independently
                                </a>
                              </li>
                            </Col>
                            <Col md="2">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className={`nav-link ${
                                    promptRunApproach === "Incremental"
                                      ? "active cursor-pointer"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    setPromptRunApproach("Incremental")
                                  }
                                >
                                  <span></span> Incrementally
                                </a>
                              </li>
                            </Col>
                          </ul>
                        </Row>
                      </Form.Group>
                    </>
                    {selectedOption != "Dashboard and Reporting" && (
                      <LLMSelectionComponent
                        limitReached={limitReached}
                        remainingCalls={remainingCalls}
                        selectedCount={selectedCount}
                        apiCallLimitToCurrentUser={apiCallLimitToCurrentUser}
                        selectedItems={selectedItems}
                        selectAll={selectAll}
                        options={monitoringLLMOptions}
                        showData={showData}
                        handleSelectAllChange={handleSelectAllChange}
                        handleCheckChange={handleCheckChange}
                        handleClickShow={handleClickShow}
                        handleClickReset={handleClickReset}
                        selectedOptionFirstShow={selectedOptionFirstShow}
                        isCurrentUserAdmin={isCurrentUserAdmin}
                      />
                    )}
                  </Row>
                )}
                {userPositionInQueue > 0 && (
                  <UserQueue position={userPositionInQueue} />
                )}
              </Form>
            </Container>
            {selectedOptionFirstShow === "Discover" && (
              <LLMDataDisplayComponent
                brandIndexScoreDoc={brandIndexScoreDoc}
                dataItem={dataItem}
                copyToClipboard={copyToClipboard}
                selectedOptionFirstShow={selectedOptionFirstShow}
                showGetData={showGetData}
                setShowGetData={setShowGetData}
                promptData={promptData}
                selectedOption={selectedOption}
                handleEditData={handleEditData}
                editedData={editedData}
                showData={showData}
                llm={againSelectedItems}
                userPositionInQueue={userPositionInQueue}
                brandIndexStep={brandIndexStep}
              />
            )}
          </div>
        </Container>
        <ChatComponent
          isChatVisible={isChatVisible}
          toggleChat={toggleChat}
          closeChat={closeChat}
          messageList={messageList}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSubmit={handleSubmit}
        />
      </Container>
      <ToastContainer />
    </div>
  );
}
export default layout(MainPage, false);
