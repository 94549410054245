import api from "../../BrandOnBoarding/api";
import {
  ADD_USER_FAILED,
  ADD_USER_STARTED,
  ADD_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_STARTED,
  DELETE_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_USER_STARTED,
  SET_USERS,
  UPDATE_USER_FAILED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
} from "../constants/actionTypes";

export const getUsers = (customerId) => async (dispatch) => {
  dispatch({ type: FETCH_USER_STARTED });
  try {
    let url = `/users`;
    if (customerId) {
      url += `?customer_id=${customerId}`;
    }
    const response = await api.get(url);
    if (response.status === 200)
      dispatch({
        type: SET_USERS,
        payload: response.data.data
          .filter((user) => user.user_role.role_id != 1)
          .sort((a, b) => a.id - b.id),
      });
    else dispatch({ type: FETCH_USER_FAILED, payload: "something went wrong" });
  } catch (error) {
    dispatch({ type: FETCH_USER_FAILED, payload: error.message });
  }
};

export const deleteUser = (user, id) => async (dispatch) => {
  dispatch({ type: DELETE_USER_STARTED });
  try {
    const response = await api.delete(`/users/${id}`);
    if (response.status === 200) {
      dispatch({ type: DELETE_USER_SUCCESS });
      dispatch(getUsers(user.customer_id))
    }
    else
      dispatch({ type: DELETE_USER_FAILED, payload: "something went wrong" });
  } catch (error) {
    dispatch({ type: DELETE_USER_FAILED, payload: error.message });
  }
};

export const updateUser = (user, id, payload) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_STARTED });
  try {
    const response = await api.patch(`/users/${id}`, payload);
    if (response.status === 200) {
      dispatch({ type: UPDATE_USER_SUCCESS });
      dispatch(getUsers(user.customer_id))
    }
    else
      dispatch({ type: UPDATE_USER_FAILED, payload: "something went wrong" });
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAILED, payload: error.message });
  }
};

export const addUser = (user, payload) => async (dispatch) => {
  dispatch({ type: ADD_USER_STARTED });
  try {
    const response = await api.post("/users/create-account", payload);
    if (response.status === 201) {
      dispatch({ type: ADD_USER_SUCCESS });
      dispatch(getUsers(user.customer_id));
    } else dispatch({ type: ADD_USER_FAILED, payload: "something went wrong" });
  } catch (error) {
    dispatch({ type: ADD_USER_FAILED, payload: error.message });
  }
};
