import React, { useEffect, useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Switch from "@mui/material/Switch";

import api from "../../api";
import { AuthContext } from "../../../context/auth-context";
import SettingsToggle from "./SettingsToggle";
import SettingsToggleItem from "./SettingsToggleItem";

export default function Metrics() {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [error, setError] = useState(null);

  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id;

  async function getMetrics() {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/customers/${customerId}/metrics`);
      setMetrics(response.data.data);
    } catch (error) {
      setError("Failed to get metrics. Try again later.");
    } finally {
      setLoading(false);
    }
  }

  async function updateMetrics(metricId, checked) {
    try {
      const response = await api.patch(
        `/customers/${customerId}/metrics/${metricId}`,
        { is_active: checked ? 1 : 0 }
      );
      console.log(response);
      if (response.status !== 200) {
        toast.error("Failed update metric status");
      }
    } catch (error) {
      toast.error("Failed update metric status");
    }
  }

  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <div>
      <ToastContainer />
      <SettingsToggle
        data={metrics}
        error={error}
        loading={loading}
        render={(metric) => {
          return (
            <SettingsToggleItem
              key={metric.id}
              textPrimary={metric.metric.name}
              textSecondary={metric.metric.description}
              secondaryAction={
                <Switch
                  edge="end"
                  defaultChecked={metric.is_active}
                  onChange={(e) => {
                    updateMetrics(metric.id, e.target.checked);
                  }}
                />
              }
            />
          );
        }}
      />
    </div>
  );
}
