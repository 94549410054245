import { useEffect, useState, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import ReportItem from "./ReportItem";
import { REPORTS } from "../../../utils/helpers";
import { addReportingJob } from "../../../services/apiService";
import { AuthContext } from "../../../context/auth-context";

function ReportingList({
  gpt4TurboPrompts,
  llama2Prompts,
  palm2Prompts,
  gpt4Prompts,
  geminiPrompts,
  claudePrompts,
  promptData,
}) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [LLMs, setLLMs] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authUserEmail } = useContext(AuthContext);

  useEffect(() => {
    setLLMs([]);
    setAttributes([]);
    setInputs([]);
  }, [activeItemIndex]);

  const promptMessage = (prompts) => {
    let message = prompts?.globalStartPrompt || "";

    let jsonKeys = [];

    // LLM Comparision
    if (activeItemIndex === 0) {
      message += ` ${promptData} `;
      message += ` ${prompts?.overviewPromptPrefix || ""}`;
      message += ` ${promptData} `;
      if (attributes.includes("Brand Description")) {
        jsonKeys.push("brand_description");
        message += ` ${prompts?.overviewPrompt?.description || ""}`;
      }
      if (attributes.includes("Brand Attributes")) {
        message += ` ${prompts?.overviewPrompt?.attributes || ""}`;
        jsonKeys.push("brand_attributes");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        message += ` ${prompts?.favorabilityPrompt?.topAttributes || ""}`;
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        message += ` ${prompts?.favorabilityPrompt?.topAttributes || ""}`;
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        message += ` ${prompts?.favorabilityPrompt?.topStatements || ""}`;
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        message += ` ${prompts?.favorabilityPrompt?.topStatements || ""}`;
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Competitive Set")) {
        message += ` ${prompts?.overviewPrompt?.competetiveSet || ""}`;
        jsonKeys.push("competitive_set");
      }
      if (attributes.includes("Sources")) {
        message += ` ${prompts?.overviewPrompt?.sources || ""}`;
        jsonKeys.push("sources");
      }
    }

    // Competitors
    if (activeItemIndex === 1) {
      message += ` ${promptData} and ${inputs.toString()}.`;
      
      if (attributes.includes("Brand Description")) {
        jsonKeys.push("brand_description");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Sources")) {
        jsonKeys.push("sources");
      }
    }

    // Key sources
    if (activeItemIndex === 2) {
      message += ` ${promptData} `;
      message += ` ${prompts?.overviewPromptPrefix || ""}`;
      message += ` ${promptData} `;
      if (attributes.includes("Brand Description")) {
        message += ` ${prompts?.overviewPrompt?.description || ""}`;
        jsonKeys.push("brand_description");
      }
      if (attributes.includes("Brand Attributes")) {
        message += ` ${prompts?.overviewPrompt?.attributes || ""}`;
        jsonKeys.push("brand_attributes");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        message += ` ${prompts?.favorabilityPrompt?.topAttributes || ""}`;
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        message += ` ${prompts?.favorabilityPrompt?.topAttributes || ""}`;
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        message += ` ${prompts?.favorabilityPrompt?.topStatements || ""}`;
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        message += ` ${prompts?.favorabilityPrompt?.topStatements || ""}`;
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Competitive Set")) {
        message += ` ${prompts?.overviewPrompt?.competitorComparison || ""}`;
        jsonKeys.push("competitive_set");
      }

      jsonKeys.push("sources");

      message += ` ${prompts?.overviewPrompt?.sources || ""}`;
    }

    // Brand category dimensions
    if (activeItemIndex === 3) {
      message += ` ${inputs.toString()}. `;
      message += ` ${prompts?.dimensionsPromptPrefix || ""} `;
      if (
        attributes.includes(
          "Frequently recommended buying criteria/considerations"
        )
      ) {
        message += ` ${prompts?.dimensionsPrompt?.recommendedBuying || ""}`;
        jsonKeys.push("buying_criteria");
      }
      if (attributes.includes("Sources")) {
        message += ` ${prompts?.dimensionsPrompt?.sources || ""}`;
        jsonKeys.push("sources");
      }
    }

    message += `Do not include any additional text. Only provide the JSON output for the following data`;

    let jsonObject = {};

    // Competitors Report
    if (activeItemIndex == 1) {
      jsonObject["brand_name"] = "value (string)"
    }

    jsonKeys.forEach((key) => {
      if (
        key.includes("attributes") ||
        key.includes("statements") ||
        key.includes("sources") ||
        key.includes("buying_criteria")
      ) {
        jsonObject[key] = "string (array)";
      } else {
        jsonObject[key] = "value (string)";
      }
    });

    message += JSON.stringify(jsonObject, null, 2);

     // Competitors Report
    if (activeItemIndex == 1) {
      message += "for each brands mentioned in an array.";
    }

    message +=
      "For attributes, statements, sources, buying_criteria, Please follow the data type given in JSON structure. Do not include apostrophe or single quotes. Please follow the rules.";

    message = replaceAll(message, "in bulleted form", "");
    return message.replace(/\s+/g, " ").trim();
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }

  const handleInputChange = (e, selection, title) => {
    const { type, text } = selection;
    if (type === "checkbox") {
      if (text === "Select LLMs") {
        if (title === "Competitor Comparison") {
          e.target.checked
            ? setLLMs([e.target.id])
            : setLLMs(LLMs.filter((llm) => llm !== e.target.id));
          return;
        }
        e.target.checked
          ? setLLMs([...LLMs, e.target.id])
          : setLLMs(LLMs.filter((llm) => llm !== e.target.id));
      } else if (text === "Select Attributes") {
        e.target.checked
          ? setAttributes([...attributes, e.target.id])
          : setAttributes(attributes.filter((attr) => attr !== e.target.id));
      }
    } else {
      setInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs[e.target.id] = e.target.value;
        return updatedInputs;
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const queries = [];
    for (let llm of LLMs) {
      let inputPrompt = "";
      if (llm === "gpt_4_turbo") {
        inputPrompt = promptMessage(gpt4TurboPrompts);
      } else if (llm === "palm2_text") {
        inputPrompt = promptMessage(palm2Prompts);
      } else if (llm === "llama2_70b_chat" || llm === "llama3") {
        inputPrompt = promptMessage(llama2Prompts);
      } else if (llm === "gpt-4" || llm === "gpt-4o" || llm === "gpt-3.5-turbo" || llm === "Perplexity") {
        inputPrompt = promptMessage(gpt4Prompts);
      } else if (llm === "Gemini") {
        inputPrompt = promptMessage(geminiPrompts);
      } else if (llm === "Claude") {
        inputPrompt = promptMessage(claudePrompts);
      }
      queries.push({
        llm,
        inputPrompt,
      });
    }

    if (
      ["LLM Comparison", "Competitor Comparison", "Key Sources"].includes(
        REPORTS[activeItemIndex].title
      ) &&
      !promptData.toString().trim()
    ) {
      toast.warn("Company/Brand input required");
      return;
    }

    if (
      REPORTS[activeItemIndex].title === "Competitor Comparison" &&
      (inputs.length === 0 || inputs.every((input) => !input))
    ) {
      toast.warn("Please enter at least one competitor");
      return;
    }

    if (
      REPORTS[activeItemIndex].title === "Brand Category Dimensions" &&
      (inputs.length === 0 || inputs.some((input) => !input))
    ) {
      toast.warn("Please enter brand category first");
      return;
    }

    if (LLMs.length === 0) {
      toast.warn("Please select at least one LLM");
      return;
    }
    if (attributes.length === 0) {
      toast.warn("Please select at least one attribute");
      return;
    }

    const updatedAttr = [...attributes];
    const itemIndex = updatedAttr.findIndex(
      (i) => i === "Top 5 Positive and Negative Attributes"
    );
    if (itemIndex > -1) {
      updatedAttr.splice(
        itemIndex,
        1,
        "Top 5 positive Attributes",
        "Top 5 negative Attributes"
      );
    }

    const itemIndex2 = updatedAttr.findIndex(
      (i) => i === "Top 5 Positive and Negative Statements"
    );
    if (itemIndex2 > -1) {
      updatedAttr.splice(
        itemIndex2,
        1,
        "Top 5 positive Statements",
        "Top 5 negative Statements"
      );
    }

    setLoading(true);
    const abortCtrl = new AbortController();
    await addReportingJob(
      {
        queries,
        updatedAttr,
        inputs: [promptData, ...inputs],
        date: new Date().toISOString(),
        id: crypto.randomUUID(),
        title: REPORTS[activeItemIndex].title,
        email: authUserEmail,
        status: "in-progress",
        LLMs: LLMs,
      },
      abortCtrl
    );

    setLoading(false);

    toast.success("Report has been submitted for processing");

    setTimeout(() => {
      const url = `/reporting?tab=2`;
      window.open(url, "_blank");
    }, 1200);

    /*localStorage.setItem(
      "queries",
      JSON.stringify({
        queries,
        attributes: updatedAttr,
        reportType: REPORTS[activeItemIndex].title,
        inputs: [promptData, ...inputs],
      })
    );
    const url = `/llmreportdata`;
    window.open(url, "_blank");*/
  };

  return (
    <div>
      <Accordion defaultActiveKey={0}>
        {REPORTS.map((report, index) => (
          <ReportItem
            key={report.title}
            report={report}
            index={index}
            onSelect={setActiveItemIndex}
            onChangeInput={handleInputChange}
            LLMs={LLMs}
            attributes={attributes}
            inputs={inputs}
          />
        ))}
      </Accordion>
      <button
        className="btn btn-primary mt-4"
        style={{
          backgroundColor: "rgb(61, 200, 99)",
          color: "white",
          fontSize: "14px",
          padding: "5px 16px",
        }}
        disabled={loading}
        onClick={submitHandler}
      >
        {loading ? (
          <div className="d-flex align-items-center">
            <CircularProgress className="mr-1" size={16} /> &nbsp;Please wait{" "}
          </div>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
}

export default ReportingList;
