import { createContext, useReducer } from "react";

export const BrandIndexContext = createContext({
  history: [],
  showHistory: false,
  type: "list",
  historyItem: null,
  setHistory: () => {},
  setShowHistory: () => {},
});

const intialState = {
  history: [],
  showHistory: false,
  type: "list",
  historyItem: null,
};

function brandIndexReducer(state, action) {
  if (action.type === "SET_HISTORY") {
    return {
      ...state,
      history: action.payload,
    };
  }
  if (action.type === "SHOW_HISTORY") {
    return {
      ...state,
      showHistory: action.payload.show,
      type: action.payload.type,
    };
  }
  if (action.type === "SET_HISTORY_ITEM") {
    return {
      ...state,
      historyItem: action.payload,
      showHistory: true,
      type: "individual"
    }
  }
  return state;
}

function BrandIndexContextProvider({ children }) {
  const [state, dispatch] = useReducer(brandIndexReducer, intialState);

  function handleSetHistory(payload) {
    dispatch({ type: "SET_HISTORY", payload });
  }

  function handleShowHistory(payload) {
    dispatch({ type: "SHOW_HISTORY", payload });
  }

  function handleSetHistoryItem(payload) {
    dispatch({ type: "SET_HISTORY_ITEM", payload });
  }

  const brandIndexValue = {
    history: state.history,
    showHistory: state.showHistory,
    type: state.type,
    historyItem: state.historyItem,
    setHistory: handleSetHistory,
    setShowHistory: handleShowHistory,
    setHistoryItem: handleSetHistoryItem,
  };

  return (
    <BrandIndexContext.Provider value={brandIndexValue}>
      {children}
    </BrandIndexContext.Provider>
  );
}

export default BrandIndexContextProvider;
