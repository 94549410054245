import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "../../../context/auth-context";
import {
  fetchBrandProducts,
  deleteBrandProduct,
} from "../../../redux/actions/brandProductsActions";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { useNavigate } from "react-router";
import { FaArrowRight } from "react-icons/fa";

const ProductComponent = ({ handleSelectedData }) => {
  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate()

  const customerId = user?.customer_id;

  useEffect(() => {
    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }

    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }

    if (coreAttributes?.data && coreAttributes?.data.length === 0) {
      dispatch(fetchcoreAttributes(customerId));
    }
  }, [dispatch]);

  const handleDeleteBrandProduct = (brandProductId) => {
    dispatch(deleteBrandProduct(customerId, brandProductId)).then(() => {
      dispatch(fetchBrandProducts(customerId));
    });
  };

  if (brandProducts.loading) {
    return <p>Loading...</p>;
  }

  if (brandProducts.deleteLoading) {
    return <p>Product is being deleted Please wait...</p>;
  }

  if (brandProducts.error) {
    return <p>Error: {brandProducts.error}</p>;
  }

  const brandProductsData = brandProducts?.data?.data.filter((obj) => obj.is_active);

  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Brand</th>
          <th>Product Name</th>
          <th>Details</th>
          <th>Product category</th>
          <th>Product competitor</th>
          <th>Persona</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {brandProductsData &&
          brandProductsData.length !== 0 &&
          brandProductsData.map((data) => {
            return (
              <tr>
                <td>{data?.brand_product?.brand?.name}</td>
                <td>{data?.brand_product?.name}</td>
                <td>{data?.brand_product?.description}</td>
                <td>
                  {" "}
                  {data?.customer_brand_product_categories &&
                    data?.customer_brand_product_categories.length !== 0 &&
                    data?.customer_brand_product_categories.map((data) => {
                      return <p> {data?.category?.name} </p>;
                    })}
                </td>
                <td>
                  {data?.customer_competitor_brand_products &&
                    data?.customer_competitor_brand_products.length !== 0 &&
                    data?.customer_competitor_brand_products.map((data) => {
                      return <p> {data?.brand_product?.name} </p>;
                    })}
                </td>
                <td>
                  {data?.customer_brand_product_personas &&
                    data?.customer_brand_product_personas.length !== 0 &&
                    data?.customer_brand_product_personas.map((data) => {
                      return <p> {data?.persona?.name} </p>;
                    })}
                </td>
                <td className={classes.actions}>
                <div style={{display:'flex'}}>  
                  <Button
                    style={{
                      backgroundColor: "green",
                      width: "unset",
                    }}
                    variant="success"
                    onClick={() => handleSelectedData(data)}
                  >
                    Update
                  </Button>{" "}
                  &nbsp;
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                      width: "unset",
                    }}
                    variant="danger"
                    onClick={() => handleDeleteBrandProduct(data?.id)}
                  >
                    Delete
                  </Button>
                  <Button
                      style={{
                        backgroundColor: "blue",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      // variant="danger"
                      onClick={() =>
                        navigate(
                          `/brandonboarding/dashboard?brandProductId=${data?.id}&isDetailPage=1`
                        )
                      }
                    >
                      Dashboard
                      <FaArrowRight
                        style={{ cursor: "pointer" }}
                        // size={20}
                        onClick={() => {
                          // navigate("/brandonboarding/dashboard");
                          // setIsDetailPage(false);
                        }}
                      />
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* <tbody>
        <tr>
          <td>1</td>
          <td>Data 1</td>
          <td>Data 2</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Data 3</td>
          <td>Data 4</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Data 5</td>
          <td>Data 6</td>
        </tr>
      </tbody> */}
    </Table>
  );
};

export default ProductComponent;
